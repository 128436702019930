import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  Stack,
  TableHead,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import Image from "mui-image";
import { Employee } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const InterPassportScan = ({ currentEmployee, template }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        marginTop: template.spacing,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
        boxShadow: template.bodyShadow,

        p: 1,
      }}
      alignItems="center"
      spacing={1}
    >
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("passportPhoto", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("passportPhoto", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
        </Table>
      </StyledTableContainer>
      <Image
        src={
          currentEmployee.passportScan
            ? (currentEmployee.passportScan as string)
            : "/enjazPLogoFilled.png"
        }
        alt={currentEmployee.passportNumber}
        width="90%"
        height="450px"
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          borderColor: template.borderColor,
          borderWidth: (template.borderWidth as number) * 2,
          borderStyle: template.borderStyle,
        }}
      />
    </Stack>
  );
};
