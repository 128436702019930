import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import "dayjs/locale/ar-sa";
import "dayjs/locale/am";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  NavLink as RouterLink,
  useNavigate,
  useParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import {
  Button,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Backspace, Download, Print } from "@mui/icons-material";

import Toast from "../../components/Layout/Toast";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  createEmployee,
  getEmployee,
  resetSelectedEmployee,
  selectProcesses,
} from "./processSlices";
//import Barcode, { Renderer } from "react-jsbarcode";
import {
  Employee,
  MaritalStatus,
  Nationalities,
  ReligionTypes,
  Sex,
} from "./types/processTypes";
import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { Form, Formik, FormikProps } from "formik";
import { createCommissionSchema } from "./validation";
import Save from "@mui/icons-material/Save";
import { addDays } from "date-fns";
import { useTranslation } from "react-i18next";
import FacePhoto from "./components/FacePhoto";
import { usePDF } from "@react-pdf/renderer";
import { SaudiApplication } from "../../components/reports/embassy/SaudiApplication";
import { AuthUser, ClientTypes } from "../auth/types/authType";
import { Company } from "../setups/types/setupTypes";
import { CompanyFilter } from "../../components/filter/CompanyFilter";
import { selectAuth } from "../auth/authSlice";
import { getCompany, selectSetups } from "../setups/setupSlices";
import { VisaBriefCommission } from "./components/VisaBriefCommission";
export const CommissionEntry = () => {
  const { id } = useParams() as {
    id: string;
  };

  //const theme = useTheme();
  const [company, setCompany] = useState<Company>();
  const { t, i18n } = useTranslation();
  const { t: te } = useTranslation("enums");
  const { user } = useAppSelector(selectAuth);
  const {
    selectedEmployee,
    error,
    success,
    loading: employeeEntryLoading,
  } = useAppSelector(selectProcesses);
  const [opacity, setOpacity] = useState(0.25);
  const [pointerEvents, setPointerEvents] = useState("none");
  const [printVisibility, setPrintVisibility] = useState("none");
  const { locale } = useAppSelector(selectPreference);
  const navigate = useNavigate();
  const inputReference = useRef<HTMLInputElement>(null);
  const { selectedCompany } = useAppSelector(selectSetups);

  const application = SaudiApplication({
    employee: selectedEmployee,
    user: user as AuthUser,
  });
  const [instance, updateInstance] = usePDF({
    document: application,
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (selectedEmployee) {
      updateInstance();
      if (selectedEmployee._id) {
        setOpacity(1);
        setPointerEvents("initial");
        if (selectedEmployee.visa && selectedEmployee.embassyProcess) {
          setPrintVisibility("flex");
        } else setPrintVisibility("none");
      } else {
        setOpacity(0.25);
        setPointerEvents("none");
        setPrintVisibility("none");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmployee]);

  useEffect(() => {
    if (inputReference && inputReference.current)
      inputReference.current.focus();
  }, [inputReference]);

  useEffect(() => {
    dispatch(changePageTitle(`Employee Entry`));

    if (id && id.length > 10) {
      dispatch(getEmployee(id));
    } else {
      if (user && user.company && user.company.enjazPlusLicense) {
        if (user.company.enjazPlusLicense === "expired") {
          alert(t("expiredMessage"));
          navigate("/app/coms");
        } else if (user.company.enjazPlusLicense === "quota") {
          alert(t("fulledQuota"));
          navigate("/app/coms");
        } else if (user.company.enjazPlusLicense === "renewExpired") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
          navigate("/app/coms");
        } else if (user.company.enjazPlusLicense === "renewal") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
        }
        //alert("Your Database is Full");
        //navigate("/app/employees");
      } else resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  function resetFields() {
    //navigate("/employee/0");
    dispatch(resetSelectedEmployee());
  }

  useEffect(() => {
    if (user && user.company) {
      dispatch(getCompany(user.company._id as string));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (selectedCompany) {
      //console.log(selectedCompany);
      if (id && id.length < 11 && selectedCompany.managerNameAmharic) {
        if (selectedCompany.enjazPlusLicense === "expired") {
          alert(t("expiredMessage"));
          navigate("/app/coms");
        } else if (selectedCompany.enjazPlusLicense === "quota") {
          alert(t("fulledQuota"));
          navigate("/app/coms");
        } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
          navigate("/app/coms");
        } else if (selectedCompany.enjazPlusLicense === "renewal") {
          alert(
            "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
          );
        }
      }
    }
  }, [dispatch, selectedCompany]);

  function downLoadPdf() {
    if (selectedCompany) {
      if (selectedCompany.enjazPlusLicense === "expired") {
        alert(t("expiredMessage"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
        navigate("/app/coms");
      } else if (selectedCompany.enjazPlusLicense === "quota") {
        alert(t("fulledQuota"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewal") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
      }
    }
    let downloadLink = document.createElement("a");
    const fileName = selectedEmployee?.givenName + "_Enjaz.pdf";

    downloadLink.href = instance.url as string;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  function printPdf() {
    if (selectedCompany) {
      if (selectedCompany.enjazPlusLicense === "expired") {
        alert(t("expiredMessage"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewExpired") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አይችሉም፥፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
        navigate("/app/coms");
      } else if (selectedCompany.enjazPlusLicense === "quota") {
        alert(t("fulledQuota"));
        return;
      } else if (selectedCompany.enjazPlusLicense === "renewal") {
        alert(
          "አመታዊ ኮንትራት ስለተጠናቀቀ፥ አዲስ ዳታ ማስገባትና ኢንጃዝ ፕሪንት ማድርግ አገልግሎት ይቋረጣል፥ ክፍያ በመፈጸም ውሉን ያድሱ::"
        );
      }
    }

    if (instance && instance.url) {
      const iframe = document.createElement("iframe"); //load content in an iframe to print later
      document.body.appendChild(iframe);

      iframe.style.display = "none";
      iframe.src = instance.url as string;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
    }
  }

  useEffect(() => {
    if (employeeEntryLoading === "pending") {
      document.body.style.opacity = "0.5";
    } else {
      document.body.style.opacity = "1";
    }
  }, [employeeEntryLoading]);

  return (
    <div dir={i18n.dir()}>
      <Helmet>
        <title>
          {`${
            selectedEmployee?.givenName?.toUpperCase() as string
          } | EnjazPlus`}
        </title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <>
          <Formik
            enableReinitialize={true}
            initialValues={selectedEmployee as Employee}
            validationSchema={createCommissionSchema}
            onSubmit={(values, actions) => {
              //console.log(Math.random().toString(36).slice(2, 12));
              actions.setSubmitting(false);
              if (company) {
                values = {
                  ...values,
                  owner: company,
                };
              } else if (user && user.company) {
                values = {
                  ...values,
                  owner: user.company,
                };
              }
              //console.log(values);
              dispatch(createEmployee(values));
            }}
          >
            {(props: FormikProps<Employee>) => (
              <Form>
                <Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Tooltip title="Back to Employee list">
                      <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        component={RouterLink}
                        to={"/app/coms"}
                      >
                        <Backspace />
                      </Button>
                    </Tooltip>

                    <Stack
                      direction="row"
                      spacing={1}
                      alignSelf="flex-end"
                      sx={{
                        "& > :not(style)": {
                          m: 0,
                          ml: 1,
                        },
                      }}
                    >
                      <Tooltip title={t("Download First Page")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={downLoadPdf}
                          size="large"
                          sx={{ display: printVisibility }}
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t("print")}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={printPdf}
                          size="large"
                          sx={{ display: printVisibility }}
                        >
                          <Print />
                          {" " + t("print")}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          t("save") + " " + t("employee") + " " + t("data")
                        }
                      >
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          size="large"
                          disabled={!props.isValid}
                        >
                          <Save />
                          {" " + t("save")}
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Stack>
                  {/* <Divider variant="middle" sx={{ mt: 2 }} /> backgroundColor: "rgba(69, 90, 100, 0.08)" */}
                  <Accordion sx={{ my: 0 }} expanded>
                    <StyledAccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5" component="div">
                        Passport Data
                      </Typography>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Grid container item spacing={1} md={8} xs={12}>
                          {user &&
                            user.company &&
                            user.company.type === ClientTypes.ForeignAgent && (
                              <Grid item xs={12}>
                                <CompanyFilter
                                  companyType={ClientTypes.LocalAgency}
                                  setCompany={setCompany}
                                />
                              </Grid>
                            )}
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              autoFocus
                              formikKey="givenName"
                              label={t("givenName")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="surName"
                              label={t("surName")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="sex"
                              select
                              label={te("sex")}
                            >
                              {Object.keys(Sex).map((option) => (
                                <MenuItem key={option} value={option as string}>
                                  {te(option)}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="passportNumber"
                              label={t("passportNumber")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="placeOfBirth"
                              label={t("placeOfBirth")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="placeOfIssue"
                              label={t("placeOfIssue")}
                              sx={{ mt: 1 }}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfBirth")}
                                views={["year", "month", "day"]}
                                minDate={new Date("1920-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.dateOfBirth}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "dateOfBirth",
                                    value,
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="dateOfBirth"
                                    sx={{
                                      mt: 1,
                                    }}
                                    fullWidth
                                    size="small"
                                    disabled
                                    error={Boolean(
                                      props.touched.dateOfBirth &&
                                        props.errors.dateOfBirth
                                    )}
                                    helperText={
                                      props.touched.dateOfBirth &&
                                      props.errors.dateOfBirth
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfIssue")}
                                views={["year", "month", "day"]}
                                minDate={new Date("2012-01-01")}
                                maxDate={new Date("2025-12-31")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.passportIssueDate}
                                onChange={(value) => {
                                  const isDate = new Date(value as Date);
                                  // console.log(addYears(isDate, 5));
                                  props.setFieldValue(
                                    "passportIssueDate",
                                    value,
                                    true
                                  );
                                  props.setFieldValue(
                                    "passportExpiryDate",
                                    addDays(isDate, 1825),
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{ mt: 1 }}
                                    id="passportIssueDate"
                                    fullWidth
                                    size="small"
                                    disabled
                                    error={Boolean(
                                      props.touched.passportIssueDate &&
                                        props.errors.passportIssueDate
                                    )}
                                    helperText={
                                      props.touched.passportIssueDate &&
                                      props.errors.passportIssueDate
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale={locale}
                            >
                              <DatePicker
                                label={t("dateOfExpiry")}
                                views={["year", "month", "day"]}
                                minDate={new Date("2023-01-01")}
                                maxDate={new Date("2035-01-01")}
                                disableMaskedInput
                                inputFormat="DD-MMM-YYYY"
                                value={props.values.passportExpiryDate}
                                onChange={(value) => {
                                  props.setFieldValue(
                                    "passportExpiryDate",
                                    value,
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="passportExpiryDate"
                                    sx={{ mt: 1 }}
                                    fullWidth
                                    size="small"
                                    disabled
                                    error={Boolean(
                                      props.touched.passportExpiryDate &&
                                        props.errors.passportExpiryDate
                                    )}
                                    helperText={
                                      props.touched.passportExpiryDate &&
                                      props.errors.passportExpiryDate
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="additionalData.maritalStatus"
                              select
                              label={te("maritalStatus")}
                            >
                              {Object.keys(MaritalStatus).map((option) => (
                                <MenuItem key={option} value={option as string}>
                                  {te(option)}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>

                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="additionalData.religion"
                              select
                              label={te("religion")}
                            >
                              {Object.keys(ReligionTypes).map((option) => (
                                <MenuItem key={option} value={option as string}>
                                  {te(option)}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormikTextField
                              formikKey="additionalData.nationality"
                              select
                              label={te("nationality")}
                            >
                              {Object.keys(Nationalities).map((option) => (
                                <MenuItem key={option} value={option as string}>
                                  {te(option)}
                                </MenuItem>
                              ))}
                            </FormikTextField>
                          </Grid>
                          {/* <Grid item md={4} xs={12}>
                            <Button
                              type="submit"
                              color="secondary"
                              variant="contained"
                              size="large"
                              disabled={!props.isValid}
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              <Save />
                              {" " + t("save")}
                            </Button>
                          </Grid> */}
                        </Grid>
                        <Grid
                          container
                          item
                          md={4}
                          xs={12}
                          sx={{
                            justifyContent: "flex-end",
                            opacity: opacity,
                            pointerEvents: pointerEvents,
                          }}
                        >
                          <FacePhoto />
                        </Grid>
                      </Grid>
                      <Box
                        sx={{
                          opacity: opacity,
                          pointerEvents: pointerEvents,
                          m: 0,
                          p: 0,
                        }}
                      >
                        <VisaBriefCommission
                          formikData={props}
                          printPdf={printPdf}
                          printVisibility={printVisibility}
                        />
                      </Box>
                      <br />
                      {success && (
                        <Toast severity="success">{success.message}</Toast>
                      )}
                      {error && <Toast severity="error">{error.message}</Toast>}
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </Form>
            )}
          </Formik>

          {error && <Toast severity="error">{error.message}</Toast>}
        </>
      </Box>
      <div>
        <input
          type="hidden"
          id="employeeTelephone"
          value={selectedEmployee.address?.telephone}
        />
        <input
          type="hidden"
          id="employeeCity"
          value={selectedEmployee.address?.city}
        />
        <input
          type="hidden"
          id="employeeAddress"
          value={selectedEmployee.address?.streetAddress}
        />

        <input
          type="hidden"
          id="contactName"
          value={selectedEmployee.contactPerson?.displayName}
        />
        <input
          type="hidden"
          id="contactKinship"
          value={selectedEmployee.contactPerson?.kinship}
        />
        <input
          type="hidden"
          id="contactTelephone"
          value={selectedEmployee.address?.telephone}
        />
        <input
          type="hidden"
          id="contactAddress"
          value={selectedEmployee.contactPerson?.address?.streetAddress}
        />
        <input
          type="hidden"
          id="fullNameAmharic"
          value={selectedEmployee.additionalData?.fullNameAmharic}
        />
      </div>
    </div>
  );
};
