import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";

import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";

import {
  LabelTemplate,
  StyledTableCellLabelTemplate,
  StyledTableCellValueTemplate,
  StyledTableContainer,
  StyledTableRowTemplate,
} from "../../styles/table";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const InterPostApplied = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("workInformation", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("workInformation", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate template={template}>
                {tt("profession", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.appliedProfession as string,
                  template.cvLang
                )
                  .toLowerCase()
                  .toUpperCase()}
              </StyledTableCellValueTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("profession", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="2">
              <StyledTableCellLabelTemplate template={template}>
                {tt("salary", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.salary?.toLocaleString(
                  template.cvLang === "ar" ? "ar-SA" : "en-US"
                ) +
                  " " +
                  tt(
                    (
                      currentEmployee.additionalData?.currency as string
                    ).toLowerCase(),
                    template.cvLang
                  )}
              </StyledTableCellValueTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("salary", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
            <StyledTableRowTemplate template={template} key="1">
              <StyledTableCellLabelTemplate template={template}>
                {tt("contractPeriod", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {tt(
                  currentEmployee.additionalData?.contractPeriod as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("contractPeriod", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
