import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";

import { format } from "date-fns";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  showHeader?: boolean | true;
  showPassportNumber?: boolean | true;
  showIssueDate?: boolean | true;
  showPlaceOfIssue?: boolean | true;
  showExpiryDate?: boolean | true;
}
export const BasicPassport = ({
  currentEmployee,
  template,
  showHeader,
  showPassportNumber,
  showIssueDate,
  showPlaceOfIssue,
  showExpiryDate,
}: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            {showHeader && (
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "left",
                    width: "33%",
                  }}
                >
                  {tt("passport", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ width: "34%" }}
                ></StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                    width: "33%",
                  }}
                >
                  {tt("passport", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableHead>
          <TableBody>
            {showPassportNumber && (
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("passportNo", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.passportNumber as string}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("passportNumber", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showIssueDate && (
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("dateOfIssue", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {template.cvLang === "en"
                    ? format(
                        new Date(currentEmployee.passportIssueDate as Date),
                        "dd-MMM-yyyy"
                      )
                    : new Date(
                        currentEmployee.passportIssueDate as Date
                      ).toLocaleDateString("ar-SA", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("dateOfIssue", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showPlaceOfIssue && (
              <StyledTableRowTemplate template={template} key="2">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("placeOfIssue", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.placeOfIssue as string}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("placeOfIssue", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showExpiryDate && (
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("dateOfExpiry", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {template.cvLang === "en"
                    ? format(
                        new Date(currentEmployee.passportExpiryDate as Date),
                        "dd-MMM-yyyy"
                      )
                    : new Date(
                        currentEmployee.passportExpiryDate as Date
                      ).toLocaleDateString("ar-SA", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("dateOfExpiry", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

export const BasicPassportShort = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("passport", "en")}
              </StyledTableCellLabelTemplate>

              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("passportDet", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="0">
              <StyledTableCellLabelTemplate template={template}>
                {tt("passportNo", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.passportNumber as string}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("passportNumber", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>

            <StyledTableRowTemplate template={template} key="3">
              <StyledTableCellLabelTemplate template={template}>
                {tt("dateOfExpiry", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {template.cvLang === "en"
                  ? format(
                      new Date(currentEmployee.passportExpiryDate as Date),
                      "dd-MMM-yyyy"
                    )
                  : new Date(
                      currentEmployee.passportExpiryDate as Date
                    ).toLocaleDateString("ar-SA", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                    })}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("dateOfExpiry", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
