import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Stack,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
} from "../../../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { Employee } from "../../types/processTypes";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const CvExperience = ({ currentEmployee, template }: Props) => {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  return (
    <>
      <Stack sx={{ marginTop: 1, width: "100%" }}>
        {currentEmployee.workExperience &&
        currentEmployee.workExperience.length <= 0 ? (
          <>
            <TableContainer
              sx={{
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <StyledTableRow key="3">
                    <StyledTableCellLabelTemplate
                      colSpan={3}
                      template={template}
                    >
                      {tt("workExperience")}
                    </StyledTableCellLabelTemplate>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCellValueTemplate template={template}>
                      {tt("firstTimer")}
                    </StyledTableCellValueTemplate>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "10px",
              width: "100%",
            }}
          >
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow key="3">
                  <StyledTableCellLabelTemplate colSpan={3} template={template}>
                    {tt("workExperience")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCellLabelTemplate template={template}>
                    {te("country")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate template={template}>
                    {te("profession")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellLabelTemplate template={template}>
                    {te("forYears")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRow>
                {currentEmployee.workExperience &&
                  currentEmployee.workExperience.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCellValueTemplate template={template}>
                        {te(row.country as string)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate template={template}>
                        {te(row.profession as string)}
                      </StyledTableCellValueTemplate>
                      <StyledTableCellValueTemplate template={template}>
                        {te(row.years as string)}
                      </StyledTableCellValueTemplate>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
};
