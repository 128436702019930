import { Helmet } from "react-helmet";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Form, FormikProps, Formik } from "formik";
import { GoogleLogin } from "@react-oauth/google";

import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { forgot, google, selectAuth } from "./authSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";

import AuthSkeleton from "./AuthSkeleton";
import { forgotSchema } from "./validation";
import Toast from "../../components/Layout/Toast";
import { Container, Divider, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Forgot = () => {
  const { loading, error, user, success } = useAppSelector(selectAuth);
  const { t: ta } = useTranslation("auth");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  if (user && user.email) {
    return <Navigate to="/" />;
  }
  if (success) {
    navigate("/login");
  }
  interface Values {
    email: string;
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | EnjazPlus</title>
      </Helmet>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Card elevation={3} sx={{ m: 2 }}>
          <CardContent>
            <Stack>
              <Stack sx={{ mb: 3, alignItems: "center" }}>
                <Typography variant="h2">{ta("forgotPassword")}</Typography>
              </Stack>

              {loading === "pending" ? (
                <AuthSkeleton />
              ) : (
                <>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={forgotSchema}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      dispatch(forgot(values));
                    }}
                  >
                    {(props: FormikProps<Values>) => (
                      <Form>
                        <FormikTextField
                          formikKey="email"
                          label={ta("email")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircle />
                              </InputAdornment>
                            ),
                          }}
                        />

                        {error && (
                          <Toast severity="error">{error.message}</Toast>
                        )}
                        <Box component="div" pt={2}>
                          <Button
                            sx={{ width: "100%" }}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!props.isValid}
                          >
                            {ta("requestPasswordResetLink")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Stack sx={{ my: 2 }} direction="row">
                    <Typography>{ta("haveAnAccount")}?</Typography>
                    <Link
                      component={NavLink}
                      underline="none"
                      to="/login"
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      <>{ta("signin")}</>
                    </Link>
                  </Stack>
                  <Divider variant="middle" sx={{ my: 2 }}>
                    or
                  </Divider>

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <GoogleLogin
                      onSuccess={(credentialResponse) => {
                        dispatch(
                          google(credentialResponse.credential as string)
                        );

                        // console.log(credentialResponse);
                      }}
                      onError={() => {
                        console.log("Login Failed");
                      }}
                      data-text="signin_with"
                      theme="filled_blue"
                      size="large"
                      data-logo_alignment="center"
                      data-shape="pill"
                      useOneTap
                    />
                  </Box>
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
