import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostAppliedSingleValue } from "./components/basic/BasicPostApplied";
import { BasicDetail } from "./components/basic/BasicDetail";
import { BasicLanguageDetail } from "./components/basic/BasicLanguage";
import { BasicExperience } from "./components/basic/BasicExperience";
import { BasicSkillsOneLineHorz } from "./components/basic/BasicSkills";
import { BasicPassport } from "./components/basic/BasicPassport";
import { BasicFullName } from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicTitleSingleLang } from "./components/basic/BasicTitle";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { BasicLocation } from "./components/basic/BasicLocation";
import { BasicFlag } from "./components/basic/BasicFlag";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function EntranceTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="center"
          >
            {/* Short Photo */}

            <Grid container item xs={7}>
              <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                <BasicTitleSingleLang template={cvTemplate} />

                {cvTemplate && currentEmployee && (
                  <BasicPostAppliedSingleValue
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    showHeader={false}
                  />
                )}
                {cvTemplate && currentEmployee && (
                  <BasicFullName
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={2}>
              {cvTemplate && currentEmployee && (
                <>
                  <BasicFlag
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    flagName="/saudiFlag.png"
                  />
                  <BasicFlag
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    flagName="/ethiopiaFlag.png"
                  />
                </>
              )}
            </Grid>
            <Grid item xs={3}>
              {cvTemplate && currentEmployee && (
                <BasicFace
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>
          </Grid>
          <Grid item md={5} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              {cvTemplate && currentEmployee && (
                <>
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                  <BasicDetail
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    showHeader={false}
                    showNationality={false}
                    showReligion={false}
                    showDateOfBirth={false}
                    showAge={false}
                    showPlaceOfBirth={false}
                    showMaritalStatus={false}
                    showNumberOfChildren={false}
                    showWeightHeight={true}
                    showComplexion={true}
                    showEducation={false}
                    showLivingTown={false}
                    showEmployeeTelephone={false}
                    showContactName={false}
                    showContactTelephone={false}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <Grid item md={7} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicPassport
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showPassportNumber={true}
                showIssueDate={true}
                showPlaceOfIssue={true}
                showExpiryDate={true}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={true}
                showPlaceOfBirth={false}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={false}
                showComplexion={false}
                showEducation={true}
                showLivingTown={true}
                showEmployeeTelephone={true}
                showContactName={true}
                showContactTelephone={true}
              />
            )}
          </Grid>

          <Grid item xs={5}>
            {cvTemplate && currentEmployee && (
              <BasicExperience
                currentEmployee={currentEmployee}
                template={cvTemplate}
                type="entrance"
              />
            )}
          </Grid>
          <Grid item xs={7}>
            {cvTemplate && currentEmployee && (
              <BasicLanguageDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={true}
              />
            )}
          </Grid>

          <Grid item xs={10}>
            {cvTemplate && currentEmployee && (
              <BasicSkillsOneLineHorz
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            {cvTemplate && currentEmployee && (
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  borderRadius: cvTemplate.borderRadius,
                  borderColor: cvTemplate.borderColor,
                  borderWidth: (cvTemplate.borderWidth as number) * 2,
                  borderStyle: cvTemplate.borderStyle,
                  p: 0,
                }}
              >
                <BasicLocation
                  type="location"
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default EntranceTemplate;
