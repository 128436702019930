import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardMedia,
  Grid,
  Link,
} from "@mui/material";
import { osVersion } from "react-device-detect";

export default function Support() {
  return (
    <Box sx={{ m: 4, py: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 300 }}>
            <CardActionArea
              href="https://chrome.google.com/webstore/detail/enjaz%2B/oafbpbmbacpfmjgmffmngnlcflhffcfi"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "fill" }}
                component="img"
                image="/enjazPLogoFilled.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://chrome.google.com/webstore/detail/enjaz%2B/oafbpbmbacpfmjgmffmngnlcflhffcfi"
                target="_blank"
              >
                EnjazPlus Extension
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 300 }}>
            <CardActionArea
              href={
                osVersion === "x86_64"
                  ? "https://download.teamviewer.com/download/TeamViewer_Setup_x64.exe"
                  : "https://download.teamviewer.com/download/TeamViewer_Setup.exe"
              }
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "fill" }}
                component="img"
                image="/teamviewer.svg"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              {osVersion === "x86_64" ? (
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://download.teamviewer.com/download/TeamViewer_Setup_x64.exe"
                  target="_blank"
                >
                  Download
                </Link>
              ) : (
                <Link
                  color="secondary"
                  variant="h4"
                  href="https://download.teamviewer.com/download/TeamViewer_Setup.exe"
                  target="_blank"
                >
                  Download
                </Link>
              )}
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 300 }}>
            <CardActionArea
              href="https://anydesk.com/en/downloads/windows"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/anydesk.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://anydesk.com/en/downloads/windows"
                target="_blank"
              >
                Download
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 300 }}>
            <CardActionArea href="https://lmis.gov.et/" target="_blank">
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/ELMIS-logo.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://lmis.gov.et/"
                target="_blank"
              >
                E-LMIS
              </Link>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/mofaLogo.jpg"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa/Account/Login/enjazcompany"
                target="_blank"
              >
                Visa Platform
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa/Enjaz/GetVisaInformation/Person"
                target="_blank"
              >
                Wekala
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://visa.mofa.gov.sa"
                target="_blank"
              >
                Issued Print
              </Link>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://tawtheeq.musaned.com.sa/"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/musaned2.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://tawtheeq.musaned.com.sa/"
                target="_blank"
              >
                Musaned
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://www.wafid.com/book-appointment/"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/wafid2.png"
                alt="Visa Platform"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/book-appointment/"
                target="_blank"
              >
                New
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/medical-status-search/"
                target="_blank"
              >
                Medical Results
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.wafid.com/search-slip/"
                target="_blank"
              >
                Get Slips
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Card key="0" sx={{ p: 2, maxWidth: 245, minHeight: 350 }}>
            <CardActionArea
              href="https://www.ococ.gov.et/index.php/confirm-cer"
              target="_blank"
            >
              <CardMedia
                sx={{ height: 200, width: 200, objectFit: "scale-down" }}
                component="img"
                image="/COCLogo.png"
                alt="COC"
              />
            </CardActionArea>
            <CardActions sx={{ display: "flex", flexDirection: "column" }}>
              <Link
                color="secondary"
                variant="h4"
                href="https://lmis.gov.et/coc-check"
                target="_blank"
              >
                Federal COC
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.ococ.gov.et/index.php/confirm-cer"
                target="_blank"
              >
                Oromiya COC
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.amharacoc.gov.et/code/certificate.html"
                target="_blank"
              >
                Amhara COC
              </Link>
              <Link
                color="secondary"
                variant="h4"
                href="https://www.snnprocaa.gov.et/"
                target="_blank"
              >
                SNNP COC
              </Link>
            </CardActions>
          </Card>
        </Grid>
        {/* <Grid item xs={5}>
          <Card key={"video.id"} sx={{ p: 2 }}>
            <CardActionArea sx={{ display: "flex", flexDirection: "column" }}>
              <CardHeader
                sx={{ color: "primary.main" }}
                title={`Manage Employee Status`}
              />
              <Stack direction="row" spacing={1}>
                <CardMedia
                  sx={{
                    height: 200,
                    width: 200,
                    objectFit: "fill",
                    alignSelf: "left",
                  }}
                  component="img"
                  image={`/enjazPLogoFilled.png`}
                  alt={"Dashboard"}
                />

                <CardMedia
                  sx={{
                    height: 200,
                    width: 200,
                    objectFit: "fill",
                    alignSelf: "right",
                  }}
                  component="img"
                  image={`/Dashboard.png`}
                  alt={"Enjaz in 150 Seconds"}
                />
              </Stack>
              <CardContent>
                <Typography variant="h6" color="secondary.main">
                  {"Manage Current Status of Employees"}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid> */}
      </Grid>
    </Box>
  );
}
