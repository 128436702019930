import Box from "@mui/material/Box";

import CvDetailCarousel from "./CvDetailCarousel";
import CvDetailTemplate from "./CvDetailTemplate";
import { Theme, useMediaQuery } from "@mui/material";
import PremiumTemplate from "../premiumCvs/PremiumTemplate";

interface Props {
  isPremium: boolean;
}

export const CvDetailFormat = ({ isPremium }: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <div>
      <Box
        sx={{
          backgroundColor: "transparent",
          minHeight: "100%",
          py: 3,
        }}
      >
        {isSmallScreen ? (
          <CvDetailCarousel />
        ) : isPremium ? (
          <PremiumTemplate />
        ) : (
          <CvDetailTemplate />
        )}
      </Box>
    </div>
  );
};
