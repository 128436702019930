import {
  Edit,
  MoreVert,
  RemoveRedEye,
  Share,
  ThumbUp,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Employee } from "../types/processTypes";
import {
  StyledListItemIcon,
  StyledListItemText,
} from "../../../styles/listStyled";

interface Props {
  row: Employee;
  selectCandidate: Function;
  viewCvHandler: Function;
  shareSingle: Function;
}

export function RowMenu({
  row,
  selectCandidate,
  viewCvHandler,
  shareSingle,
}: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        id={row._id as string}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: theme.direction === "ltr" ? "right" : "left",
        }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.default",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {[
          <MenuItem key="11" onClick={() => selectCandidate(row._id as string)}>
            <StyledListItemIcon active={0}>
              <ThumbUp color="secondary" />
            </StyledListItemIcon>
            <StyledListItemText active={0}>Select</StyledListItemText>
          </MenuItem>,
          <MenuItem key="12" onClick={() => viewCvHandler(row._id as string)}>
            <StyledListItemIcon active={0}>
              <RemoveRedEye color="secondary" />
            </StyledListItemIcon>
            <StyledListItemText active={0}>View Detail</StyledListItemText>
          </MenuItem>,
          // <Divider key="15" />,

          <MenuItem
            key="13"
            onClick={handleMenuClose}
            component={NavLink}
            to={`/app/employee/${row._id as string}`}
          >
            <StyledListItemIcon active={0}>
              <Edit fontSize="small" color="secondary" />
            </StyledListItemIcon>
            <StyledListItemText active={0}>Edit Data</StyledListItemText>
          </MenuItem>,
          <MenuItem key="14" onClick={() => shareSingle(row._id as string)}>
            <StyledListItemIcon active={0}>
              <Share fontSize="small" color="secondary" />
            </StyledListItemIcon>
            <StyledListItemText active={0}>Share</StyledListItemText>
          </MenuItem>,
        ]}
      </Menu>

      <IconButton
        aria-label="account of current user"
        aria-controls={row._id as string}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        size="large"
        color="secondary"
        // sx={{ alignSelf: "flex-end", marginBottom: "-30px" }}
        sx={{
          "& > :not(style)": {
            mt: -1,
            mr: theme.direction === "ltr" ? -2 : 0,
            ml: theme.direction === "rtl" ? -2 : 0,
          },
        }}
      >
        <MoreVert />
      </IconButton>
    </>
  );
}
