import React, { ChangeEvent, useState } from "react";
import Avatar from "@mui/material/Avatar";
import {
  updateProfile,
  deleteFile,
  selectAuth,
  uploadProfilePicture,
} from "../../features/auth/authSlice";
import Delete from "@mui/icons-material/Delete";
import AddAPhoto from "@mui/icons-material/AddAPhoto";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { AuthUser } from "../../features/auth/types/authType";
import { useTheme } from "@mui/material";
import Toast from "../Layout/Toast";
import { PhotoTypes } from "../../features/process/types/processTypes";
import { resizeFile } from "../../utils/imageUtility";

function AccountHeader() {
  const [image, setImage] = useState("");
  const theme = useTheme();
  //const [error, setError] = useState<string | undefined>(undefined);
  const { user, error } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  // const onChangeResize = async (event: ChangeEvent<HTMLInputElement>) => {
  //   try {
  //     const file = event.target.files[0];
  //     const image = await resizeFile(file);
  //     console.log(image);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;

    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 200, 200)) as File;
      //console.log(image);
      //console.log(files[0].name);
      //the file should be less than 1 Mb===1000000
      // if (files[0].size >= 1000000) {
      //   setError("file exceeds");
      //   setTimeout(() => {
      //     setError(undefined);
      //   }, 6000);
      // } else
      if (image) {
        dispatch(
          uploadProfilePicture({
            type: PhotoTypes.User,
            _id: user!._id as string,
            avatar: image,
          })
        );
      }
    }
  };

  const removeImage = () => {
    setImage("");
    let prof = user;
    prof = { ...prof, avatar: "" } as AuthUser;
    dispatch(updateProfile(prof));
    dispatch(deleteFile(user?.avatar as string));
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{
          position: "relative",
          borderRadius: 18,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          name="image"
          accept="image/*"
          onChange={onChange}
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <>
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={
                <Tooltip title="Change Image" aria-label="icon-button-file">
                  <Fab component="span" color="primary">
                    <IconButton
                      aria-label="icon-button-file"
                      component="span"
                      size="large"
                    >
                      <AddAPhoto />
                    </IconButton>
                  </Fab>
                </Tooltip>
              }
            >
              <Avatar
                alt="avatar"
                src={image ? image : user ? user.avatar : ""}
                sx={{
                  height: 200,
                  width: 200,
                  marginTop: -3,
                }}
              />
            </Badge>
          </>
        </label>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 18,
            marginBottom: 18,
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          {/* <Typography
            variant="h4"
            style={{
              fontSize: 26,
              fontWeight: "bold",
              textAlign: "center",
            }}
            noWrap
            width="200px"
          >
            {user && user.name}
          </Typography> */}
          <Typography noWrap variant="h6">
            {user && user.email}
          </Typography>

          {(image || (user && user.avatar)) && (
            <>
              <Box component="div" pt={3} pb={2}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={removeImage}
                  sx={{
                    margin: "auto",
                    marginBottom: theme.spacing(2),
                    width: "100%",
                  }}
                >
                  <Delete />
                </Button>
              </Box>
            </>
          )}
        </div>
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
}

export default AccountHeader;
