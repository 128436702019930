import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee, Skills } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";
import { Check, Close } from "@mui/icons-material";
import { BasicRate } from "./BasicRate";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicSkills = ({ currentEmployee, template }: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  template={template}
                  colSpan={2}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("washing_dishes", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("dusting_cleaning", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("baby_sitting", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("cooking", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("arabic_cooking", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("children_care", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("sewing", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="7">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("other_skills", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("other_skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsTwoRows = ({ currentEmployee, template }: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  template={template}
                  colSpan={3}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  colSpan={3}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("washing_dishes", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("dusting_cleaning", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("baby_sitting", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("cooking", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("arabic_cooking", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("children_care", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("sewing", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="7">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("other_skills", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  colSpan={4}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks &&
                  currentEmployee.education.remarks.length > 2
                    ? currentEmployee.education.remarks
                    : tt("willing_to_learn", template.cvLang)}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("other_skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsSmallHorz = ({ currentEmployee, template }: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={2}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "ar")}
                  <br />
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("arabic_cooking", "ar")}
                  <br />
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="11">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("children_care", "ar")}
                  <br />
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("sewing", "ar")}
                  <br />
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                ></StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="55">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                ></StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="7">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("other_skills", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  colSpan={2}
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks &&
                  currentEmployee.education.remarks.length > 2
                    ? currentEmployee.education.remarks
                    : tt("willing_to_learn", template.cvLang)}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsSmallHorzTwoLines = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={2}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="11">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsSmallHorzTwoLinesTwoLang = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={2}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "en")}
                  <br />
                  {tt("washing_dishes", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "en")}
                  <br />
                  {tt("washing_ironing_clothes", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "en")}
                  <br />
                  {tt("dusting_cleaning", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "en")}
                  <br />
                  {tt("baby_sitting", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "en")}
                  <br />
                  {tt("cooking", "ar")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("children_care", "en")}
                  <br />
                  {tt("children_care", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="11">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsOneLineHorz = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={5}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "ar")}
                  <br />
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("tutor", "ar")}
                  <br />
                  {tt("tutor", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.tutoring_children
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsTwoLinesHorz = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={5}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "ar")}
                  <br />
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("arabic_cooking", "ar")}
                  <br />
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("children_care", "ar")}
                  <br />
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("sewing", "ar")}
                  <br />
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  colSpan={4}
                  sx={{ textAlign: "center" }}
                >
                  {tt("other_skills", "ar")}
                  <br />
                  {tt("other_skills", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="55">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  colSpan={4}
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks &&
                  currentEmployee.education.remarks.length > 2
                    ? currentEmployee.education.remarks
                    : tt("willing_to_learn", template.cvLang)}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
export const BasicSkillsVer = ({ currentEmployee, template }: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_dishes", "ar")}
                  <br />
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("arabic_cooking", "ar")}
                  <br />
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("children_care", "ar")}
                  <br />
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("sewing", "ar")}
                  <br />
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="7">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("other_skills", "en")}
                  <br />
                  {tt("other_skills", "ar")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks &&
                  currentEmployee.education.remarks.length > 2
                    ? currentEmployee.education.remarks
                    : tt("willing_to_learn", template.cvLang)}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};

export const BasicSkillsVerValue = ({ currentEmployee, template }: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("skills", "en")}
                  <br />
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_dishes", "ar")}
                  <br />
                  {tt("washing_dishes", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_dishes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.washing_ironing_clothes
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.dusting_cleaning
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.baby_sitting
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.cooking) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("arabic_cooking", "ar")}
                  <br />
                  {tt("arabic_cooking", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.arabic_cooking
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("children_care", "ar")}
                  <br />
                  {tt("children_care", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(
                    Skills.children_care
                  ) > -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("sewing", "ar")}
                  <br />
                  {tt("sewing", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education?.skills?.indexOf(Skills.sewing) >
                  -1 ? (
                    <Check />
                  ) : (
                    <Close />
                  )}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>

              <StyledTableRowTemplate template={template} key="7">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("other_skills", "en")}
                  <br />
                  {tt("other_skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {currentEmployee.education.remarks &&
                  currentEmployee.education.remarks.length > 2
                    ? currentEmployee.education.remarks
                    : tt("willing_to_learn", template.cvLang)}
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};

export const BasicSkillsOneLineHorzStar = ({
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {currentEmployee.education && currentEmployee.education.skills && (
        <StyledTableContainer
          template={template}
          sx={{
            marginTop: template.spacing,
          }}
        >
          <Table size="small" aria-label="simple table">
            <TableHead>
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate
                  colSpan={5}
                  template={template}
                  sx={{ textAlign: "left" }}
                >
                  {tt("skills", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "right" }}
                >
                  {tt("skills", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("baby_sitting", "ar")}
                  <br />
                  {tt("baby_sitting", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("dusting_cleaning", "ar")}
                  <br />
                  {tt("dusting_cleaning", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("washing_ironing_clothes", "ar")}
                  <br />
                  {tt("washing_ironing_clothes", "en")}
                </StyledTableCellLabelTemplate>

                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  {tt("cooking", "ar")}
                  <br />
                  {tt("cooking", "en")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  <BasicRate
                    currentEmployee={currentEmployee}
                    template={template}
                    rateType="babySitting"
                  />
                </StyledTableCellValueTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  <BasicRate
                    currentEmployee={currentEmployee}
                    template={template}
                    rateType="cleaning"
                  />
                </StyledTableCellValueTemplate>

                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  <BasicRate
                    currentEmployee={currentEmployee}
                    template={template}
                    rateType="washing"
                  />
                </StyledTableCellValueTemplate>
                <StyledTableCellValueTemplate
                  template={template}
                  sx={{ textAlign: "center" }}
                >
                  <BasicRate
                    currentEmployee={currentEmployee}
                    template={template}
                    rateType="cooking"
                  />
                </StyledTableCellValueTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      )}
    </>
  );
};
