import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ColorButton, GetInTouchArrow } from "./styles/componentStyled";
import TypeWriter from "./TypeWriter";
import Box from "@mui/material/Box";
import { GradientBackground } from "../../styles/layoutStyled";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";

export default function Header() {
  //const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation("landing_page");
  return (
    <Box id="home" sx={{ color: "white", width: "100%" }}>
      {/* </GradientBackground>
      <GradientBackground> */}
      <Container maxWidth="lg">
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
            color: theme.palette.primary.main,
          }}
        >
          <img
            data-aos="fade-up"
            src="../Dashboard.png"
            alt="Web Development"
            width="100%"
          />
          <Box
            sx={{
              textAlign: "center",
              position: "absolute",
              top: "20%",
              left: "20%",
              transform: "translate(-20%, -20%)",
              width: "100%",
              padding: { xs: 2, md: 10 },
              background: "rgba(255, 255, 255, 0.85)",
              color: theme.palette.primary.main,
            }}
          >
            <Stack
              spacing={2}
              data-aos="fade-up"
              sx={{
                alignItems: "center",
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                sx={{ textAlign: "center", textTransform: "camelCase" }}
              >
                {"EnjazPlus"}
              </Typography>

              <Typography
                variant="h3"
                component="h3"
                sx={{ textAlign: "center", textTransform: "uppercase" }}
              >
                All-in-one Gulf jobs recruitment processing Application
              </Typography>

              <TypeWriter />

              <ColorButton variant="contained" href="/register">
                Register
                <GetInTouchArrow>
                  <ArrowForward />
                </GetInTouchArrow>
              </ColorButton>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
