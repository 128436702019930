import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchVisas,
  selectProcesses,
  removeVisa,
} from "../process/processSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";

import { StyledTableCell, StyledTableRow } from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import Paging from "../../components/Layout/Paging";
import Toast from "../../components/Layout/Toast";

export const Visas = () => {
  const dispatch = useAppDispatch();
  const {
    visasWithCount: { visas, totalCount },
    loading,
    success,
    error,
  } = useAppSelector(selectProcesses);
  //const { Visa } = useAppSelector(selectAuth);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedVisaId, setSelectedVisaId] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = (id: string) => {
    setSelectedVisaId(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DeleteVisa = () => {
    setOpen(false);
    dispatch(removeVisa(selectedVisaId));
  };
  useEffect(() => {
    dispatch(changePageTitle("Visas List"));

    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchVisas({
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    setTotal(totalCount as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount]);

  return (
    <>
      <Helmet>
        <title>Visas | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          justifyItems="center"
        >
          <Tooltip title="Add New Item">
            <Button
              color="secondary"
              variant="contained"
              component={RouterLink}
              to={`/app/visa/0`}
            >
              <Add />
            </Button>
          </Tooltip>
        </Stack>

        <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Visa No.</StyledTableCell>
                  <StyledTableCell>Employer Name</StyledTableCell>
                  <StyledTableCell>Employer Id</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading === "pending" ? (
                  <TableSkeleton numRows={5} numColumns={4} />
                ) : (
                  visas.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {currentPage * rowsPerPage + index + 1}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/app/visa/${row._id}`}
                          size="small"
                        >
                          {row.visaNumber}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/app/visa/${row._id}`}
                          size="small"
                        >
                          {row.fullName}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/app/visa/${row._id}`}
                          size="small"
                        >
                          {row.passportNumber}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <IconButton
                            color="primary"
                            component={RouterLink}
                            to={"/app/visa/" + row._id}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => handleClickOpen(row._id as string)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <br />
        {success && <Toast severity="success">{success.message}</Toast>}
        {error && <Toast severity="error">{error.message}</Toast>}
        <Stack spacing={1}>
          <Paging
            total={total}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Typography variant="h6" color="textPrimary" component="div">
            Number of Visas: {total}
          </Typography>
        </Stack>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Taking this action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          <Button onClick={DeleteVisa}>Continue</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
