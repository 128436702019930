import {
  LabelTemplate,
  StyledTableCellLabelTemplate,
  StyledTableContainer,
  StyledTableRowTemplate,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import QRCodeStyling from "qr-code-styling";
import { useEffect } from "react";
import { Box, Stack, Table, TableBody, TableHead } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCompany, selectSetups } from "../../../../setups/setupSlices";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  type: String;
}
export const InterLocation = ({ currentEmployee, template, type }: Props) => {
  const dispatch = useAppDispatch();

  const { selectedCompany } = useAppSelector(selectSetups);

  useEffect(() => {
    if (currentEmployee) {
      if (
        currentEmployee.prospects &&
        currentEmployee.prospects[0] &&
        currentEmployee.prospects[0]._id
      ) {
        dispatch(getCompany(currentEmployee.prospects[0]._id));
      } else if (currentEmployee.owner && currentEmployee.owner._id) {
        dispatch(getCompany(currentEmployee.owner._id));
      }
    }
  }, [dispatch, currentEmployee]);

  useEffect(() => {
    if (currentEmployee._id && template.bgColor && template.headerGradient) {
      const qrCanvas = document.getElementById(
        "qrLocationCanvas"
      ) as HTMLElement;
      qrCanvas.innerHTML = "";
      const hg = template.headerGradient;

      let qrWidth = 240;
      if (template.qrLocationWidth && template.qrMoreCvsWidth)
        qrWidth =
          type === "location"
            ? template.qrLocationWidth
            : template.qrMoreCvsWidth;

      let qrHeight = 240;
      if (template.qrLocationHeight && template.qrMoreCvsHeight)
        qrHeight =
          type === "location"
            ? template.qrLocationHeight
            : template.qrMoreCvsHeight;

      let qrMargin = 0;
      if (template.qrLocationMargin && template.qrMoreCvsMargin)
        qrMargin =
          type === "location"
            ? template.qrLocationMargin
            : template.qrMoreCvsMargin;
      let qrLink = "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8";
      if (template.qrLocationLink && template.qrMoreCvsLink)
        qrLink =
          type === "location"
            ? template.qrLocationLink
            : template.qrMoreCvsLink;
      let qrImageUrl = "/googleMaps.png";
      if (template.qrLocationImageUrl && template.qrMoreCvsImageUrl)
        qrImageUrl =
          type === "location"
            ? template.qrLocationImageUrl
            : template.qrMoreCvsImageUrl;
      const qrCode = new QRCodeStyling({
        width: qrWidth,
        height: qrHeight,
        type: "svg",
        shape: "square",
        margin: qrMargin,
        data: qrLink,
        image: qrImageUrl,
        dotsOptions: {
          type: "classy-rounded",
          color: "#fffff",
          gradient: {
            type: "radial",
            rotation: 0,
            colorStops: [
              { offset: 0, color: hg.firstColor },
              { offset: 1, color: hg.secondColor },
            ],
          },
        },
        cornersSquareOptions: {
          color: hg.firstColor,
          type: "extra-rounded",
        },
        cornersDotOptions: {
          color: hg.secondColor,
        },
        backgroundOptions: {
          color: template.bgColor,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 2,
        },
      });
      qrCode.append(qrCanvas);
    }
  }, [currentEmployee, template]);
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      spacing={1}
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        borderColor: template.borderColor,
        borderWidth: template.borderWidth,
        borderStyle: template.borderStyle,
        p: 1,
      }}
    >
      <Stack spacing={3} direction="row">
        <Box
          sx={{
            borderRadius: template.borderRadius,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
            p: 1,
          }}
        >
          <Box id="qrLocationCanvas"></Box>
        </Box>

        <StyledTableContainer template={template}>
          <Table size="small" aria-label="simple table" sx={{ width: 200 }}>
            <TableHead>
              <StyledTableRowTemplate template={template} key="40">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`ارقام خدمة العملاء`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableHead>
            <TableBody>
              <StyledTableRowTemplate template={template} key="41">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`${selectedCompany.address?.mobile}`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="42">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`${selectedCompany.address?.alternateMobile}`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="43">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`${selectedCompany.address?.telephone}`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
              <StyledTableRowTemplate template={template} key="44">
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {`${selectedCompany.address?.alternateTelephone}`}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Stack>
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableHead></TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="45">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {`مخرج 5ـ طريق الملك عبدالعزيز ـ حي الغدير ـ الرياض`}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Stack>
  );
};
