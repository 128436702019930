import { Attachment, ExpandMore, Print, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  Button,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { VisaFilter } from "../../../components/filter/VisaFilter";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { CountriesList, Employee, VisaTypes } from "../types/processTypes";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import {
  assignEmployeeVisa,
  fetchVisas,
  selectProcesses,
} from "../processSlices";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";

interface ExperienceTabProps {
  formikData: FormikProps<Employee>;
  printPdf?: any;
  printVisibility?: string;
}

export const VisaBriefCommission = ({
  formikData,
  printPdf,
  printVisibility,
}: ExperienceTabProps) => {
  const { t: te } = useTranslation("enums");
  const { t } = useTranslation();
  const [visaId, setVisaId] = useState<string>("");
  const dispatch = useAppDispatch();

  const {
    visasWithCount: { visas },
  } = useAppSelector(selectProcesses);

  useEffect(() => {
    dispatch(
      fetchVisas({
        skip: 0,
        take: 400,
      })
    );
  }, [dispatch]);

  const attachVisa = () => {
    dispatch(
      assignEmployeeVisa([formikData.values._id as string, visaId] as string[])
    );
  };
  const visaEntered = (visaNumber: string) => {
    const visa = visas.find((v) => v.visaNumber === visaNumber);
    if (visa) {
      formikData.setFieldValue("visa", visa);
      return true;
    }
    return false;
  };

  return (
    <Stack>
      <Accordion expanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Visa Entry
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={4} xs={12}>
              {/* <CompanyFilter
                companyType={ClientTypes.ForeignAgent}
                setCompany={setCompany}
              /> */}
              <FormikTextField
                formikKey="visa.visaType"
                select
                label="Visa Type"
              >
                {Object.keys(VisaTypes).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te((option as string).toLowerCase())}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item sm={8} xs={12}></Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.visaNumber"
                label="Visa Number"
                onKeyUp={() => {
                  const visNum = formikData?.values?.visa?.visaNumber as string;
                  if (visNum?.length === 10) visaEntered(visNum);
                }}
                onPaste={() => {
                  const visNum = formikData?.values?.visa?.visaNumber as string;
                  if (visNum?.length === 10) visaEntered(visNum);
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField formikKey="visa.fullName" label="Sponsor Name" />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.passportNumber"
                label="Sponsor Id"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.telephone"
                label="Sponsor Telephone"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.city"
                label="Sponsor city"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.country"
                select
                label="Sponsor Country"
              >
                {Object.keys(CountriesList).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te((option as string).toLowerCase())}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="embassyProcess.enjazNumber"
                label="Application Number"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!formikData.isValid}
                size="large"
                sx={{ mt: 1, width: "100%" }}
              >
                <Save />
                {t("save") + " " + t("visa")}
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                onClick={printPdf}
                size="large"
                sx={{ display: printVisibility, mt: 1, width: "100%" }}
              >
                <Print /> Print First Page
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Search & Attach Visa
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item md={8} xs={12}>
              <VisaFilter setVisaId={setVisaId} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                sx={{ width: "100%", mt: 1 }}
                color="secondary"
                variant="contained"
                onClick={attachVisa}
                disabled={!visaId || visaId.length === 0}
              >
                <Attachment />
                Attach Visa
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
