import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import QRCode from "qrcode";

import { Employee } from "../../../features/process/types/processTypes";
import { AuthUser } from "../../../features/auth/types/authType";
import JsBarcode from "jsbarcode";

const qrCanvas = document.createElement("canvas");

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
interface Props {
  employees: Employee[];
  user: AuthUser;
}
const enjazCanvas = document.createElement("canvas");

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 25,
  },
  row21: {
    borderColor: "#000000",
    borderWidth: 2,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 20,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Open Sans",
  },
  header: {
    position: "absolute",
    top: 10,
    left: 0,
    right: 0,
    height: "80px",
    marginBottom: 20,
  },
  image: {
    width: 100,
    height: 18,
  },
  qrCode: {
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    height: "35px",
    display: "flex",
    marginTop: -10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
export const EmbassyList = ({ employees, user }: Props) => {
  QRCode.toCanvas(qrCanvas, "https://www.enjazplus.com?lng=ar");
  const qr = qrCanvas.toDataURL("png");
  return (
    <Document
      creator="pinnasofts"
      author="pinnaSofts"
      title="enjaz+"
      subject="Embassy List"
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header} fixed>
          <Image
            source={
              user && user.company && user.company?.header
                ? user.company?.header
                : "/noHeader.png"
            }
          />
        </View>
        <View style={{ marginTop: 100 }}>
          <View style={[styles.row2]}>
            <View style={[styles.row21, { width: "5%" }]}>
              <Text style={styles.cellCenter}>S.No</Text>
            </View>
            <View style={[styles.row21, { width: "30%" }]}>
              <Text style={styles.cellCenter}>Applicant Name</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>Passport No.</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>Nationality</Text>
            </View>
            <View style={[styles.row21, { width: "15%" }]}>
              <Text style={styles.cellCenter}>Application No.</Text>
            </View>
            <View
              style={[
                styles.row21,
                { marginRight: 0, borderRightWidth: 1.5, width: "20%" },
              ]}
            >
              <Text style={styles.cellCenter}>Barcode</Text>
            </View>
          </View>
          {employees.map((employee, index) => {
            JsBarcode(
              enjazCanvas,
              employee.embassyProcess?.enjazNumber as string,
              {
                format: "code128a",
                width: 1,
                height: 25,
                displayValue: false,
                margin: 0,
                background: "#ffffff",
                lineColor: "#000000",
              }
            );
            const enjazNoBarcode = enjazCanvas.toDataURL();
            return (
              employee &&
              employee.visa &&
              employee.passportNumber &&
              employee.passportNumber.length > 0 &&
              employee.embassyProcess &&
              employee.embassyProcess.enjazNumber && (
                <View style={[styles.row3]} key={index}>
                  <View style={[styles.row31, { width: "5%" }]}>
                    <Text style={styles.cellCenter}>{index + 1}</Text>
                  </View>
                  <View style={[styles.row31, { width: "30%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.givenName + " " + employee.surName}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.passportNumber}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.additionalData?.nationality?.toUpperCase()}
                    </Text>
                  </View>
                  <View style={[styles.row31, { width: "15%" }]}>
                    <Text style={styles.cellCenter}>
                      {employee.embassyProcess.enjazNumber}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.row31,
                      { marginRight: 0, borderRightWidth: 1.5, width: "20%" },
                    ]}
                  >
                    <Text style={styles.cellCenter}>
                      <Image style={styles.image} source={enjazNoBarcode} />
                    </Text>
                  </View>
                </View>
              )
            );
          })}
        </View>
        <View style={styles.qrCode} fixed>
          <Image source={qr} />
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Text>EnjazPlus</Text>
            <Text>+251-911-850-908</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
