import { Table, TableHead, TableBody } from "@mui/material";
import { differenceInMonths, format } from "date-fns";

import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  showHeader?: boolean | true;
  showReference?: boolean | false;
  showNationality?: boolean | true;
  showPassportNumber?: boolean | false;
  showReligion?: boolean | true;
  showDateOfBirth?: boolean | true;
  showAge?: boolean | true;
  showMaritalStatus?: boolean | true;
  showNumberOfChildren?: boolean | true;
  showWeightHeight?: boolean | true;
  showComplexion?: boolean | true;
  showEducation?: boolean | false;
  showPlaceOfBirth?: boolean | false;
  showLivingTown?: boolean | false;
  showEmployeeTelephone?: boolean | false;
  showContactName?: boolean | false;
  showContactTelephone?: boolean | false;
  showYearGraduated?: boolean | false;
  showGraduatedSchool?: boolean | false;
}
export const BasicDetail = ({
  currentEmployee,
  template,
  showHeader,
  showReference,
  showNationality,
  showPassportNumber,
  showReligion,
  showDateOfBirth,
  showAge,
  showMaritalStatus,
  showNumberOfChildren,
  showWeightHeight,
  showComplexion,
  showEducation,
  showPlaceOfBirth,
  showLivingTown,
  showEmployeeTelephone,
  showContactName,
  showContactTelephone,
  showYearGraduated,
  showGraduatedSchool,
}: Props) => {
  return (
    <>
      <StyledTableContainer
        template={template}
        sx={{
          marginTop: template.spacing,
        }}
      >
        <Table size="medium" aria-label="simple table">
          <TableHead>
            {showHeader && (
              <StyledTableRowTemplate template={template}>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "left",
                    width: "33%",
                  }}
                >
                  {tt("detailsOfApplication", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{ width: "34%" }}
                ></StyledTableCellLabelTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                    width: "33%",
                  }}
                >
                  {tt("detailsOfApplication", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableHead>
          <TableBody>
            {showReference && (
              <StyledTableRowTemplate template={template} key="0">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("referenceNumber", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.education?.yearCompleted as string}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("referenceNumber", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showNationality && (
              <StyledTableRowTemplate template={template} key="120">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("nationality", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.additionalData?.nationality as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("nationality", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showPassportNumber && (
              <StyledTableRowTemplate template={template} key="130">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("passportNo", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.passportNumber as string}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("passportNo", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showReligion && (
              <StyledTableRowTemplate template={template} key="1">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("religion", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.additionalData?.religion as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("religion", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showDateOfBirth && (
              <StyledTableRowTemplate template={template} key="2">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("dateOfBirth", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {template.cvLang === "en"
                    ? format(
                        new Date(currentEmployee.dateOfBirth as Date),
                        "dd-MMM-yyyy"
                      )
                    : new Date(
                        currentEmployee.dateOfBirth as Date
                      ).toLocaleDateString("ar-SA", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("dateOfBirth", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showPlaceOfBirth && (
              <StyledTableRowTemplate template={template} key="101">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("placeOfBirth", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.placeOfBirth as string}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("placeOfBirth", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showAge && (
              <StyledTableRowTemplate template={template} key="3">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("age", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {Math.floor(
                    differenceInMonths(
                      new Date(),
                      new Date(currentEmployee.dateOfBirth as Date)
                    ) / 12
                  ).toLocaleString(
                    template.cvLang === "ar" ? "ar-SA" : "en-US"
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("age", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showMaritalStatus && (
              <StyledTableRowTemplate template={template} key="5">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("maritalStatus", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.additionalData?.maritalStatus as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("maritalStatus", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showNumberOfChildren && (
              <StyledTableRowTemplate template={template} key="6">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("numberOfChildren", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.additionalData?.numberOfChildren?.toLocaleString(
                    template.cvLang === "ar" ? "ar-SA" : "en-US"
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("numberOfChildren", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showWeightHeight && (
              <>
                <StyledTableRowTemplate template={template} key="7">
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("weight", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate template={template}>
                    {currentEmployee.additionalData?.weight === 0
                      ? "-"
                      : currentEmployee.additionalData?.weight?.toLocaleString(
                          template.cvLang === "ar" ? "ar-SA" : "en-US"
                        ) + tt("kg", template.cvLang)}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("weight", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>

                <StyledTableRowTemplate template={template} key="77">
                  <StyledTableCellLabelTemplate template={template}>
                    {tt("height", "en")}
                  </StyledTableCellLabelTemplate>
                  <StyledTableCellValueTemplate template={template}>
                    {currentEmployee.additionalData?.height === 0
                      ? "-"
                      : currentEmployee.additionalData?.height?.toLocaleString(
                          template.cvLang === "ar" ? "ar-SA" : "en-US"
                        ) + tt("meter", template.cvLang)}
                  </StyledTableCellValueTemplate>
                  <StyledTableCellLabelTemplate
                    template={template}
                    sx={{
                      textAlign: "right",
                    }}
                  >
                    {tt("height", "ar")}
                  </StyledTableCellLabelTemplate>
                </StyledTableRowTemplate>
              </>
            )}
            {showComplexion && (
              <StyledTableRowTemplate template={template} key="9">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("complexion", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.additionalData?.complexion as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("complexion", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showEducation && (
              <StyledTableRowTemplate template={template} key="10">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("education_level", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {tt(
                    currentEmployee.education?.educationLevel as string,
                    template.cvLang
                  )}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("education_level", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showLivingTown && (
              <StyledTableRowTemplate template={template} key="11">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("living_town", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.address
                    ? (currentEmployee.address?.city as string)
                    : ""}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("living_town", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showEmployeeTelephone && (
              <StyledTableRowTemplate template={template} key="12">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("employee_telephone", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.address
                    ? (currentEmployee.address?.telephone as string)
                    : ""}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("employee_telephone", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showContactName && (
              <StyledTableRowTemplate template={template} key="13">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("contact_name", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.contactPerson
                    ? (currentEmployee.contactPerson?.displayName as string)
                    : ""}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("contact_name", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showContactTelephone && (
              <StyledTableRowTemplate template={template} key="14">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("contact_telephone", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  {currentEmployee.contactPerson &&
                  currentEmployee.contactPerson.address
                    ? (currentEmployee.contactPerson.address
                        ?.telephone as string)
                    : ""}
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("contact_telephone", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showYearGraduated && (
              <StyledTableRowTemplate template={template} key="141">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("year_graduated", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  2023
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("year_graduated", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
            {showGraduatedSchool && (
              <StyledTableRowTemplate template={template} key="142">
                <StyledTableCellLabelTemplate template={template}>
                  {tt("graduated_school", "en")}
                </StyledTableCellLabelTemplate>
                <StyledTableCellValueTemplate template={template}>
                  COC center
                </StyledTableCellValueTemplate>
                <StyledTableCellLabelTemplate
                  template={template}
                  sx={{
                    textAlign: "right",
                  }}
                >
                  {tt("graduated_school", "ar")}
                </StyledTableCellLabelTemplate>
              </StyledTableRowTemplate>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
