import { ReactNode } from "react";
import { Navigate, Outlet } from "react-router-dom";
import {
  Forgot,
  Profile,
  Reset,
  SignIn,
  SignUp,
  Verify,
} from "../features/auth";
import { Role } from "../features/auth/types/authType";
import { RoleTypes } from "../features/auth/types/roleTypes";
import Dashboard from "../features/dashboard/Dashboard";
import Error from "../features/error/Error";
import LandingPage from "../features/home/LandingPage";
import { Candidates } from "../features/process/Candidates";
import { CheckStatus } from "../features/process/CheckStatus";
import CvDetailCarousel from "../features/process/cvs/CvDetailCarousel";
import { EmployeeEntry } from "../features/process/EmployeeEntry";
import { Employees } from "../features/process/Employees";
import { VisaEntry } from "../features/process/VisaEntry";
import { Visas } from "../features/process/Visas";
import { Companies } from "../features/setups/Companies";
import { CompanyProfile } from "../features/setups/CompanyProfile";
import { Users } from "../features/setups/users";
import DashboardLayout from "./DashboardLayout";
import MainLayout from "./MainLayout";
import Complaints from "../features/process/Complaints";
import { CvDetailFormat } from "../features/process/cvs/CvDetailFormat";
import Tutors from "./tutors/Tutors";
import TutorDetail from "./tutors/TutorDetail";
import Support from "./tutors/Support";
import { CommissionEntry } from "../features/process/CommissionEntry";
import { Commissions } from "../features/process/Commissions";
import ImportantLinks from "../features/process/ImportantLinks";
import { Reports } from "./reports/Reports";
import PremiumTemplate from "../features/process/premiumCvs/PremiumTemplate";
import PremiumTemplateMaker from "../features/process/premiumCvs/PremiumTemplateMaker";
import { PremiumCandidates } from "../features/process/premiumCvs/PremiumCandidates";

let PrivilegedRoles: Role[] = [];
const routes = (isLoggedIn: Boolean, roles: Role[], agencyType: String) => {
  PrivilegedRoles = roles;
  return [
    {
      path: "/app",
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        {
          path: "dashboard",
          element: isPrivileged(<Dashboard />, RoleTypes.ViewDashboard),
        },

        { path: "profile", element: <Profile /> },
        { path: "tutors", element: <Tutors /> },
        { path: "tutor/:id", element: <TutorDetail /> },
        { path: "support", element: <Support /> },
        { path: "complaints", element: <Complaints /> },
        { path: "links", element: <ImportantLinks /> },
        { path: "reports", element: <Reports /> },
        { path: "cp", element: <CompanyProfile /> },
        { path: "cp/:id/:type", element: <CompanyProfile /> },
        { path: "cvs", element: <Candidates isPremium={false} /> },
        { path: "cvd", element: <CvDetailFormat isPremium={false} /> },
        { path: "pcv", element: <PremiumCandidates isPremium={true} /> },
        {
          path: "cvp",
          element: isPrivileged(
            <CvDetailFormat isPremium={true} />,
            RoleTypes.EditEmployees
          ),
        },
        {
          path: "cvm",
          element: isPrivileged(
            <PremiumTemplateMaker />,
            RoleTypes.ViewCompanies
          ),
        },
        {
          path: "employees",
          element: isPrivileged(<Employees />, RoleTypes.ViewEmployees),
        },
        {
          path: "employee/:id",
          element: isPrivileged(<EmployeeEntry />, RoleTypes.ViewEmployees),
        },
        {
          path: "coms",
          element: isPrivileged(<Commissions />, RoleTypes.EnjazEntry),
        },
        {
          path: "com/:id",
          element: isPrivileged(<CommissionEntry />, RoleTypes.EnjazEntry),
        },
        {
          path: "visas",
          element: isPrivileged(<Visas />, RoleTypes.AddVisas),
        },
        {
          path: "visa/:id",
          element: isPrivileged(<VisaEntry />, RoleTypes.AddVisas),
        },
        {
          path: "users",
          element: isPrivileged(<Users />, RoleTypes.ViewCompanies),
        },
        // { path: "user/:id", element: <User /> },
        {
          path: "c",
          element: isPrivileged(<Companies />, RoleTypes.ViewCompanies),
        },
        {
          path: "/app",
          element: (
            <Navigate
              to={agencyType === "employment" ? "/app/employees" : "/app/coms"}
            />
          ),
        },
        {
          path: "member",
          element: <Outlet />,
          children: [
            { path: "/app/member", element: <Profile /> },
            { path: "/app/member/add", element: <Profile /> },
          ],
        },
      ],
    },
    {
      path: "c/:link",
      element: <CvDetailCarousel />,
    },
    {
      path: "cpp/:agencyId/:agentId",
      element: <PremiumTemplate />,
    },
    {
      path: "pcv/:agencyId/:agentId",
      element: <PremiumCandidates isPremium={true} />,
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "home", element: <LandingPage /> },
        { path: "ts", element: <CheckStatus /> },
        { path: "login", element: <SignIn /> },
        { path: "register", element: <SignUp /> },
        {
          path: "verify/:id/:token/:expires/:signature",
          element: <Verify />,
        },
        {
          path: "ccv/:link",
          element: <CvDetailCarousel />,
        },
        { path: "forgotPassword", element: <Forgot /> },
        { path: "reset/:id/:token", element: <Reset /> },
        { path: "404", element: <Error /> },
        { path: "/", element: <Navigate to="/home" /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ];
};
// !isLoggedIn ? <MainLayout /> : <Navigate to="/app/cvs" />;
function isPrivileged(element: ReactNode, privileged: string): ReactNode {
  if (
    PrivilegedRoles.some((r) => r.displayName === privileged) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("Add", "View")
    ) ||
    PrivilegedRoles.some(
      (r) => r.displayName === privileged.replace("View", "Add")
    )
  ) {
    return element;
  }

  return <Navigate to="/app/profile" />;
}
export default routes;
//privileged.substr(0, 3) === "Add" &&
// else {
//     if (
//       PrivilegedRoles.some(
//         (r) => r.displayName === privileged.replace("Add", "View")
//       )
//     )
