import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import Image from "mui-image";
import { Box, Stack } from "@mui/material";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicFace = ({ currentEmployee, template }: Props) => {
  return (
    <Box sx={{ boxShadow: template.photoShadow }}>
      <Image
        src={
          currentEmployee && currentEmployee.facePhoto
            ? (currentEmployee.facePhoto as string)
            : "/blankShort.jpg"
        }
        alt={currentEmployee.passportNumber}
        width={template.faceWidth}
        height={template.faceHeight}
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          marginTop: template.spacing,
          borderColor: template.borderColor,
          borderWidth: (template.borderWidth as number) * 3,
          borderStyle: template.borderStyle,
        }}
      />
    </Box>
  );
};
export const BasicFaceWithBg = ({ currentEmployee, template }: Props) => {
  return (
    <Stack
      sx={{
        width: "100%",
        borderRadius: template.borderRadius,
        borderWidth: template.borderWidth,
        borderColor: template.borderColor,
        borderStyle: template.borderStyle,
        boxShadow: template.bodyShadow,
        backgroundColor: template.headBgColor,
        p: 2,
      }}
      alignItems="center"
    >
      <Image
        src={
          currentEmployee && currentEmployee.facePhoto
            ? (currentEmployee.facePhoto as string)
            : "/blankShort.jpg"
        }
        alt={currentEmployee.passportNumber}
        width={template.faceWidth}
        height={template.faceHeight}
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          marginTop: template.spacing,
          borderColor: template.borderColor,
          borderWidth: (template.borderWidth as number) * 3,
          borderStyle: template.borderStyle,
        }}
      />
    </Stack>
  );
};
