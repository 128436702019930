import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { Form, FormikProps, Formik } from "formik";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import { Navigate } from "react-router-dom";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import LockRounded from "@mui/icons-material/LockRounded";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { reset, selectAuth } from "./authSlice";
import { FormikTextField } from "../../components/Layout/FormikTextField";

import AuthSkeleton from "./AuthSkeleton";
import { resetSchema } from "./validation";
import Toast from "../../components/Layout/Toast";
import Typography from "@mui/material/Typography";
import { ResetAuth } from "./types/authType";
import { Container, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Reset = () => {
  const { loading, error, user, success } = useAppSelector(selectAuth);
  const { t: ta } = useTranslation("auth");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id, token } = useParams() as {
    token: string;
    id: string;
  };

  if (user && user.email) {
    return <Navigate to="/" />;
  }
  if (success) {
    navigate("/login");
  }

  return (
    <>
      <Helmet>
        <title>Reset Password | EnjazPlus</title>
      </Helmet>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Card elevation={3} sx={{ m: 2 }}>
          <CardContent>
            <Stack>
              <Stack sx={{ mb: 3, alignItems: "center" }}>
                <Typography variant="h2">{ta("resetPassword")}</Typography>
              </Stack>

              {loading === "pending" ? (
                <AuthSkeleton />
              ) : (
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    showPassword: false,
                    id: id,
                    token: token as string,
                  }}
                  validationSchema={resetSchema}
                  onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    dispatch(reset(values));
                  }}
                >
                  {(props: FormikProps<ResetAuth>) => (
                    <Form>
                      <FormikTextField
                        formikKey="password"
                        label={ta("newPassword")}
                        type={props.values.showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockRounded />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormikTextField
                        formikKey="confirmPassword"
                        label={ta("confirmNewPassword")}
                        type={props.values.showPassword ? "text" : "password"}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockRounded />
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormControlLabel
                        style={{ marginBottom: "0" }}
                        control={
                          <Switch
                            checked={props.values.showPassword}
                            onChange={props.handleChange("showPassword")}
                            name="showPassword"
                          />
                        }
                        label={ta("showPassword")}
                      />

                      {error && <Toast severity="error">{error.message}</Toast>}
                      <Box component="div" pt={2}>
                        <Button
                          sx={{ width: "100%", marginY: "8px" }}
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={!props.isValid}
                        >
                          {ta("resetPassword")}
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
