import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
//import { orange } from "@mui/material/colors";
import { FormatListNumbered, Summarize } from "@mui/icons-material";
interface SummaryProps {
  label: string;
  value: number;
  loading: string;
  color: string;
  avatarColor: string;
  totalCount: number;
}
export const TotalValues = ({
  label,
  value,
  loading,
  color,
  avatarColor,
  totalCount,
}: SummaryProps) => (
  <Card sx={{ backgroundColor: color }}>
    <CardContent>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            color="white"
            gutterBottom
            variant="h4"
            sx={{ fontWeight: "900" }}
          >
            {label}
          </Typography>
          <Avatar
            sx={{
              backgroundColor: avatarColor,
              height: 40,
              width: 40,
            }}
          >
            <FormatListNumbered />
          </Avatar>
        </Stack>
        {loading === "pending" ? (
          <Skeleton variant="text" />
        ) : (
          <Stack sx={{ backgroundColor: avatarColor, p: 1 }}>
            <Typography color="white" variant="h3">
              {`${value.toLocaleString()} / ${totalCount.toLocaleString()}`}
            </Typography>
            <Typography color="white" variant="h3">
              {value === totalCount
                ? "100%"
                : `${((value * 100) / totalCount).toPrecision(2)}%`}
            </Typography>
          </Stack>
        )}
      </Box>
    </CardContent>
  </Card>
);

export const Summary = ({
  label,
  value,
  loading,
  color,
  avatarColor,
  totalCount,
}: SummaryProps) => (
  <Card sx={{ backgroundColor: color }}>
    <CardContent>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            color="white"
            gutterBottom
            variant="h4"
            sx={{ fontWeight: "900" }}
          >
            {label}
          </Typography>
          <Avatar
            sx={{
              backgroundColor: avatarColor,
              height: 56,
              width: 56,
            }}
          >
            <Summarize />
          </Avatar>
        </Stack>
        {loading === "pending" ? (
          <Skeleton variant="text" />
        ) : (
          <Stack sx={{ backgroundColor: avatarColor }}>
            <Typography color="white" variant="h3">
              {`${value.toLocaleString()}`}
            </Typography>
          </Stack>
        )}
      </Box>
    </CardContent>
  </Card>
);
