import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Employee } from "../../../features/process/types/processTypes";
import { format } from "date-fns";
import QRCode from "qrcode";
const qrCanvas = document.createElement("canvas");
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
  },
  rowNoBorder: {
    flexDirection: "row",
    alignItems: "center",
    height: 20,
  },
  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 40,
    marginTop: -6,
  },
  row21: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 20,
    marginTop: -6,
  },
  cellCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
  },
  cellCenterArabic: {
    textAlign: "center",
    fontFamily: "Noto",
    margin: 0,
    padding: 0,
  },
  cellLeft10: {
    width: "10%",
    textAlign: "left",
  },
  cellRight10: {
    width: "10%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellLeft5: {
    width: "5%",
    textAlign: "left",
  },
  cellRight5: {
    width: "7%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellLeft20: {
    width: "19%",
    textAlign: "left",
  },
  cellCenter5: {
    width: "5%",
    textAlign: "center",
  },
  cellCenter10: {
    width: "10%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellCenter15: {
    width: "15%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellCenter35: {
    width: "35%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellRight20: {
    width: "19%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellRight35: {
    width: "35%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellSpace2: {
    width: "2%",
  },
  cellSpace1: {
    width: "1%",
  },
  forOfficialUseOnly: {
    marginTop: 1,
    width: 700,
    height: 140,
  },
  qrCode: {
    height: "35px",
    display: "flex",
    marginTop: -10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
});
interface Props {
  employee: Employee;
}

const DependentsRow = ({ employee }: Props) => {
  const visa = employee.visa;
  const fullName = employee.givenName + " " + employee.surName;
  QRCode.toCanvas(qrCanvas, "https://www.enjazplus.com?lng=ar");
  const qr = qrCanvas.toDataURL("png");
  return (
    <>
      <View style={styles.rowNoBorder}>
        <Text
          style={{
            width: "50%",
            textAlign: "left",
            fontFamily: "Open Sans",
            fontWeight: "extrabold",
            fontSize: 13,
          }}
        >
          Dependents traveling in the same passport:
        </Text>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            marginTop: -4,
            fontWeight: "extrabold",
            fontSize: 13,
            fontFamily: "Noto",
          }}
        >
          :إيضاحات تخص أفراد العائلة على نفس جواز السفر
        </Text>
      </View>
      <View style={[styles.row2]}>
        <View style={styles.row21}>
          <Text style={styles.cellCenterArabic}>نوع الصلة</Text>
          <Text style={styles.cellCenter}>RelationShip</Text>
        </View>
        <View style={styles.row21}>
          <Text style={styles.cellCenterArabic}>تاريخ الميلاد</Text>
          <Text style={styles.cellCenter}>Date of Birth</Text>
        </View>
        <View style={styles.row21}>
          <Text style={styles.cellCenterArabic}>الجنس</Text>
          <Text style={styles.cellCenter}>Sex</Text>
        </View>
        <View style={[styles.row21, { marginRight: 0, borderRightWidth: 1.5 }]}>
          <Text style={styles.cellCenterArabic}>الاسم الكامل</Text>
          <Text style={styles.cellCenter}>Full Name</Text>
        </View>
      </View>
      <View style={styles.row3}>
        <View style={styles.row31} />
        <View style={styles.row31} />
        <View style={styles.row31} />
        <View
          style={[styles.row31, { marginRight: 0, borderRightWidth: 1.5 }]}
        />
      </View>
      <View style={styles.rowNoBorder}>
        <Text style={{ width: "50%", textAlign: "left" }}>
          Name and address of company or individual in Kingdom:
        </Text>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            marginTop: -4,
            fontFamily: "Noto",
          }}
        >
          :اسم وعنوان الشركة أو اسم الشخص و عنوانه بالمملكة
        </Text>
      </View>
      <View style={[styles.row, { marginTop: -6 }]}>
        <Text style={styles.cellLeft5}>Place:</Text>
        <Text style={[styles.cellCenter15, { fontFamily: "Noto" }]}>
          {visa?.address?.city}
        </Text>
        <Text style={styles.cellRight5}>:العنوان</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft5}>Tel:</Text>
        <Text style={styles.cellCenter15}>
          {visa?.address?.telephone?.toUpperCase()}
        </Text>
        <Text style={styles.cellRight5}>:التلفون</Text>
        <Text style={[styles.cellCenter35, { fontFamily: "Noto" }]}>
          {visa?.fullName}
        </Text>
      </View>
      <View style={[styles.rowNoBorder, { marginTop: -2 }]}>
        <Text
          style={{
            width: "60%",
            textAlign: "left",
            fontSize: 9,
            paddingRight: 2,
          }}
        >
          The undersigned thereby certify that all the information I have
          provided are correct.
        </Text>
        <Text
          style={{
            width: "40%",
            textAlign: "right",
            marginTop: -6,
            fontFamily: "Noto",
            fontSize: 9,
          }}
        >
          :أناالمواقع أدناء أقر بأن كل المعلومات التي دونتها صحيحة
        </Text>
      </View>
      <View style={[styles.rowNoBorder, { marginTop: -10 }]}>
        <Text
          style={{
            width: "60%",
            textAlign: "left",
            fontSize: 9,
            paddingRight: 2,
          }}
        >
          I will abide by the laws of the during the period of my residence in
          it.
        </Text>
        <Text
          style={{
            width: "40%",
            textAlign: "right",
            marginTop: -8,
            fontFamily: "Noto",
            fontSize: 9,
          }}
        >
          :وسأكون ملتزما بقوانين المملكة اثناء فترة وجودي به
        </Text>
      </View>
      <View style={[styles.row, { marginTop: -6 }]}>
        <Text style={styles.cellLeft5}>Date:</Text>
        <Text style={[styles.cellCenter15, { marginTop: -4 }]}>
          {format(new Date(), "dd-MMM-yyyy").toUpperCase()}
        </Text>
        <Text style={styles.cellRight5}>:التاريخ</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft10}>Signature:</Text>
        <Text style={styles.cellCenter10}></Text>
        <Text style={styles.cellRight5}>:التوقيع</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft5}>Name:</Text>
        <Text style={[styles.cellCenter35, { marginTop: -4 }]}>{fullName}</Text>
        <Text style={styles.cellRight5}>:الاسم</Text>
      </View>
      <View style={[styles.rowNoBorder, { marginTop: 5 }]}>
        <Text
          style={{
            width: "50%",
            textAlign: "left",
            fontFamily: "Open Sans",
            fontWeight: "extrabold",
            fontSize: 13,
            textDecoration: "underline",
          }}
        >
          For official use only:
        </Text>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            marginTop: -4,
            fontWeight: "extrabold",
            fontSize: 13,
            fontFamily: "Noto",
            textDecoration: "underline",
          }}
        >
          :للستمال الرسمي فقط
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft5}>Date:</Text>
        <Text style={styles.cellRight5}></Text>
        <Text style={styles.cellRight5}>:التاريخ</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft20}>Authorization:</Text>
        <Text style={styles.cellCenter35}>{visa?.visaNumber}</Text>
        <Text style={styles.cellRight35}>:رقم الامر المعتمد عليه في اعطاء</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft20}>Visit/Work for:</Text>
        <Text
          style={[
            styles.cellCenter35,
            { marginTop: -4, width: "50%", fontFamily: "Noto" },
          ]}
        >
          {visa?.fullName}
        </Text>
        <Text style={styles.cellRight35}>:لزيارة ‑ العمل لدي</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft20}>Fee Collected:</Text>
        <Text style={styles.cellRight5}></Text>
        <Text style={styles.cellRight20}>:المبلغ المحصل</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft5}>Type:</Text>
        <Text style={styles.cellRight5}></Text>
        <Text style={styles.cellRight5}>:نوعها</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft20}>Duration:</Text>
        <Text style={styles.cellCenter15}></Text>
        <Text style={styles.cellRight10}>:مدتها</Text>
      </View>
      <View style={[styles.rowNoBorder, { marginTop: 2 }]}>
        <Text
          style={{
            width: "50%",
            textAlign: "left",
            fontFamily: "Noto",
            fontWeight: "extrabold",
            fontSize: 12,
          }}
        >
          القنصلي رئيس القسم
        </Text>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            fontWeight: "extrabold",
            fontSize: 12,
            fontFamily: "Noto",
          }}
        >
          مدقق البيانات
        </Text>
      </View>
      <View style={[styles.rowNoBorder, { marginTop: -4 }]}>
        <Text
          style={{
            width: "50%",
            textAlign: "left",
            fontFamily: "Open Sans",
            fontWeight: "extrabold",
            fontSize: 12,
          }}
        >
          Head of Consular Section
        </Text>
        <Text
          style={{
            width: "50%",
            textAlign: "right",
            fontWeight: "extrabold",
            fontSize: 12,
            fontFamily: "Open Sans",
          }}
        >
          Checked by
        </Text>
      </View>

      <View style={styles.qrCode}>
        <Image source={qr} />
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Text>EnjazPlus</Text>
          <Text>+251-911-850-908</Text>
        </View>
      </View>
    </>
  );
};

export default DependentsRow;
