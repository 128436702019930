import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, Divider, Grid, useTheme, Badge } from "@mui/material";
import { AddressDetail } from "./AddressDetail";
import { ContactPerson } from "./ContactPerson";
import { Experience } from "./Experience";
import { FormikProps } from "formik";
import { Employee } from "../types/processTypes";
import { useTranslation } from "react-i18next";
import { EmployeeMore } from "./EmployeeMore";
import { StyledTab } from "../../../styles/componentStyled";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import {
  FileCopy,
  LocationOn,
  NextPlan,
  Save,
  Woman2,
  WorkHistory,
} from "@mui/icons-material";
import { VisaBrief } from "./VisaBrief";
import { ProcessStages } from "./ProcessStages";

interface EmployeeTabProps {
  formikData: FormikProps<Employee>;
  printPdf?: any;
  printVisibility?: string;
}
// const SubEmployeeTabs = ({ formikData }: EmployeeTabProps) => {
//   const theme = useTheme();
//   const { t } = useTranslation();
//   const [tabValue, setValue] = useState("0");

//   const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
//     setValue(newValue);
//   };
//   return (
//     <div>
//       <TabContext value={tabValue}>
//         <Box
//           sx={{
//             my: 1,
//             backgroundColor: theme.palette.primary.main,
//             borderRadius: "4px",
//           }}
//         >
//           <TabList
//             onChange={handleTabChange}
//             variant="scrollable"
//             scrollButtons
//             allowScrollButtonsMobile
//             aria-label="lab API tabs example"
//             TabScrollButtonProps={{ color: "white" }}
//           >
//             <StyledTab
//               label="Address & Contact Person"
//               value="0"
//               wrapped
//               sx={{ width: 150 }}
//             />

//             <StyledTab
//               label={"Skills & " + t("workExperience")}
//               value="1"
//               sx={{ width: 150 }}
//               wrapped
//             />
//             <StyledTab label="CV" value="2" sx={{ width: 150 }} />
//           </TabList>
//         </Box>

//         <TabPanel sx={{ py: 0, px: 1 }} value="0">
//           <Grid container spacing={1} xs={12}>
//             <Grid item md={6} xs={12}>
//               <Accordion expanded>
//                 <StyledAccordionSummary
//                   expandIcon={<ExpandMore />}
//                   aria-controls="panel1a-content"
//                   id="panel1a-header"
//                 >
//                   <Typography variant="h5" component="div">
//                     Employee Address
//                   </Typography>
//                 </StyledAccordionSummary>
//                 <AccordionDetails>
//                   <AddressDetail />
//                 </AccordionDetails>
//               </Accordion>
//             </Grid>

//             <Grid item md={6} xs={12}>
//               <Accordion expanded>
//                 <StyledAccordionSummary
//                   expandIcon={<ExpandMore />}
//                   aria-controls="panel1a-content"
//                   id="panel1a-header"
//                 >
//                   <Typography variant="h5" component="div">
//                     Contact Person
//                   </Typography>
//                 </StyledAccordionSummary>
//                 <AccordionDetails>
//                   <ContactPerson />
//                 </AccordionDetails>
//               </Accordion>
//             </Grid>
//           </Grid>
//         </TabPanel>
//         <TabPanel sx={{ py: 0, px: 1 }} value="1">
//           <Experience formikData={formikData} />
//         </TabPanel>
//         <TabPanel sx={{ py: 0, px: 1 }} value="2">
//           <EmployeeMore />
//         </TabPanel>
//       </TabContext>
//     </div>
//   );
// };

export const EmployeeTabs = ({
  formikData,
  printPdf,
  printVisibility,
}: EmployeeTabProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  const [tabValue, setValue] = useState("0");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div>
      <TabContext value={tabValue}>
        <Box
          sx={{
            my: 1,
            backgroundImage: `linear-gradient(.25turn, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
            //backgroundColor: theme.palette.secondary.main,
            borderRadius: "4px",
          }}
        >
          <TabList
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="lab API tabs example"
            TabScrollButtonProps={{ color: "white" }}
          >
            {/* <StyledTab icon={<FileCopy />} label="Visa Info" value="0" /> */}

            <StyledTab
              icon={<FileCopy />}
              label="Visa Process"
              value="0"
              sx={{ width: 150 }}
            />
            <StyledTab
              icon={<LocationOn />}
              label="Address & Others..."
              value="1"
              wrapped
              sx={{ width: 150 }}
            />

            <StyledTab
              icon={<WorkHistory />}
              label={"Skills & Experience"}
              value="2"
              sx={{ width: 150 }}
              wrapped
            />
            <StyledTab
              icon={<Woman2 />}
              label="CV"
              value="3"
              sx={{ width: 150 }}
            />
            <StyledTab
              icon={<NextPlan />}
              label="Current Status"
              value="4"
              sx={{ width: 150 }}
            />
          </TabList>
        </Box>
        {/* <TabPanel sx={{ py: 0, px: 1 }} value="0">
          <VisaBrief />
        </TabPanel> icon={<MoreHoriz />}*/}
        <TabPanel sx={{ py: 0, px: 1 }} value="0">
          <VisaBrief
            formikData={formikData}
            printPdf={printPdf}
            printVisibility={printVisibility}
          />
        </TabPanel>

        <TabPanel sx={{ py: 0, px: 1 }} value="1">
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
              <FormikTextField
                formikKey="documents"
                select
                label="Completed Documents"
                SelectProps={{
                  multiple: true,
                  displayEmpty: true,
                  defaultValue: [],
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: 250,
                      },
                    },

                    variant: "menu",
                  },
                }}
              >
                {Object.keys(RequiredDocuments).map((option) => (
                  <MenuItem
                    key={option}
                    value={option as string}
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: theme.palette.action.selected,
                      },
                      "&.Mui-selected:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    {te(option)}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid> */}
            <Grid item container md={6} xs={12} spacing={1}>
              <Grid item xs={12}>
                <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
                  Employee Address
                </Divider>
              </Grid>
              <AddressDetail />
              <Grid item xs={12}>
                <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
                  More Data (Optional)
                </Divider>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormikTextField
                  formikKey="additionalData.fullNameAmharic"
                  label="የሠራተኛ ሙሉ ስም (በአማርኛ)"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormikTextField
                  formikKey="additionalData.lmisId"
                  label="Biometrics ID"
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormikTextField
                  formikKey="additionalData.labourId"
                  label="Labour ID"
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <FormikTextField
                  formikKey="visa.fullNameAmharic"
                  label="የአሰሪው ሙሉ ስም (በአማርኛ)"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormikTextField
                  formikKey="visa.cityAmharic"
                  label="የአሰሪው ከተማ (በአማርኛ)"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <FormikTextField
                  formikKey="visa.contractNumber"
                  label="Musaned Contract No."
                />
              </Grid>
            </Grid>
            <Grid
              item
              md={1}
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Divider orientation="vertical"></Divider>
            </Grid>
            <Grid item md={5} xs={12}>
              <Divider variant="fullWidth" textAlign="left" sx={{ mt: 1 }}>
                Contact Person
              </Divider>
              <ContactPerson />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!formikData.isValid}
                sx={{ mt: 1, width: "50%" }}
              >
                <Save />
                {t("save") + " " + t("address") + " & others..."}
              </Button>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel sx={{ py: 0, px: 1 }} value="2">
          <Experience formikData={formikData} />
        </TabPanel>
        <TabPanel sx={{ py: 0, px: 1 }} value="3">
          <EmployeeMore formikData={formikData} />
        </TabPanel>
        <TabPanel sx={{ py: 0, px: 1 }} value="4">
          <ProcessStages formikData={formikData} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
