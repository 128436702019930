import { useEffect, ChangeEvent, useState } from "react";
import * as XLSX from "xlsx";
import { Form, FormikProps, Formik } from "formik";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid, LinearProgress } from "@mui/material";
import Save from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { registerSchema } from "../../features/setups/validation";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import Toast from "../../components/Layout/Toast";
import {
  addCompany,
  getCompany,
  selectSetups,
} from "../../features/setups/setupSlices";
import { Company } from "../../features/setups/types/setupTypes";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { selectAuth } from "../../features/auth/authSlice";
import { AddressDetail } from "../../features/process/components/AddressDetail";
import { importFromXl } from "../../utils/backupUtils";
import { forEach } from "lodash";
import {
  createEmployee,
  selectProcesses,
} from "../../features/process/processSlices";
import { addYears } from "date-fns";
import {
  EmployeeStatus,
  ReligionTypes,
} from "../../features/process/types/processTypes";

export const CompanyDetail = () => {
  const { user } = useAppSelector(selectAuth);

  const {
    loading,
    error,
    success,
    selectedCompany: companyProfile,
  } = useAppSelector(selectSetups);

  const dispatch = useAppDispatch();
  // const { selectedEmployee, selectedVisa } = useAppSelector(selectProcesses);
  // const [data, setData] = useState<unknown[]>([]);

  useEffect(() => {
    if (user && user.company && user.company._id) {
      const companyId = user.company?._id as string;
      if (companyId && companyId.length > 0) {
        dispatch(getCompany(companyId as string));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //useEffect(() => {
  // if (data && data.length > 10) {
  //   let i = 14;
  //   while (i > 0) {
  //     const e = data[i];
  //     i--;
  //     let currentStatus = e.status;
  //     let currentEmployee = selectedEmployee;
  //     let addData = currentEmployee.additionalData;
  //     addData = {
  //       ...addData,
  //       religion:
  //         e.religion === "Islam"
  //           ? ReligionTypes.muslim
  //           : ReligionTypes.christian,
  //     };
  //     let embassyData = currentEmployee.embassyProcess;
  //     embassyData = {
  //       ...embassyData,
  //       enjazNumber: e.applicationNum,
  //     };
  //     currentEmployee = {
  //       ...currentEmployee,
  //       passportNumber: e.passportNum,
  //       givenName: e.fullName.split(" ")[0] + " " + e.fullName.split(" ")[1],
  //       surName: e.fullName.split(" ")[2],
  //       dateOfBirth: addYears(new Date(), -parseInt(e.age)),
  //       placeOfBirth: "Addis Ababa",
  //       owner: user?.company,
  //       prospects: user?.company?.partners
  //         ? [user?.company?.partners[0]]
  //         : ([user?.company] as [Company]),
  //       status:
  //         currentStatus === "AVAILABLE"
  //           ? EmployeeStatus.new
  //           : currentStatus === "SELECTED"
  //           ? EmployeeStatus.selected
  //           : currentStatus === "TRAVELED"
  //           ? EmployeeStatus.traveled
  //           : currentStatus === "RUNAWAY"
  //           ? EmployeeStatus.lost
  //           : EmployeeStatus.discontinued,
  //       additionalData: addData,
  //       embassyProcess: embassyData,
  //     };
  //     if (e.sponsorName && e.sponsorName.length > 0) {
  //       let visa = selectedVisa;
  //       visa = {
  //         ...visa,
  //         fullName: e.sponsorName,
  //         passportNumber: e.sponsorId,
  //         visaNumber: e.visaNumber,
  //       };
  //       currentEmployee = {
  //         ...currentEmployee,
  //         visa,
  //       };
  //     }
  //     //console.log(currentEmployee);
  //     dispatch(createEmployee(currentEmployee));
  //   }
  // }
  //}, [data]);

  // const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
  //   const { validity, files } = event.target;

  //   if (validity.valid && files && files[0]) {
  //     const reader = new FileReader();

  //     reader.onload = (event) => {
  //       if (event.target) {
  //         const workbook = XLSX.read(event.target.result, { type: "binary" });
  //         const sheetName = workbook.SheetNames[0];
  //         const sheet = workbook.Sheets[sheetName];
  //         const sheetData = XLSX.utils.sheet_to_json(sheet) as unknown[];

  //         setData(sheetData);
  //       }
  //     };
  //     reader.readAsArrayBuffer(files[0]);
  //   }
  // };

  return (
    <Box>
      {/*
      <label htmlFor="icon-button-file">Import From Excel</label>
      <input
        name="image"
        accept="xls/*"
        onChange={onChange}
        style={{ display: "block" }}
        id="icon-button-file"
        type="file"
  />*/}
      <Formik
        enableReinitialize={true}
        initialValues={companyProfile as Company}
        validationSchema={registerSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          dispatch(addCompany(values));
        }}
      >
        {(props: FormikProps<Company>) => (
          <Form>
            <Accordion sx={{ m: 1 }} expanded={true}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Company Profile</Typography>
              </StyledAccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="displayName"
                      disabled
                      label="Name"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormikTextField formikKey="email" disabled label="Email" />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="nameAmharic"
                      label="የድርጅት ስም (በአማርኛ)"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormikTextField
                      formikKey="managerNameAmharic"
                      label="የስራ አስኪያጅ ስም (በአማርኛ)"
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormikTextField
                      formikKey="licenseNumber"
                      label="License Number"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}></Grid>
                </Grid>
                <AddressDetail />

                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  fullWidth
                  disabled={!props.isValid}
                  sx={{ mt: 1 }}
                >
                  <Save /> Save Company Profile
                </Button>
              </AccordionDetails>
            </Accordion>
            <br />
            {loading === "pending" && <LinearProgress color="secondary" />}
            {success && <Toast severity="success">{success.message}</Toast>}
            {error && <Toast severity="error">{error.message}</Toast>}
          </Form>
        )}
      </Formik>
      <Box m={1}>
        {error && <Toast severity="error">{error.message}</Toast>}
      </Box>
    </Box>
  );
};
