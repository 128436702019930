import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import {
  ProfessionalTypes,
  ReligionTypes,
  AgeCategory,
  Complexion,
  Visa,
  CurrencyTypes,
} from "../types/processTypes";

interface VisaTabProps {
  formikData: FormikProps<Visa>;
}

export const VisaCondition = ({ formikData }: VisaTabProps) => {
  const { t } = useTranslation();
  const { t: te } = useTranslation("enums");
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="profession"
            select
            label={te("profession")}
          >
            {Object.keys(ProfessionalTypes).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormikTextField formikKey="religion" select label={te("religion")}>
            {Object.keys(ReligionTypes).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="ageCategory"
            select
            label={te("ageCategory")}
          >
            {Object.keys(AgeCategory).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikTextField
            formikKey="complexion"
            select
            label={te("complexion")}
          >
            {Object.keys(Complexion).map((option) => (
              <MenuItem key={option} value={option as string}>
                {te(option)}
              </MenuItem>
            ))}
          </FormikTextField>
        </Grid>
        <Grid item md={4} xs={12}>
          <Stack direction="row">
            <FormikTextField
              formikKey="salary"
              label={t("salary")}
              type="number"
              sx={{ mr: 0.3, mt: 1 }}
            />
            <FormikTextField formikKey="currency" select label={te("currency")}>
              {Object.keys(CurrencyTypes).map((option) => (
                <MenuItem key={option} value={option as string}>
                  {te(option)}
                </MenuItem>
              ))}
            </FormikTextField>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <FormikTextField formikKey="remarks" label="Remarks" />
        </Grid>
      </Grid>
      <Stack direction="row" spacing={1}>
        <FormControlLabel
          control={
            <Checkbox checked={formikData.values.firstTime as boolean} />
          }
          label="First Timer"
          name="firstTime"
          onChange={formikData.handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox checked={formikData.values.goodLooking as boolean} />
          }
          label="Good Looking"
          name="goodLooking"
          onChange={formikData.handleChange}
        />
        <FormControlLabel
          control={
            <Checkbox checked={formikData.values.writeRead as boolean} />
          }
          label="Reading and Writing"
          name="writeRead"
          onChange={formikData.handleChange}
        />
      </Stack>
    </div>
  );
};
