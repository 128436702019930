import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { Employee, Visa } from "../../../features/process/types/processTypes";
import JsBarcode from "jsbarcode";
import { AuthUser } from "../../../features/auth/types/authType";

interface Props {
  employee: Employee;
  user: AuthUser;
}
const visaCanvas = document.createElement("canvas");
const enjazCanvas = document.createElement("canvas");
const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 15,
    paddingLeft: 2,
    paddingRight: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  leftColumnContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
    fontFamily: "Open Sans",
    marginLeft: "4px",
  },
  centerColumnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 50,
  },
  rightColumnContainer: {
    width: "33%",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: 110,
    height: 18,
  },
  sponsorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: -2,
    marginLeft: 4,
  },
  label: {
    width: 45,
  },
  text: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  photo: {
    width: 95,
    height: 100,
    marginLeft: 4,
  },
  mofaLogo: {
    width: 70,
    height: 80,
    marginLeft: "auto",
    marginRight: "auto",
  },
  consularImage: {
    width: 150,
    height: 60,
  },
});
export const BarcodeHeader = ({ employee, user }: Props) => {
  JsBarcode(visaCanvas, employee.visa?.visaNumber as string, {
    format: "code128a",
    width: 1,
    height: 20,
    displayValue: false,
    margin: 0,
    background: "#ffffff",
    lineColor: "#000000",
  });
  JsBarcode(enjazCanvas, employee.embassyProcess?.enjazNumber as string, {
    format: "code128a",
    width: 1,
    height: 20,
    displayValue: false,
    margin: 0,
    background: "#ffffff",
    lineColor: "#000000",
  });
  const visaNoBarcode = visaCanvas.toDataURL();
  const enjazNoBarcode = enjazCanvas.toDataURL();
  const face = employee.facePhoto as string;
  const visa = employee.visa as Visa;
  //const embassy = employee.embassy;
  // const [faceImage, setFaceImage] = useState("./enjazPLogo.png");
  // useEffect(() => {
  //   if (face.length > 1) {
  //     console.log(face);
  //     fetch(face, { mode: "no-cors" })
  //       .then(function (response) {
  //         return response.blob();
  //       })
  //       .then(function (blob) {
  //         // here the image is a blob
  //         setFaceImage(URL.createObjectURL(blob));
  //       });
  //   }
  // }, [employee]);
  return (
    <View style={styles.headerContainer}>
      <View style={styles.leftColumnContainer}>
        <Image
          style={[styles.image, { marginLeft: 4 }]}
          source={visaNoBarcode}
        />
        <View style={styles.sponsorContainer}>
          <Text style={[styles.label, { fontSize: 10 }]}>Visa No.: </Text>
          <Text style={[styles.text, { fontSize: 10 }]}>{visa.visaNumber}</Text>
        </View>
        <View style={styles.sponsorContainer}>
          <Text style={[styles.label, { fontSize: 10 }]}>Sponsor: </Text>
          <Text style={[styles.text, { fontSize: 10, fontFamily: "Noto" }]}>
            {visa?.fullName}
          </Text>
        </View>
        <Image style={styles.photo} source={face} />
      </View>
      <View style={styles.centerColumnContainer}>
        <Image style={styles.mofaLogo} source="/mofaLogo.jpg" />
      </View>
      <View style={styles.rightColumnContainer}>
        <Image style={styles.image} source={enjazNoBarcode} />
        <Text style={[styles.text, { fontSize: 10, marginTop: -2 }]}>
          {employee.embassyProcess?.enjazNumber}
        </Text>
        <Text
          style={{
            fontFamily: "Noto",
            fontSize: 8,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 5,
          }}
          wrap
        >
          سفارة المملكة العربية السعودية
        </Text>
        <Text
          style={{
            fontFamily: "Noto",
            fontSize: 8,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: -1,
          }}
          wrap
        >
          القسم القنصلي
        </Text>
        <Text
          style={{
            fontFamily: "Open Sans",
            fontSize: 8,
            fontWeight: "bold",
            textAlign: "center",
          }}
          wrap
        >
          EMBASSY OF SAUDI ARABIA
        </Text>
        <Text
          style={{
            fontFamily: "Open Sans",
            fontSize: 8,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: -1,
          }}
          wrap
        >
          CONSULAR SECTION
        </Text>
        <Text
          style={{
            fontFamily: "Open Sans",
            fontSize: 10,
            fontWeight: "bold",
            textAlign: "center",
            marginTop: 8,
          }}
          wrap
        >
          {user?.company?.displayName.toUpperCase()}
        </Text>
      </View>
    </View>
  );
};
