import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import Image from "mui-image";
import { Box } from "@mui/material";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicStand = ({ currentEmployee, template }: Props) => {
  return (
    <Box sx={{ boxShadow: template.photoShadow }}>
      <Image
        src={
          currentEmployee && currentEmployee.standPhoto
            ? (currentEmployee.standPhoto as string)
            : "/blankLong.jpg"
        }
        alt={currentEmployee.passportNumber}
        width={template.standWidth}
        height={template.standHeight}
        shift="top"
        distance="2rem"
        shiftDuration={320}
        style={{
          objectFit: "fill",
          borderRadius: template.borderRadius,
          marginTop: template.spacing,
          borderColor: template.borderColor,
          borderWidth: (template.borderWidth as number) * 3,
          borderStyle: template.borderStyle,
        }}
      />
    </Box>
  );
};
