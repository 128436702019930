import { ChangeEvent, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { deleteFile } from "../../auth/authSlice";
import Delete from "@mui/icons-material/Delete";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Box from "@mui/material/Box";
import Toast from "../../../components/Layout/Toast";
import { PhotoTypes } from "../types/processTypes";
import { selectProcesses, uploadPicture } from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import { resizeFile } from "../../../utils/imageUtility";
import { Photo } from "@mui/icons-material";

function FacePhoto() {
  const [image, setImage] = useState("");

  const {
    selectedEmployee,
    success,
    loading: loadingFacePhoto,
    error,
  } = useAppSelector(selectProcesses);
  const dispatch = useAppDispatch();
  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;

    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 300, 300)) as File;
      if (image) {
        dispatch(
          uploadPicture({
            _id: selectedEmployee!._id as string,
            type: PhotoTypes.Face,
            avatar: image,
          })
        );
      }
    }
  };

  const removeImage = () => {
    setImage("");
    //let prof = selectedEmployee;
    //prof = { ...prof, avatar: "" } as Employee;
    //dispatch(updateProfile(prof));
    dispatch(deleteFile(selectedEmployee?.facePhoto as string));
  };

  useEffect(() => {
    if (loadingFacePhoto === "pending") {
      document.body.style.opacity = "0.5";
    } else {
      document.body.style.opacity = "1";
    }
  }, [loadingFacePhoto]);

  return (
    <>
      <Paper
        elevation={0}
        style={{
          position: "relative",
          borderRadius: 18,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          name="image"
          accept="image/*"
          onChange={onChange}
          style={{ display: "none" }}
          id="icon-button-file"
          type="file"
        />
        <label htmlFor="icon-button-file">
          <>
            <Badge
              overlap="rectangular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              badgeContent={
                <Tooltip title="Add/Change Photo" aria-label="icon-button-file">
                  <Fab component="span" color="primary">
                    <IconButton
                      aria-label="icon-button-file"
                      component="span"
                      size="large"
                      color="inherit"
                    >
                      <Photo />
                    </IconButton>
                  </Fab>
                </Tooltip>
              }
            >
              <Avatar
                alt="avatar"
                src={
                  image
                    ? image
                    : selectedEmployee
                    ? selectedEmployee.facePhoto
                    : ""
                }
                sx={{
                  height: 180,
                  width: 180,
                  // marginTop: -3,
                }}
              />
              {/* {loadingFacePhoto === "pending" ? (
                <AuthSkeleton />
              ) : (
              )} */}
            </Badge>
          </>
        </label>{" "}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 0,
          }}
        >
          {(image || (selectedEmployee && selectedEmployee.facePhoto)) && (
            <>
              <Box component="div">
                <Tooltip title="Remove Photo" aria-label="icon-button-file">
                  <Fab component="span" color="primary">
                    <IconButton
                      color="inherit"
                      edge="start"
                      onClick={removeImage}
                      size="large"
                      sx={{
                        margin: "auto",
                        width: "auto",
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Fab>
                </Tooltip>
              </Box>
            </>
          )}
        </div>
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
          {success && <Toast severity="success">{success.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
}

export default FacePhoto;
