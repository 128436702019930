import { ar } from "./ar";
import { en } from "./en";

// interface TranslationWords {
//   key: string;
//   value: string;
// }
//const allWords = (lng: string): TranslationWords[] => {
// let result = Object.entries(ar).map(([key, value]) => ({ key, value }));
// if (lng === "en")
//   result = Object.entries(en).map(([key, value]) => ({ key, value }));
// return result;
//};
export const tt = (word: string, lng: string) => {
  //const words = allWords(lng);
  let val;
  if (lng === "ar") val = ar.find((w) => w.key === word)?.value;
  else if (lng === "en") val = en.find((w) => w.key === word)?.value;

  if (!val) return "";
  return val;
};
