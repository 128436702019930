import React from "react";
import { TablePagination, useTheme } from "@mui/material";
import { LabelTemplate } from "../../features/process/premiumCvs/styles/table";

interface Props {
  total: number;
  rowsPerPage: number;
  currentPage: number;
  setRowsPerPage: any;
  setCurrentPage: any;
}

function Paging({
  total,
  rowsPerPage,
  currentPage,
  setRowsPerPage,
  setCurrentPage,
}: Props) {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rows = parseInt(event.target.value);
    setCurrentPage(0);
    setRowsPerPage(rows);
  };
  const theme = useTheme();
  return (
    <TablePagination
      component="div"
      count={total}
      onPageChange={handlePageChange}
      page={currentPage}
      rowsPerPage={rowsPerPage}
      showFirstButton
      showLastButton
      rowsPerPageOptions={[
        2,
        5,
        10,
        25,
        50,
        100,
        { value: total, label: "All" },
      ]}
      onRowsPerPageChange={handleRowsPerPageChange}
      sx={{
        backgroundColor: theme.palette.primary.main,
        height: "70px",
        ".MuiTablePagination-toolbar": {
          backgroundColor: theme.palette.secondary.main,
          width: "auto",
          color: theme.palette.text.primary,
          height: "35px",
        },
        ".MuiButtonBase-root": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
          ":hover": {
            backgroundColor: theme.palette.action.selected,
          },

          marginRight: "3px",
          "& .MuiSvgIcon-root": {
            color: theme.palette.text.primary,
          },
        },
        ".MuiButtonBase-root.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.text.primary,
        },
      }}
    ></TablePagination>
  );
}

interface PropsForCV {
  total: number;
  rowsPerPage: number;
  currentPage: number;
  setRowsPerPage: any;
  setCurrentPage: any;
  template: LabelTemplate;
}
export const PagingForCV = ({
  total,
  rowsPerPage,
  currentPage,
  setRowsPerPage,
  setCurrentPage,
  template,
}: PropsForCV) => {
  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    setCurrentPage(page);
  };
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const rows = parseInt(event.target.value);
    setCurrentPage(0);
    setRowsPerPage(rows);
  };
  const theme = useTheme();
  return (
    <TablePagination
      component="div"
      count={total}
      onPageChange={handlePageChange}
      page={currentPage}
      rowsPerPage={rowsPerPage}
      showFirstButton
      showLastButton
      rowsPerPageOptions={[
        2,
        5,
        10,
        25,
        50,
        100,
        { value: total, label: "All" },
      ]}
      onRowsPerPageChange={handleRowsPerPageChange}
      sx={{
        backgroundColor: template.bgColor,
        height: "70px",
        fontSize: "20px",
        ".MuiTablePagination-toolbar": {
          backgroundColor: template.labelBgColor,
          width: "auto",
          color: template.labelFontColor,
          height: "35px",
        },
        ".MuiTablePagination-selectLabel": {
          fontSize: template.labelFontSize,
          fontWeight: template.labelFontWeight,
        },
        ".MuiTablePagination-displayedRows": {
          fontSize: template.labelFontSize,
          fontWeight: template.labelFontWeight,
        },
        ".MuiButtonBase-root": {
          backgroundColor: template.bgColor,
          color: template.labelFontColor,
          ":hover": {
            backgroundColor: template.valueBgColor,
          },

          marginRight: "3px",
          "& .MuiSvgIcon-root": {
            color: template.labelFontColor,
          },
        },
        ".MuiButtonBase-root.Mui-disabled": {
          backgroundColor: template.bgColor,
          color: template.headFontColor,
        },
      }}
    ></TablePagination>
  );
};

/**
 * sx={{
        backgroundColor: "red !important", // gets overridden if not important
        height: "70px",
        ".MuiInputBase-root": {
          backgroundColor: "green",
        },
        ".MuiTablePagination-toolbar": {
          backgroundColor: "pink",
          width: "auto",
          color: "rgb(41, 39, 39)",
          height: "35px",
        },
        ".MuiBox-root": {
          backgroundColor: "yellow",
          color: "black",

          "& .MuiSvgIcon-root": {
            backgroundColor: "purple",
            color: "black",
          },
        },
      }}
      SelectProps={{
        MenuProps: {
          sx: {
            ".MuiPaper-root": {
              backgroundColor: "rosybrown",
              color: "black",
            },
            ".MuiTablePagination-menuItem": {
              ":hover": {
                backgroundColor: "turquoise",
              },
              backgroundColor: "yellow",
            },
            ".MuiTablePagination-menuItem.Mui-selected": {
              ":hover": {
                backgroundColor: "blue",
              },
              backgroundColor: "purple",
            },
          },
        },
      }}
 */
export default Paging;
