import {
  TableContainer,
  Paper,
  Table,
  TableBody,
  Stack,
  TableHead,
} from "@mui/material";
import {
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import Image from "mui-image";
import { Employee } from "../../../types/processTypes";

import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  showComplexion?: boolean | false;
  showReligion?: boolean | false;
}
export const BasicHeightWeightHorz = ({
  currentEmployee,
  template,
  showComplexion,
  showReligion,
}: Props) => {
  return (
    <StyledTableContainer template={template}>
      <Table size="small" aria-label="simple table">
        <TableBody>
          <StyledTableRowTemplate template={template} key="7">
            {showComplexion && (
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt("complexion", template.cvLang)}
              </StyledTableCellLabelTemplate>
            )}
            {showReligion && (
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt("religion", template.cvLang)}
              </StyledTableCellLabelTemplate>
            )}
            <StyledTableCellLabelTemplate
              template={template}
              sx={{
                textAlign: "center",
              }}
            >
              {tt("height", template.cvLang)}
            </StyledTableCellLabelTemplate>
            <StyledTableCellLabelTemplate
              template={template}
              sx={{
                textAlign: "center",
              }}
            >
              {tt("weight", template.cvLang)}
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>

          <StyledTableRowTemplate template={template} key="77">
            {showComplexion && (
              <StyledTableCellValueTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt(
                  currentEmployee.additionalData?.complexion as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
            )}
            {showReligion && (
              <StyledTableCellValueTemplate
                template={template}
                sx={{
                  textAlign: "center",
                }}
              >
                {tt(
                  currentEmployee.additionalData?.religion as string,
                  template.cvLang
                )}
              </StyledTableCellValueTemplate>
            )}
            <StyledTableCellValueTemplate
              template={template}
              sx={{
                textAlign: "center",
              }}
            >
              {currentEmployee.additionalData?.height === 0
                ? "-"
                : currentEmployee.additionalData?.height?.toLocaleString(
                    template.cvLang === "ar" ? "ar-SA" : "en-US"
                  ) + tt("meter", template.cvLang)}
            </StyledTableCellValueTemplate>

            <StyledTableCellValueTemplate
              template={template}
              sx={{
                textAlign: "center",
              }}
            >
              {currentEmployee.additionalData?.weight === 0
                ? "-"
                : currentEmployee.additionalData?.weight?.toLocaleString(
                    template.cvLang === "ar" ? "ar-SA" : "en-US"
                  ) + tt("kg", template.cvLang)}
            </StyledTableCellValueTemplate>
          </StyledTableRowTemplate>
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};
