import { Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  AdditionalData,
  Employee,
  Sex,
} from "../../../features/process/types/processTypes";
import { format } from "date-fns";
import DependentsRow from "./DependentsRow";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 20,
  },
  purposeOfTravel: {
    flexDirection: "column",
    alignItems: "center",
    height: "30px",
    width: "65px",
    border: "1px solid black",
  },

  cellLeft15: {
    width: "15%",
    textAlign: "left",
    fontFamily: "Open Sans",
  },
  cellCenter15: {
    width: "15%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellCenter10: {
    width: "10%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellLeft35: {
    width: "35%",
    textAlign: "left",
    fontFamily: "Open Sans",
  },
  cellCenter35: {
    width: "35%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellRight35: {
    width: "35%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellLeft20: {
    width: "19%",
    textAlign: "left",
  },
  cellCenter20: {
    width: "19%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  cellCenter5: {
    width: "5%",
    textAlign: "center",
  },
  cellRight20: {
    width: "19%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellSpace2: {
    width: "2%",
  },
  cellSpace1: {
    width: "1%",
  },
  cellRight15: {
    width: "15%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
  cellLeft10: {
    width: "10%",
    textAlign: "left",
    fontFamily: "Open Sans",
  },
  cellRight10: {
    width: "10%",
    textAlign: "right",
    fontFamily: "Noto",
    marginTop: -4,
  },
});
interface Props {
  employee: Employee;
}

const ListRow = ({ employee }: Props) => {
  const edu = employee.education?.educationLevel as string;
  const fullName = employee.givenName + " " + employee.surName;
  const agency = employee.owner;
  const cv = employee.additionalData as AdditionalData;
  const getEducationLevelCaps = (education: string) => {
    let educationCap = "";
    switch (education) {
      case "grade_eight_complete":
        educationCap = "Grade 8 Complete";
        break;
      case "secondary_complete":
        educationCap = "Secondary";
        break;
      case "vocational_complete":
        educationCap = "Vocational";
        break;
      case "college_complete":
        educationCap = "College";
        break;
      case "post_graduate":
        educationCap = "Post Graduate";
        break;
      case "other_education":
        educationCap = "Other Education";
        break;
    }
    return educationCap;
  };
  return (
    <>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Full Name:</Text>
        <Text
          style={[
            styles.cellLeft35,
            {
              fontWeight: "bold",
              fontSize: 12,
              width: "55%",
            },
          ]}
        >
          {fullName}
        </Text>
        <Text style={styles.cellRight15}></Text>
        <Text style={styles.cellRight15}>:الاسم الكامل</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Mother's Name:</Text>
        <Text style={styles.cellLeft35}></Text>
        <Text style={styles.cellRight35}></Text>
        <Text style={styles.cellRight15}>:إسم الأم</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Date of birth:</Text>
        <Text style={styles.cellCenter20}>
          {format(
            new Date(employee.dateOfBirth as Date),
            "dd-MMM-yyyy"
          ).toUpperCase()}
        </Text>
        <Text style={styles.cellRight15}>:تاريخ الولادة</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft15}>Place of birth:</Text>
        <Text style={styles.cellCenter20}>{employee.placeOfBirth}</Text>
        <Text style={styles.cellRight15}>:محل الولادة</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Past Nationality:</Text>
        <Text style={styles.cellCenter20}>
          {cv.pastNationality?.toUpperCase()}
        </Text>

        <Text style={styles.cellRight15}>:الجنسية السابقة</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft20}>Current Nationality:</Text>
        <Text style={styles.cellCenter15}>{cv.nationality?.toUpperCase()}</Text>
        <Text style={styles.cellRight15}>:الجنسية الحالي</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Sex:</Text>
        <Text style={[styles.cellLeft10, { fontWeight: "bold" }]}>
          {employee.sex?.toUpperCase()}
        </Text>
        <Text style={[styles.cellRight15, { width: "9%", fontWeight: "bold" }]}>
          {employee.sex === Sex.female ? "أنثى" : "ذكر"}
        </Text>
        <Text style={styles.cellRight15}>:الجنس</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft15}>Marital Status:</Text>
        <Text style={styles.cellCenter20}>
          {cv?.maritalStatus?.toUpperCase()}
        </Text>
        <Text style={styles.cellRight15}>:الحالة الإجتماعية</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Sect:</Text>
        <Text style={styles.cellCenter20}></Text>
        <Text style={styles.cellRight15}>:المذهب</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft15}>Religion:</Text>
        <Text style={styles.cellCenter20}>{cv.religion?.toUpperCase()}</Text>
        <Text style={styles.cellRight15}>:الديانة</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Qualification:</Text>
        <Text style={styles.cellCenter20}>
          {getEducationLevelCaps(edu).toUpperCase()}
        </Text>
        <Text style={styles.cellRight15}>:لمؤهل العلمي</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft15}>Profession:</Text>
        <Text style={styles.cellCenter20}>
          {cv.appliedProfession?.toUpperCase().replaceAll("_", " ")}
        </Text>
        <Text style={styles.cellRight15}>:لمهنة</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft35}>Home address and telephone No:</Text>
        <Text style={styles.cellCenter10}>{employee.address?.telephone}</Text>
        <Text style={styles.cellCenter20}></Text>
        <Text style={styles.cellCenter5}></Text>
        <Text style={styles.cellRight35}>:عنوان المنزل ورقم التلفون</Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cellLeft35, { width: "40%" }]}>
          Business address and telephone No:
        </Text>
        <Text style={styles.cellCenter10}>{agency?.address?.telephone}</Text>
        <Text style={styles.cellCenter20}></Text>
        <Text style={styles.cellRight35}>:عنوان الشركة ورقم التلفون</Text>
      </View>
      <View style={[styles.row, { height: 33 }]}>
        <Text style={styles.cellLeft20}>Purpose of travel:</Text>
        <View
          style={[
            styles.purposeOfTravel,
            { backgroundColor: "#5A5A5A", color: "white" },
          ]}
        >
          <Text style={{ fontFamily: "Noto", fontWeight: 900 }}>عمل</Text>
          <Text style={{ fontFamily: "Open Sans", fontWeight: 900 }}>Work</Text>
        </View>
        <View style={styles.purposeOfTravel}>
          <Text style={{ fontFamily: "Noto" }}>مرور</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Transit</Text>
        </View>
        <View style={styles.purposeOfTravel}>
          <Text style={{ fontFamily: "Noto" }}>زيارة</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Visit</Text>
        </View>

        <View style={styles.purposeOfTravel}>
          <Text style={{ fontFamily: "Noto" }}>عمرة</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Umrah</Text>
        </View>
        <View style={styles.purposeOfTravel}>
          <Text style={{ fontFamily: "Noto" }}>للأقامة</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Residence</Text>
        </View>
        <View style={styles.purposeOfTravel}>
          <Text style={{ fontFamily: "Noto" }}>حـج</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Hajj</Text>
        </View>
        <View style={[styles.purposeOfTravel, { width: "72px" }]}>
          <Text style={{ fontFamily: "Noto" }}>دبلوماسية</Text>
          <Text style={{ fontFamily: "Open Sans" }}>Diplomacy</Text>
        </View>
        <Text style={styles.cellRight15}>:الغاية من السفر</Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cellLeft15, { width: "15%" }]}>Issue Place:</Text>
        <Text style={styles.cellCenter20}>
          {employee.placeOfIssue?.toUpperCase()}
        </Text>
        <Text style={[styles.cellRight10, { width: "13%" }]}>:محل الإصدار</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={[styles.cellLeft15, { width: "14%" }]}>Issue Date:</Text>
        <Text style={[styles.cellCenter15, { width: "17%" }]}>
          {format(
            new Date(employee.passportIssueDate as Date),
            "dd-MMM-yyyy"
          ).toUpperCase()}
        </Text>
        <Text style={[styles.cellRight15, { width: "13%" }]}>
          :تاريخ الإصدار
        </Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={[styles.cellLeft20, { width: "17%" }]}>Passport No.:</Text>
        <Text style={styles.cellCenter15}>{employee.passportNumber}</Text>
        <Text style={styles.cellRight10}>:رقم جواز</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft20}>Date of Expiry:</Text>
        <Text style={styles.cellCenter35}>
          {format(
            new Date(employee.passportExpiryDate as Date),
            "dd-MMM-yyyy"
          ).toUpperCase()}
        </Text>
        <Text style={styles.cellRight15}></Text>
        <Text style={styles.cellRight35}>:تاريخ الانتهاء</Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cellLeft35, { width: "40%" }]}>
          {" "}
          Duration of stay in the kingdom :
        </Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellRight20}>:مدة الإقامة بالمملكة</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft15}>Arrival Date</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellRight15}>:تاريخ الوصو</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft20}>Departure Date:</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellRight15}>:تاريخ المغادرة</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft20}>Mode of payment:</Text>
        <Text style={styles.cellCenter5}></Text>
        <Text style={styles.cellRight10}>:محل الإصدار</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft20}>Payment No.:</Text>
        <Text style={styles.cellCenter5}></Text>
        <Text style={styles.cellRight10}>:رقم الدفع</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellLeft20}>Date:</Text>
        <Text style={styles.cellCenter5}></Text>
        <Text style={styles.cellRight10}>:تاريخ</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft20}>Relationship:</Text>
        <Text style={styles.cellCenter20}></Text>
        <Text style={styles.cellRight15}>:صلته</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft35}>Name of guardian:</Text>
        <Text style={styles.cellSpace1}></Text>
        <Text style={styles.cellRight35}>:اسم المحرم</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.cellLeft15}>Destination:</Text>
        <Text style={styles.cellLeft10}></Text>
        <Text style={styles.cellRight35}>: جهةالوصول بالمملكة</Text>
        <Text style={styles.cellSpace2}></Text>
        <Text style={styles.cellLeft20}>Carrier's Name:</Text>
        <Text style={styles.cellLeft10}></Text>
        <Text style={styles.cellRight35}>:اسم الشركة الناقلة</Text>
      </View>
      <DependentsRow employee={employee} />
    </>
  );
};

export default ListRow;
