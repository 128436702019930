import { useRoutes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CacheProvider } from "@emotion/react";

import { useAppSelector } from "./app/hooks";
import routes from "./components/routes";
import { selectAuth } from "./features/auth/authSlice";
import { selectPreference } from "./features/preferences/preferencesSlice";
import AOS from "aos";
import "aos/dist/aos.css";
import themes from "./themes/themes";

import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { useEffect } from "react";

//const isBrowser = typeof document !== "undefined";
//let insertionPoint;

// if (isBrowser) {
//   const emotionInsertionPoint = document.querySelector(
//     'meta[name="emotion-insertion-point"]'
//   );
//   insertionPoint = (emotionInsertionPoint as HTMLElement) ?? undefined;
// }

const cacheRtl = createCache({
  key: "mui-rtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: "mui-ltr",
});

function App() {
  const { user } = useAppSelector(selectAuth);
  const { mode } = useAppSelector(selectPreference);

  const isLoggedIn = user && user.email ? true : false;
  const roles = user?.roles ? user?.roles : [];
  const agencyType =
    user &&
    user.company &&
    user.company.depositAmount &&
    user.company.depositAmount.length > 0
      ? "commission"
      : "employment";
  const routing = useRoutes(routes(isLoggedIn, roles, agencyType));

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
    if (window.location.href.includes("https://www.enjazplus.com"))
      window.location.replace("https://enjazplus.com/login");
  }, [i18n]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    AOS.refresh();
  }, []);
  // useLayoutEffect(() => {
  //   document.body.setAttribute("dir", i18n.dir());
  // }, [i18n]);
  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider
        value={i18n.dir() === "rtl" ? cacheRtl : cacheLtr}
      ></CacheProvider>
      <ThemeProvider
        theme={
          mode === "dark"
            ? {
                ...themes.dark,
                direction: i18n.dir(),
              }
            : {
                ...themes.light,
                direction: i18n.dir(),
              }
        }
      >
        <div dir={i18n.dir()}>{routing}</div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
