export const ar = [
  { key: "sex", value: "الجنس" },
  { key: "male", value: "ذكر" },
  { key: "female", value: "أنثى" },
  { key: "religion", value: "الديانة" },
  { key: "muslim", value: "مسلم" },
  { key: "christian", value: "مسيحي" },
  { key: "non_muslim", value: "غير مسلم" },
  { key: "other_religion", value: "دين آخر" },
  { key: "maritalStatus", value: "الحالة الإجتماعية" },
  { key: "single", value: "غير متزوج" },
  { key: "married", value: "متزوج" },
  { key: "divorced", value: "مُطلّق" },
  { key: "widow", value: "أرملة" },
  { key: "separated", value: "منفصل" },
  { key: "language", value: "اللغة" },
  { key: "language_knowledge", value: "معرفة اللغات" },
  { key: "arabic", value: "اللغة العربية" },
  { key: "english", value: "اللغة الإنجليزية" },
  { key: "arabic2", value: "العربية" },
  { key: "english2", value: "الإنجليزية" },
  { key: "poor", value: "لا شيء" },
  { key: "poor2", value: "ضعيف" },
  { key: "fair", value: "وسط" },
  { key: "average", value: "مقبول" },
  { key: "good", value: "جيد" },
  { key: "fluent", value: "ممتاز" },
  { key: "applied", value: "مُطبَّق" },
  { key: "profession", value: "لمهنة" },
  { key: "housemaid", value: "عاملة المنزل" },
  { key: "housemaid2", value: "خادمة المنزل" },
  { key: "house_worker", value: "عامل منزل" },
  { key: "driver", value: "سائق" },
  { key: "baby_sitter", value: "جليسه اطفال" },
  { key: "nanny", value: "مربية" },
  { key: "cooker", value: "طبخ" },
  { key: "nurse", value: "ممرضة" },
  { key: "farm_worker", value: "عامل مزرعة" },
  { key: "other_profession", value: "مهنة أخرى" },
  { key: "contractPeriod", value: "مدة العقد" },
  { key: "forYears", value: "للمدة" },
  { key: "period", value: "للمدة" },
  { key: "one", value: "سنة واحدة" },
  { key: "two", value: "سنتان" },
  { key: "three", value: "ثلاث سنوات" },
  { key: "four", value: "أربع سنوات" },
  { key: "five", value: "خمس سنوات" },
  { key: "six", value: "ست سنوات" },
  { key: "seven", value: "سبع سنوات" },
  { key: "eight", value: "ثماني سنوات" },
  { key: "nine", value: "تسع سنوات" },
  { key: "ten", value: "عشر سنوات" },
  { key: "complexion", value: "لون البشرة" },
  { key: "acceptable", value: "مقبول" },
  { key: "brown", value: "بني" },
  { key: "black", value: "أسود" },
  { key: "blue", value: "أزرق" },
  { key: "ageCategory", value: "الفئة العمرية" },
  { key: "bet2125", value: "بين ۲۱ و ۲٥" },
  { key: "bet2630", value: "بين ۲٦ و ۳۰" },
  { key: "bet3135", value: "بين ۳۱ و ۳٥" },
  { key: "bet3640", value: "بين ۳٦ و ٤۰" },
  { key: "bet4145", value: "بين ٤۱ و ٤٥" },
  { key: "country", value: "البلد" },
  { key: "destination", value: "بلد الوجهة" },
  { key: "ethiopia", value: "إثيوبيا" },
  { key: "kenya", value: "كينيا" },
  { key: "sudan", value: "السودان" },
  { key: "eritrea", value: "إريتريا" },
  { key: "somalia", value: "الصومال" },
  { key: "addis_ababa", value: "اديس ابابا" },
  { key: "saudi_arabia", value: "السعودية" },
  { key: "bahrain", value: "البحرين" },
  { key: "lebanon", value: "لبنان" },
  { key: "oman", value: "عُمان" },
  { key: "qatar", value: "قطر" },
  { key: "kuwait", value: "الكويت" },
  { key: "jordan", value: "الأردن" },
  { key: "syria", value: "سوريا" },
  { key: "uae", value: "الإمارات العربية المتحدة" },
  { key: "yemen", value: "اليمن" },
  { key: "previousNationality", value: "الجنسية السابقة" },
  { key: "nationality", value: "الجنسية" },
  { key: "ethiopian", value: "الاثيوبية" },
  { key: "kenyan", value: "الكيني" },
  { key: "yemeni", value: "يمني" },
  { key: "sudanese", value: "سوداني" },
  { key: "somali", value: "صومالي" },
  { key: "eritrean", value: "إريترين" },
  { key: "skills", value: "مهارات" },
  { key: "washing_dishes", value: "غسل" },
  { key: "washing_ironing_clothes", value: "كى الملابس" },
  { key: "dusting_cleaning", value: "النظافة" },
  { key: "baby_sitting", value: "تربية الأطفال" },
  { key: "children_care", value: "عناية بالأطفال" },
  { key: "elderly_care", value: "عناية كبار السن" },
  { key: "cooking", value: "الطبخ" },
  { key: "arabic_cooking", value: "الطبخ العربي" },
  { key: "tutoring_children", value: "دروس الأطفال" },
  { key: "tutor", value: "الدروس" },
  { key: "sewing", value: "خياطة" },
  { key: "computer", value: "استخدام الحاسوب" },
  { key: "decorating", value: "تزيين" },
  { key: "driving", value: "القيادة" },
  { key: "other_skills", value: "مهارات اخرى" },
  { key: "willing_to_learn", value: "Willing to Learn" },
  { key: "currency", value: "عملة" },
  { key: "sarLONG", value: "ريال سعودي" },
  { key: "saudi_riyal", value: "ريال" },
  { key: "qatar_riyal", value: "ر.ق" },
  { key: "qarShort", value: "ر.ق" },
  { key: "kwdLong", value: "دينار كويتي" },
  { key: "kuwait_dinar", value: "د.ك" },
  { key: "aedLong", value: "درهم إماراتي" },
  { key: "emirate_dirham", value: "د.إ" },
  { key: "jodLong", value: "دينار أردني" },
  { key: "jordan_dinar", value: "د.أردني" },
  { key: "lbnLong", value: "ليرة لبنانية" },
  { key: "lebanon_lire", value: ".ل.ل" },
  { key: "education_level", value: "الدرجة العلمية" },
  { key: "living_town", value: "مكان السكن" },
  { key: "grade_eight_complete", value: "الثامن" },
  { key: "secondary_complete", value: "الثانوية كاملة" },
  { key: "vocational_complete", value: "استكمال المهني" },
  { key: "college_complete", value: "الكلية كاملة" },
  { key: "post_graduate", value: "دراسات عليا" },
  { key: "other_education", value: "تعليم الآخرين" },
  { key: "passport", value: "بيانات جواز السفر" },
  { key: "passportDet", value: "بيانات جواز" },
  { key: "photo", value: "" },
  { key: "id_card", value: "" },
  { key: "emergency_person_photo", value: "" },
  { key: "emergency_person_id_card", value: "" },
  { key: "finger_print", value: "" },
  { key: "medical", value: "" },
  { key: "grade_eight_certificate", value: "" },
  { key: "coc_certificate", value: "" },
  { key: "abroad_job_agreement", value: "" },
  { key: "trip_orientation", value: "" },
  { key: "currentStatus", value: "الحالة الحالية" },
  { key: "new", value: "جديد" },
  { key: "visa_assigned", value: "المختار" },
  { key: "on_process", value: "جاري التنفيذ" },
  { key: "labour_process", value: "في وزارة العمل" },
  { key: "embassy_process", value: "في السفارة" },
  { key: "flight_process", value: "على تجهيز الرحلة" },
  { key: "flight_booked", value: "تم حجز رحلة الطيران" },
  { key: "on_good_condition", value: "بحالة جيدة" },
  { key: "discontinued", value: "توقفت العملية" },
  { key: "visa_canceled", value: "تم إلغاء التأشيرة" },
  { key: "lost", value: "اهرب" },
  { key: "returned", value: "عاد إلى البلاد" },
  { key: "with_complain", value: "مع الشكوى" },
  { key: "work", value: "عمل" },
  { key: "visit", value: "زيارة" },
  { key: "transit", value: "عبور" },
  { key: "residence", value: "إقامة" },
  { key: "umrah", value: "عمرة" },
  { key: "hajj", value: "الحج" },
  { key: "other", value: "آخر" },
  { key: "diplomacy", value: "الدبلوماسية" },
  { key: "dashboard", value: "لوحة القيادة" },
  { key: "platform", value: "منصة انجاز+ للتوظيف" },
  { key: "enjaz+", value: "انجاز+" },
  { key: "candidates", value: "مرشحين" },
  { key: "cv", value: "سيرة ذاتية" },
  { key: "addNew", value: "اضف جديد" },
  { key: "save", value: "يحفظ" },
  { key: "submit", value: "يُقدِّم" },
  { key: "employee", value: "موظف" },
  { key: "employees", value: "الموظفين" },
  { key: "data", value: "بيانات" },
  { key: "complaints", value: "شكاوي" },
  { key: "visa", value: "تأشيرة" },
  { key: "visas", value: "التأشيرات" },
  { key: "processes", value: "العمليات" },
  { key: "process", value: "عملية" },
  { key: "embassy", value: "السفارة " },
  { key: "flight", value: "رحلة جوية" },
  { key: "molsa", value: "وزارة الموظف" },
  { key: "insurance", value: "تأمين" },
  { key: "actions", value: "أجراءات" },
  { key: "numberOf", value: "عدد" },
  { key: "no.", value: "رقم" },
  { key: "yes", value: "نعم" },
  { key: "no", value: "لا" },
  { key: "doNot", value: "لا" },
  { key: "number", value: "رقم" },
  { key: "perPage", value: "لكل صفحة" },
  { key: "list", value: "فهرس" },
  { key: "brief", value: "مختصر" },
  { key: "detail", value: "التفاصيل" },
  { key: "statusTracking", value: "الحالة" },
  { key: "back", value: "خلف" },
  { key: "next", value: "التالي" },
  { key: "chatOnWhatsApp", value: "الدردشة على الواتس اب" },
  { key: "support", value: "يدعم" },
  { key: "aboutUs", value: "معلومات عنا" },
  { key: "refresh", value: "إعادة تحميل" },
  { key: "search", value: "بحث" },
  { key: "search...", value: "بحث..." },
  { key: "fullName", value: "الاسم الكامل" },
  { key: "name", value: "الاسم" },
  { key: "givenName", value: "الاسم الأول" },
  { key: "surName", value: "اسم العائلة" },
  { key: "passportNumber", value: "رقم جواز" },
  { key: "placeOfIssue", value: "محل الإصدا" },
  { key: "dateOfIssue", value: "تاريخ الإصدار" },
  { key: "dateOfExpiry", value: "تاريخ الانتهاء" },
  { key: "dateOfBirth", value: "تاريخ الميلاد" },
  { key: "placeOfBirth", value: "محل الولادة" },
  { key: "shareByWhatsApp", value: "شارك عبر الواتس اب" },
  { key: "workExperience", value: "خبرة العمل" },
  { key: "between", value: "بين" },
  { key: "and", value: "و" },
  { key: "from", value: "من" },
  { key: "to", value: "إلى" },
  { key: "detailsOfApplication", value: "بيانات الشخصية" },
  { key: "workInformation", value: "بيانات التوظيف" },
  { key: "employmentApplication", value: "تطبيق التوظيف" },
  { key: "referenceNumber", value: "رقم الطلب" },
  { key: "applicationDate", value: "تاريخ الطلب" },
  { key: "personalPhoto", value: "صورة شخصية" },
  { key: "passportPhoto", value: "صورة جواز" },
  { key: "numberOfChildren", value: "عدد الاطفال" },
  { key: "age", value: "العمر" },
  { key: "weight", value: "الوزن" },
  { key: "kg", value: "كلغ" },
  { key: "height", value: "الطول" },
  { key: "meter", value: "متر" },
  { key: "salary", value: "الراتب الشهري" },
  { key: "remarks", value: "ملاحظة" },
  { key: "firstTimerOld", value: "للمرة الأولى" },
  { key: "firstTimer", value: "لم تعمل من قبل" },
  { key: "goodLooking", value: "جميل المظهر" },
  { key: "readingAndWriting", value: "قراءة وكتابة" },
  { key: "telephone", value: "هاتف" },
  { key: "employee_telephone", value: "هاتف" },
  { key: "contact_name", value: "اسم الاتصال" },
  { key: "contact_telephone", value: "اتصال هاتفي" },
  { key: "year_graduated", value: "تاريخ التخرج" },
  { key: "graduated_school", value: "اسم المدرسة" },
  { key: "alternateTelephone", value: "هاتف بديل" },
  { key: "city", value: "مدينة" },
  { key: "address", value: "عنوان" },
  { key: "condition", value: "حالة" },
  { key: "sponsor", value: "راعي" },
  { key: "trainings", value: "التدريبات" },
  { key: "videos", value: "التدريبات" },
  { key: "links", value: "الروابط" },
  { key: "afghan", value: "أفغاني" },
  { key: "albanian", value: "ألباني" },
  { key: "algerian", value: "جزائري" },
  { key: "american", value: "أمريكي" },
  { key: "andorran", value: "أندوري" },
  { key: "angolan", value: "أنغولي" },
  { key: "antiguans", value: "انتيغوا" },
  { key: "argentinean", value: "أرجنتيني" },
  { key: "armenian", value: "أرميني" },
  { key: "australian", value: "أسترالي" },
  { key: "austrian", value: "نمساوي" },
  { key: "azerbaijani", value: "أذربيجاني" },
  { key: "bahamian", value: "باهامى" },
  { key: "bahraini", value: "بحريني" },
  { key: "bangladeshi", value: "بنجلاديشي" },
  { key: "barbadian", value: "باربادوسي" },
  { key: "barbudans", value: "بربودا" },
  { key: "batswana", value: "بوتسواني" },
  { key: "belarusian", value: "بيلاروسي" },
  { key: "belgian", value: "بلجيكي" },
  { key: "belizean", value: "بليزي" },
  { key: "beninese", value: "بنيني" },
  { key: "bhutanese", value: "بوتاني" },
  { key: "bolivian", value: "بوليفي" },
  { key: "bosnian", value: "بوسني" },
  { key: "brazilian", value: "برازيلي" },
  { key: "british", value: "بريطاني" },
  { key: "bruneian", value: "بروناى" },
  { key: "bulgarian", value: "بلغاري" },
  { key: "burkinabe", value: "بوركيني" },
  { key: "burmese", value: "بورمي" },
  { key: "burundian", value: "بوروندي" },
  { key: "cambodian", value: "كمبودي" },
  { key: "cameroonian", value: "كاميروني" },
  { key: "canadian", value: "كندي" },
  { key: "capeVerdean", value: "االرأس الأخضر" },
  { key: "centralAfrican", value: "وسط أفريقيا" },
  { key: "chadian", value: "تشادي" },
  { key: "chilean", value: "شيلي" },
  { key: "chinese", value: "صينى" },
  { key: "colombian", value: "كولومبي" },
  { key: "comoran", value: "جزر القمر" },
  { key: "congolese", value: "كونغولي" },
  { key: "costaRican", value: "كوستاريكي" },
  { key: "croatian", value: "كرواتية" },
  { key: "cuban", value: "كوبي" },
  { key: "cypriot", value: "قبرصي" },
  { key: "czech", value: "تشيكي" },
  { key: "danish", value: "دانماركي" },
  { key: "djibouti", value: "جيبوتي" },
  { key: "dominican", value: "دومينيكاني" },
  { key: "dutch", value: "هولندي" },
  { key: "eastTimorese", value: "تيموري شرقي" },
  { key: "ecuadorean", value: "اكوادوري" },
  { key: "egyptian", value: "مصري" },
  { key: "egypt", value: "مصر" },
  { key: "emirian", value: "إماراتي" },
  { key: "equatorialGuinean", value: "غيني  استوائي" },
  { key: "estonian", value: "إستوني" },
  { key: "fijian", value: "فيجي" },
  { key: "filipino", value: "فلبيني" },
  { key: "finnish", value: "فنلندي" },
  { key: "french", value: "فرنسي" },
  { key: "gabonese", value: "جابوني" },
  { key: "gambian", value: "غامبيي" },
  { key: "georgian", value: "جورجي" },
  { key: "german", value: "ألماني" },
  { key: "ghanaian", value: "غاني" },
  { key: "greek", value: "إغريقي" },
  { key: "grenadian", value: "جرينادي" },
  { key: "guatemalan", value: "غواتيمالي" },
  { key: "guineaBissauan", value: "غيني بيساوي" },
  { key: "guinean", value: "غيني" },
  { key: "guyanese", value: "جوياني" },
  { key: "haitian", value: "هايتي" },
  { key: "herzegovinian", value: "هرسكي" },
  { key: "honduran", value: "هندوراسي" },
  { key: "hungarian", value: "هنغاري" },
  { key: "icelander", value: "إيسلندي" },
  { key: "indian", value: "هندي" },
  { key: "indonesian", value: "إندونيسي" },
  { key: "iranian", value: "إيراني" },
  { key: "iraqi", value: "عراقي" },
  { key: "irish", value: "إيرلندي" },
  { key: "italian", value: "إيطالي" },
  { key: "ivorian", value: "إفواري" },
  { key: "jamaican", value: "جامايكي" },
  { key: "japanese", value: "ياباني" },
  { key: "jordanian", value: "أردني" },
  { key: "kazakhstani", value: "كازاخستاني" },
  { key: "kittianAndNevisian", value: "كيتياني ونيفيسياني" },
  { key: "kuwaiti", value: "كويتي" },
  { key: "kyrgyz", value: "قيرغيزستان" },
  { key: "laotian", value: "لاوسي" },
  { key: "latvian", value: "لاتفي" },
  { key: "lebanese", value: "لبناني" },
  { key: "liberian", value: "ليبيري" },
  { key: "libyan", value: "ليبي" },
  { key: "liechtensteiner", value: "ليختنشتايني" },
  { key: "lithuanian", value: "لتواني" },
  { key: "luxembourger", value: "لكسمبرغي" },
  { key: "macedonian", value: "مقدوني" },
  { key: "malagasy", value: "مدغشقري" },
  { key: "malawian", value: "مالاوى" },
  { key: "malaysian", value: "ماليزي" },
  { key: "maldivan", value: "مالديفي" },
  { key: "malian", value: "مالي" },
  { key: "maltese", value: "مالطي" },
  { key: "marshallese", value: "مارشالي" },
  { key: "mauritanian", value: "موريتاني" },
  { key: "mauritian", value: "موريشيوسي" },
  { key: "mexican", value: "مكسيكي" },
  { key: "micronesian", value: "ميكرونيزي" },
  { key: "moldovan", value: "مولدوفي" },
  { key: "monacan", value: "موناكو" },
  { key: "mongolian", value: "منغولي" },
  { key: "moroccan", value: "مغربي" },
  { key: "mosotho", value: "ليسوتو" },
  { key: "motswana", value: "لتسواني" },
  { key: "mozambican", value: "موزمبيقي" },
  { key: "namibian", value: "ناميبي" },
  { key: "nauruan", value: "ناورو" },
  { key: "nepalese", value: "نيبالي" },
  { key: "newZealander", value: "نيوزيلندي" },
  { key: "niVanuatu", value: "ني فانواتي" },
  { key: "nicaraguan", value: "نيكاراغوا" },
  { key: "nigerien", value: "نيجري" },
  { key: "northKorean", value: "كوري شمالي" },
  { key: "northernIrish", value: "إيرلندي شمالي" },
  { key: "norwegian", value: "نرويجي" },
  { key: "omani", value: "عماني" },
  { key: "pakistani", value: "باكستاني" },
  { key: "palauan", value: "بالاوي" },
  { key: "palestinian", value: "فلسطيني" },
  { key: "panamanian", value: "بنمي" },
  { key: "papuaNewGuinean", value: "بابوا غينيا الجديدة" },
  { key: "paraguayan", value: "باراغواياني" },
  { key: "peruvian", value: "بيروفي" },
  { key: "polish", value: "بولندي" },
  { key: "portuguese", value: "برتغالي" },
  { key: "qatari", value: "قطري" },
  { key: "romanian", value: "روماني" },
  { key: "russian", value: "روسي" },
  { key: "rwandan", value: "رواندي" },
  { key: "saintLucian", value: "لوسياني" },
  { key: "salvadoran", value: "سلفادوري" },
  { key: "samoan", value: "ساموايان" },
  { key: "sanMarinese", value: "سان مارينيز" },
  { key: "saoTomean", value: "ساو توميان" },
  { key: "saudi", value: "سعودي" },
  { key: "scottish", value: "سكتلندي" },
  { key: "senegalese", value: "سنغالي" },
  { key: "serbian", value: "صربي" },
  { key: "seychellois", value: "سيشلي" },
  { key: "sierraLeonean", value: "سيرا ليوني" },
  { key: "singaporean", value: "سنغافوري" },
  { key: "slovakian", value: "سلوفاكي" },
  { key: "slovenian", value: "سلوفيني" },
  { key: "solomonIslander", value: "جزر سليمان" },
  { key: "southAfrican", value: "جنوب افريقيي" },
  { key: "southKorean", value: "كوري جنوبي" },
  { key: "spanish", value: "إسباني" },
  { key: "sriLankan", value: "سري لانكي" },
  { key: "surinamer", value: "سورينامي" },
  { key: "swazi", value: "سوازي" },
  { key: "swedish", value: "سويدي" },
  { key: "swiss", value: "سويسري" },
  { key: "syrian", value: "سوري" },
  { key: "taiwanese", value: "تايواني" },
  { key: "tajik", value: "طاجيكي" },
  { key: "tanzanian", value: "تنزاني" },
  { key: "thai", value: "التايلاندي" },
  { key: "togolese", value: "توغواني" },
  { key: "tongan", value: "تونجاني" },
  { key: "trinidadianorTobagonian", value: "ترينيدادي أو توباغوني" },
  { key: "tunisian", value: "تونسي" },
  { key: "turkish", value: "تركي" },
  { key: "tuvaluan", value: "توفالي" },
  { key: "ugandan", value: "أوغندي" },
  { key: "ukrainian", value: "أوكراني" },
  { key: "uruguayan", value: "أوروجواي" },
  { key: "uzbekistani", value: "أوزبكستاني" },
  { key: "venezuelan", value: "فنزويلي" },
  { key: "vietnamese", value: "فيتنامي" },
  { key: "welsh", value: "ويلزي" },
  { key: "zambian", value: "زامبي" },
  { key: "zimbabwean", value: "زيمبابوي" },
];
