import { Helmet } from "react-helmet";

import { Form, FormikProps, Formik } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { FormikTextField } from "../../components/Layout/FormikTextField";
import AuthSkeleton from "../auth/AuthSkeleton";

import { checkStatusCaptchaSchema } from "../auth/validation";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { selectAuth } from "../auth/authSlice";
import Toast from "../../components/Layout/Toast";
import { Container, Stack } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { createRef, useState } from "react";
import { RecaptchaCheckStatus } from "../auth/types/authType";
import { useTranslation } from "react-i18next";
import { checkStatusRecaptcha } from "./processSlices";

export const CheckStatus = () => {
  const { loading, error } = useAppSelector(selectAuth);
  const { t: tt } = useTranslation();
  const [recaptchaCheckStatus, setRecaptchaCheckStatus] =
    useState<RecaptchaCheckStatus>({
      searchText: "",
      captcha: "",
    });
  const dispatch = useAppDispatch();

  const recaptchaRef = createRef<ReCAPTCHA>();
  const onReCAPTCHAChange = async (captchaCode: string | undefined | null) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }
    try {
      dispatch(
        checkStatusRecaptcha({ ...recaptchaCheckStatus, captcha: captchaCode })
      );
    } catch (error) {
      //setError(error?.message || "Something went wrong");
    } finally {
      // Reset the reCAPTCHA when the request has failed or succeeded
      // so that it can be executed again if user submits another email.

      if (recaptchaRef.current) recaptchaRef.current.reset();
    }
  };
  return (
    <>
      <Helmet>
        <title>Check Status | EnjazPlus</title>
      </Helmet>
      <Container maxWidth="sm" sx={{ height: "100vh" }}>
        <Card elevation={3} sx={{ m: 2 }}>
          <CardContent>
            <Stack>
              <Stack sx={{ mb: 3, alignItems: "center" }}>
                <Typography variant="h2">{tt("statusTracking")}</Typography>
              </Stack>

              {loading === "pending" ? (
                <AuthSkeleton />
              ) : (
                <>
                  <Formik
                    initialValues={recaptchaCheckStatus}
                    validationSchema={checkStatusCaptchaSchema}
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false);
                      recaptchaRef.current!.execute();
                      //console.log(values);
                      setRecaptchaCheckStatus({ ...values });
                      //dispatch(signUp(values));
                    }}
                  >
                    {(props: FormikProps<RecaptchaCheckStatus>) => (
                      <Form>
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey={
                            process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
                          }
                          onChange={onReCAPTCHAChange}
                        />
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <FormikTextField
                              formikKey="searchText"
                              label={tt("search")}
                            />
                          </Grid>
                        </Grid>

                        {error && (
                          <Toast severity="error">{error.message}</Toast>
                        )}
                        <Box component="div">
                          <Button
                            sx={{ width: "100%", marginY: "8px" }}
                            type="submit"
                            color="secondary"
                            variant="contained"
                            disabled={!props.isValid}
                          >
                            {tt("statusTracking")}
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  <Stack sx={{ my: 1, alignItems: "center" }} direction="row">
                    <Typography>{tt("currentStatus")}</Typography>
                  </Stack>
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};
