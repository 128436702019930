import Grid from "@mui/material/Grid";
import { Box, Stack } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import { BasicPostAppliedSingleValue } from "./components/basic/BasicPostApplied";
import { BasicDetail } from "./components/basic/BasicDetail";
import {
  BasicLanguage,
  BasicLanguageDetail,
  BasicLanguageMaleda,
} from "./components/basic/BasicLanguage";
import { BasicExperience } from "./components/basic/BasicExperience";
import {
  BasicSkillsSmallHorz,
  BasicSkillsSmallHorzTwoLines,
  BasicSkillsSmallHorzTwoLinesTwoLang,
} from "./components/basic/BasicSkills";
import { BasicPassport } from "./components/basic/BasicPassport";
import { BasicFullName } from "./components/basic/BasicFullName";
import { BasicFace, BasicFaceWithBg } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicRemarks } from "./components/basic/BasicRemarks";
import { BasicTitleSingleLang } from "./components/basic/BasicTitle";
import {
  BasicReference,
  BasicReferenceOnly,
} from "./components/basic/BasicReference";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { StandWithHeader } from "./components/basic/StandWithHeader";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
function MaledaTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  //a08745;f2f1f0 fffcf2
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="center"
          >
            {/* Short Photo */}
            <Grid item xs={5}>
              {cvTemplate && currentEmployee && (
                <BasicFaceWithBg
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>

            <Grid container item xs={7}>
              <Stack spacing={cvTemplate.spacing} sx={{ width: "100%" }}>
                <BasicTitleSingleLang template={cvTemplate} />
                <BasicReferenceOnly
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
                {cvTemplate && currentEmployee && (
                  <>
                    <BasicPostAppliedSingleValue
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                      showHeader={false}
                      showProfession={true}
                      showSalary={true}
                      showContractPeriod={true}
                    />
                    <BasicPassport
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                      showHeader={false}
                      showPassportNumber={true}
                      showIssueDate={false}
                      showPlaceOfIssue={false}
                      showExpiryDate={true}
                    />
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicFullName
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={false}
                showPlaceOfBirth={true}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={false}
                showComplexion={false}
                showEducation={false}
                showLivingTown={false}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showNationality={false}
                showReligion={false}
                showDateOfBirth={false}
                showAge={true}
                showPlaceOfBirth={false}
                showMaritalStatus={false}
                showNumberOfChildren={false}
                showWeightHeight={true}
                showComplexion={true}
                showEducation={true}
                showLivingTown={false}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicLanguageMaleda
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showEducation={true}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicExperience
                currentEmployee={currentEmployee}
                template={cvTemplate}
                type="basic"
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicSkillsSmallHorzTwoLinesTwoLang
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <StandWithHeader
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              {cvTemplate && currentEmployee && (
                <BasicRemarks
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid>
            {/* <Grid item xs={2}>
              {cvTemplate && currentEmployee && (
                <BasicLocation
                  type="location"
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                />
              )}
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}
        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 15,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default MaledaTemplate;
