import { useEffect, useState, ChangeEvent } from "react";
import { Helmet } from "react-helmet";
import { addMonths, endOfDay, startOfDay, isBefore, isAfter } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  fetchEmployees,
  selectProcesses,
} from "../../features/process/processSlices";
import Grid from "@mui/material/Grid";

import {
  changePageTitle,
  selectPreference,
} from "../../features/preferences/preferencesSlice";
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  TextField,
  MenuItem,
  Button,
  Stack,
} from "@mui/material";
import { ExpandMore, Search, ShowChart, VideoCall } from "@mui/icons-material";

import { useTranslation } from "react-i18next";
import { PDFViewer } from "@react-pdf/renderer";
import { selectAuth } from "../../features/auth/authSlice";
import { AuthUser } from "../../features/auth/types/authType";
import {
  Employee,
  EmployeeStatus,
} from "../../features/process/types/processTypes";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import {
  getAmharicCalendar,
  getAmharicCalendarFormatted,
} from "../../utils/calendarUtility";
import { AfterFlight } from "./labour/AfterFlight";
import { NavLink } from "react-router-dom";

export const Reports = () => {
  const dispatch = useAppDispatch();
  const { t: te } = useTranslation("enums");
  const {
    employeesWithCount: { employees },
  } = useAppSelector(selectProcesses);
  const { locale } = useAppSelector(selectPreference);

  const { user } = useAppSelector(selectAuth);
  const [employeesForFilter, setEmployeesForFilter] = useState<Employee[]>([]);
  const [status, setStatus] = useState(EmployeeStatus.on_good_condition);
  const [startDate, setStartDate] = useState<Date | null>(
    addMonths(new Date(), -1)
  );
  const [startAmharicDate, setStartAmharicDate] = useState(
    getAmharicCalendarFormatted(addMonths(new Date(), -1), "/")
  );
  const [durationEndDate, setDurationEndDate] = useState<Date | null>(
    addMonths(new Date(), 1)
  );
  const [endAmharicDate, setEndAmharicDate] = useState(
    getAmharicCalendarFormatted(addMonths(new Date(), 11), "/")
  );
  const [letterDate, setLetterDate] = useState<Date | null>(new Date());
  const [letterAmharicDate, setLetterAmharicDate] = useState(
    getAmharicCalendarFormatted(new Date(), "/")
  );
  const [letterNumber, setLetterNumber] = useState("");

  const handleChange = (v: string) => {
    setLetterNumber(v);
  };

  const onChangeStatus = (value: ChangeEvent<HTMLInputElement>) => {
    setStatus(value.target.value as EmployeeStatus);
  };

  useEffect(() => {
    dispatch(changePageTitle("Reports"));
    ShowAll();
  }, [dispatch]);

  const ShowAll = () => {
    //refreshList: "refresh",
    dispatch(
      fetchEmployees({
        skip: 0,
        take: 500,
      })
    );
  };

  useEffect(() => {
    if (employees.length > 0) {
      //setEmployeesForFilter(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (startDate) {
      setStartAmharicDate(getAmharicCalendar(startDate, true));
    }
  }, [startDate]);

  useEffect(() => {
    if (durationEndDate) {
      setEndAmharicDate(getAmharicCalendar(durationEndDate, true));
    }
  }, [durationEndDate]);

  useEffect(() => {
    if (letterDate) {
      setLetterAmharicDate(getAmharicCalendarFormatted(letterDate, "/"));
    }
  }, [letterDate]);

  // useEffect(() => {
  //   if (employeesForFilter.length > 0) {
  //     updateInstance();
  //   }
  // }, [employeesForFilter]);

  function filterList() {
    setEmployeesForFilter(getFilteredList);
  }
  const getFilteredList = function () {
    return employees.filter(function (emp) {
      let found = true;
      //Filter By Status
      if (
        (status as EmployeeStatus) !== EmployeeStatus.all &&
        emp.status !== status
      ) {
        found = false;
      }

      //Filter By Duration
      if (found) {
        if (
          status === EmployeeStatus.on_good_condition ||
          status === EmployeeStatus.lost ||
          status === EmployeeStatus.returned ||
          status === EmployeeStatus.contract_completed
        ) {
          if (
            !(
              emp.additionalData &&
              emp.additionalData.afterFlightStatusDate &&
              startDate &&
              durationEndDate &&
              isAfter(
                new Date(emp.additionalData.afterFlightStatusDate),
                new Date(startOfDay(startDate))
              ) &&
              isBefore(
                new Date(emp.additionalData.afterFlightStatusDate),
                new Date(endOfDay(durationEndDate))
              )
            )
          ) {
            found = false;
          }
        }
      }
      if (found) {
        if (status === EmployeeStatus.discontinued) {
          if (
            !(
              emp.additionalData &&
              emp.additionalData.discontinuedDate &&
              startDate &&
              durationEndDate &&
              isAfter(
                new Date(emp.additionalData.discontinuedDate),
                new Date(startOfDay(startDate))
              ) &&
              isBefore(
                new Date(emp.additionalData.discontinuedDate),
                new Date(endOfDay(durationEndDate))
              )
            )
          ) {
            found = false;
          }
        }
      }
      return found;
    });
  };

  return (
    <>
      <Helmet>
        <title>Reports | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Button
          size="small"
          color="secondary"
          component={NavLink}
          to={`/app/tutor/e9AOQIhM7jY`}
        >
          Watch Video
          <VideoCall />
        </Button>
        <>
          <>
            <Accordion expanded>
              <StyledAccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Search />
                <Typography variant="h6" component="div">
                  Filter Report
                </Typography>
              </StyledAccordionSummary>

              <AccordionDetails>
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <Grid item md={4} xs={12}>
                    <TextField
                      id="statuses"
                      select
                      defaultValue="all"
                      label={"By Current Status"}
                      onChange={onChangeStatus}
                      value={status}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      {Object.keys(EmployeeStatus)
                        .filter(
                          (st) =>
                            st === EmployeeStatus.on_good_condition ||
                            st === EmployeeStatus.lost ||
                            st === EmployeeStatus.returned ||
                            st === EmployeeStatus.discontinued ||
                            st === EmployeeStatus.contract_completed
                        )
                        .map((option) => (
                          <MenuItem key={option} value={option as string}>
                            {te(option.toLowerCase())}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Stack direction="row" alignItems="center">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <DatePicker
                          label={"From Date"}
                          disableMaskedInput
                          views={["year", "month", "day"]}
                          minDate={new Date("2023-01-01")}
                          inputFormat="DD-MMM-YYYY"
                          value={startDate}
                          onChange={(newValue) => {
                            setStartDate(new Date(newValue as Date));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="startDate"
                              sx={{
                                mt: 1,
                              }}
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Typography variant="h6" noWrap sx={{ width: "60%" }}>
                        {startAmharicDate}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Stack direction="row" alignItems="center">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <DatePicker
                          label={"To Date"}
                          disableMaskedInput
                          views={["year", "month", "day"]}
                          minDate={new Date("2023-01-01")}
                          inputFormat="DD-MMM-YYYY"
                          value={durationEndDate}
                          onChange={(newValue) => {
                            setDurationEndDate(new Date(newValue as Date));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="durationEndDate"
                              sx={{
                                mt: 1,
                              }}
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Typography variant="h6" noWrap sx={{ width: "60%" }}>
                        {endAmharicDate}
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <Stack direction="row" alignItems="center">
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={locale}
                      >
                        <DatePicker
                          label={"ቀን"}
                          disableMaskedInput
                          views={["year", "month", "day"]}
                          minDate={new Date("2023-01-01")}
                          inputFormat="DD-MMM-YYYY"
                          value={letterDate}
                          onChange={(newValue) => {
                            setLetterDate(new Date(newValue as Date));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="startDate"
                              sx={{
                                mt: 1,
                              }}
                              fullWidth
                              size="small"
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Typography variant="h6" noWrap sx={{ width: "60%" }}>
                        {letterAmharicDate}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      id="filterByText"
                      label="ደብዳቤ ቁጥር"
                      onChange={(e) => handleChange(e.target.value)}
                      value={letterNumber}
                      variant="outlined"
                      size="small"
                      fullWidth
                      sx={{ mt: 1 }}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={filterList}
                      size="large"
                      fullWidth
                      sx={{ mt: 1 }}
                    >
                      <ShowChart /> Show
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        </>
        {employeesForFilter && employeesForFilter.length > 0 && (
          <PDFViewer style={{ height: "900px", width: "100%" }}>
            <AfterFlight
              employees={employeesForFilter}
              user={user as AuthUser}
              letterDate={letterAmharicDate}
              letterNumber={letterNumber}
              reportType={status}
              durationStartDate={startAmharicDate}
              durationEndDate={endAmharicDate}
            />
          </PDFViewer>
        )}
      </Box>
    </>
  );
};
// <Document
//   creator="pinnasofts"
//   author="pinnaSofts"
//   title="enjaz+"
//   subject={"title"}
// >
//   <Page size="A4">
//     <AfterFlight
//       employees={employeesForFilter}
//       user={user as AuthUser}
//     />
//   </Page>
// </Document>
