import { NavLink, useNavigate } from "react-router-dom";
import data from "./data.json";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

export interface TutorVideo {
  id: string;
  url: string;
  image: string;
  title: string;
  content: string;
}
export interface TutorVideos {
  videos: TutorVideo[];
}
// { videos }: TutorVideos
export default function Tutors() {
  const navigate = useNavigate();

  const viewVideo = (id: string) => {
    navigate(`/app/tutor/${id}`);
  };
  return (
    <Box sx={{ m: 4, py: 2 }}>
      <Grid container spacing={4}>
        {data.posts.map((video: TutorVideo, index) => (
          <Grid item xs={12} md={6} lg={4}>
            <Card key={video.id} sx={{ p: 2 }}>
              <CardActionArea
                onClick={() => viewVideo(video.id)}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <CardHeader
                  sx={{ color: "primary.main" }}
                  title={`#${index + 1} - ${video.title}`}
                />
                <CardMedia
                  sx={{
                    height: 200,
                    width: 200,
                    objectFit: "fill",
                    alignSelf: "center",
                  }}
                  component="img"
                  image={`/${video.image}`}
                  alt={video.title}
                />
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {video.content.slice(0, 100) + "..."}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  component={NavLink}
                  to={`/app/tutor/${video.id}`}
                >
                  Watch Video
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

//   "id": "cbIC0G3FVWE",
//   "title": "Enjaz Preparation in 150 Seconds",
//   "image": "mofaLogo.jpg",
//   "url": "https://www.youtube.com/watch?v=cbIC0G3FVWE",
//   "content": "This video shows how to prepare Enjaz for embassy."
// },
