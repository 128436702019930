import { useEffect, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ClientTypes, UserTypes } from "../../features/auth/types/authType";
import { Company } from "../../features/setups/types/setupTypes";
import { selectAuth } from "../../features/auth/authSlice";
import { selectSetups } from "../../features/setups/setupSlices";
import { fetchCompanies } from "../../features/setups/setupSlices";

interface Props {
  setCompany: any;
  companyType: ClientTypes;
  companyId?: string | undefined;
  includeAgency?: boolean | undefined;
  includeAll?: boolean | undefined;
}
export const CompanyFilter = ({
  setCompany,
  companyType,
  companyId,
  includeAgency,
  includeAll,
}: Props) => {
  const dispatch = useAppDispatch();

  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [companies, setCompanies] = useState<Company[]>([]);
  const { user, loading: loadingForeignAgent } = useAppSelector(selectAuth);
  const {
    companiesWithCount: { companies: allCompanies },
    loading: companiesLoading,
  } = useAppSelector(selectSetups);

  useEffect(() => {
    if (user) {
      if (user.userType === UserTypes.Super) {
        dispatch(
          fetchCompanies({ type: ClientTypes.ForeignAgent, skip: 0, take: 500 })
        );
      } else if (user.company && user.company.partners) {
        if (includeAgency)
          setCompanies([...user.company.partners, user.company]);
        else if (includeAll)
          setCompanies([
            { _id: "-1", displayName: "All", email: "all@enjazplus.com" },
            ...user.company.partners,
          ]);
        else setCompanies(user.company.partners);
      }
    }
  }, [dispatch, companyType, user]);

  useEffect(() => {
    if (allCompanies.length > 0) {
      setCompanies([
        { _id: "-1", displayName: "All", email: "all@enjazplus.com" },
        ...allCompanies,
      ]);
    }
  }, [allCompanies]);

  useEffect(() => {
    if (companies?.length > 0) {
      const defaultCompany =
        companyId && companyId.length > 0
          ? (companies.find((c) => c._id === companyId) as Company)
          : (companies[0] as Company);

      setCompany(defaultCompany);
      setSelectedCompany(defaultCompany);
    }
  }, [companies, setCompany, companyId]);
  return (
    <>
      {loadingForeignAgent === "pending" ||
      companiesLoading === "pending" ||
      companies.length < 1 ? (
        <LinearProgress color="secondary" />
      ) : (
        // Search and Link your partner + " " + option.email
        <Autocomplete
          id="companyId"
          options={companies}
          value={selectedCompany}
          getOptionLabel={(option) => option.displayName}
          sx={{ mt: 1 }}
          defaultValue={companies[0]}
          loading={loadingForeignAgent !== "idle" ? true : false}
          onChange={(e, value) => {
            setSelectedCompany(value as Company);
            setCompany(value as Company);
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              label="Company"
              name="companyId"
              {...params}
              variant="outlined"
            />
          )}
        />
      )}
    </>
  );
};
