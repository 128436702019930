import {
  Document,
  Page,
  StyleSheet,
  Font,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";

import { ReportProps } from "./types";
import {
  Employee,
  EmployeeStatus,
} from "../../features/process/types/processTypes";
import {
  getAmharicCalendar,
  getAmharicCalendarFormatted,
} from "../../utils/calendarUtility";
import { AuthUser } from "../../features/auth/types/authType";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

Font.register({
  family: "Noto",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Naskh_Arabic/NotoNaskhArabic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Naskh_Arabic/static/NotoNaskhArabic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Ethiopic",
  format: "truetype",
  fonts: [
    {
      src: "/fonts/Noto_Sans_Ethiopic/NotoSansEthiopic-VariableFont_wght.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Regular.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Medium.ttf",
    },
    {
      src: "/fonts/Noto_Sans_Ethiopic/static/NotoSansEthiopic-Bold.ttf",
      fontWeight: 600,
    },
  ],
});
Font.register({
  family: "Open Sans",
  format: "truetype",
  fonts: [
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      src: "/fonts/open-sans-regular.ttf",
    },
    {
      // src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      src: "/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  row2: {
    flexDirection: "row",
    alignItems: "center",
    height: 30,
  },
  row21: {
    borderColor: "#000000",
    borderWidth: 2,
    alignItems: "center",
    marginRight: -1,
    borderRightWidth: 0,
  },
  row31: {
    width: "25%",
    borderColor: "#000000",
    borderWidth: 1,
    alignItems: "center",
    marginRight: -1,
    borderTopWidth: 0,
  },
  row3: {
    flexDirection: "row",
    height: 25,
  },
  cellLeft: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "12px",
  },
  headerCenter: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  header: {
    position: "absolute",
    top: 20,
    left: 0,
    right: 0,
    height: "150px",
    marginBottom: 20,
  },
  dateHeader: {
    textAlign: "left",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    color: "#008000",
    fontFamily: "Ethiopic",
    fontSize: "14px",
  },
  header2: {
    textAlign: "center",
    margin: 0,
    padding: 0,
    fontWeight: "bold",
    color: "#008000",
    fontFamily: "Ethiopic",
    fontSize: "16px",
    borderBottomWidth: 2,
    borderColor: "black",
  },
  footer: {
    textAlign: "center",
    height: "25px",
    display: "flex",
    fontFamily: "Ethiopic",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#000",
    backgroundColor: "#FFA500",
    marginTop: 20,
  },
});

// 2a668d c5dceb
export interface Props {
  employee: Employee;
  user: AuthUser;
}
export const ContractTranslation = ({ employee, user }: Props) => {
  const { t: te } = useTranslation("enums");
  const company = user && user.company ? user.company : null;
  //const address = company && company.address ? company.address : null;
  const agency = employee.owner;
  const employeeProspect =
    employee && employee.prospects && employee.prospects[0]
      ? employee.prospects[0]
      : null;
  let agent = company && company.partners ? company.partners[0] : null;
  if (employeeProspect) agent = employeeProspect;
  if (
    employee &&
    employee.address &&
    employee.additionalData &&
    employee.contactPerson &&
    employee.contactPerson.address &&
    employee.visa &&
    employee.visa.address &&
    employee.passportNumber &&
    employee.passportNumber.length > 0 &&
    agency &&
    agent
  ) {
    const empFullName = employee.givenName + " " + employee.surName;
    return (
      <Document
        creator="pinnasofts"
        author="pinnaSofts"
        title="EnjazPlus"
        subject="Contract Translation"
      >
        <Page size="LETTER" style={styles.page}>
          <View style={styles.header} fixed>
            <Image
              source={
                user && user.company && user.company?.header
                  ? user.company?.header
                  : "/noHeader.png"
              }
            />
          </View>

          <View style={{ marginTop: 120, backgroundColor: "#008000" }} key="2">
            <Text
              style={[
                styles.dateHeader,
                {
                  maxLines: 3,
                  color: "#fff",
                  textAlign: "center",
                  fontSize: "16px",
                },
              ]}
            >
              {`በሳውዲ አረቢያ ለቤት ውስጥ ሰራተኞች የተዘጋጀ መደበኛ የስራ ስምሪት ውል (ትርጉም)`}
            </Text>
          </View>

          <View key="1">
            <Text
              style={[styles.dateHeader, { color: "#008000" }]}
            >{`ኮንትራት ቁጥር #${employee.visa.contractNumber}`}</Text>
          </View>

          <View style={{ height: 20, marginTop: 10 }} key="-10">
            <Text
              style={[styles.cellLeft, { maxLines: 3 }]}
            >{`ይህ የቅጥር ውል የሚፈጸመው በሚከተሉት እና መካከል ነው፡-`}</Text>
          </View>

          <View
            style={{
              height: 20,
              marginTop: 5,
              borderBottomWidth: 2,
              borderColor: "#008000",
              backgroundColor: "#008000",
              paddingHorizontal: 3,
            }}
            key="-20"
          >
            <Text style={[styles.cellLeft, { color: "#fff" }]}>{`ሀ. አሰሪ`}</Text>
          </View>
          <View style={[styles.row3]} key="20">
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={styles.cellLeft}>{`ስም: ${
                employee.visa.fullNameAmharic &&
                employee.visa.fullNameAmharic.length > 0
                  ? employee.visa.fullNameAmharic
                  : employee.visa.fullName
              }`}</Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={styles.cellLeft}
              >{`አይዲ ቁጥር: ${employee.visa.passportNumber}`}</Text>
            </View>
          </View>
          <View style={[styles.row3]} key="220">
            <View style={{ width: "50%" }}>
              <Text
                style={styles.cellLeft}
              >{`ስልክ ቁጥር: ${employee.visa.address.telephone}`}</Text>
            </View>
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={styles.cellLeft}>{`ከተማ: ${
                employee.visa.cityAmharic &&
                employee.visa.cityAmharic.length > 0
                  ? employee.visa.cityAmharic
                  : employee.visa.address.city
              }`}</Text>
            </View>
          </View>
          <View
            style={{
              height: 20,
              backgroundColor: "#FFA500",
              paddingHorizontal: 3,
            }}
            key="-49"
          >
            <Text
              style={[styles.cellLeft]}
            >{`በሳኡዲ አረቢያ አሰሪውን የወከለው የሳኡዲ የስራ ቅጥር ኤጀንሲ`}</Text>
          </View>

          <View style={[styles.row3]} key="21">
            <View
              style={{
                width: "70%",
              }}
            >
              <Text style={styles.cellLeft}>{`ስም: ${agent.displayName}`}</Text>
            </View>
            <View style={{ width: "30%" }}>
              <Text style={styles.cellLeft}>{`ስልክ ቁጥር:`}</Text>
            </View>
          </View>

          <View
            style={{
              height: 20,
              borderBottomWidth: 2,
              borderColor: "#008000",
              backgroundColor: "#008000",
              paddingHorizontal: 3,
            }}
            key="30"
          >
            <Text
              style={[styles.cellLeft, { color: "#fff" }]}
            >{`ለ: የቤት ውስጥ ሠራተኛ`}</Text>
          </View>
          <View style={[styles.row3]} key="40">
            <View
              style={{
                width: "50%",
              }}
            >
              <Text style={styles.cellLeft}>{`ስም: ${
                employee.additionalData.fullNameAmharic &&
                employee.additionalData.fullNameAmharic.length > 0
                  ? employee.additionalData.fullNameAmharic
                  : empFullName
              }`}</Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text
                style={styles.cellLeft}
              >{`የስራ ዘርፍ: ${employee.additionalData.appliedProfession?.toUpperCase()}`}</Text>
            </View>
          </View>

          <View style={[styles.row3]} key="55">
            <View style={{ width: "30%" }}>
              <Text style={styles.cellLeft}>{`ስልክ ቁጥር: ${
                employee.address.telephone ? employee.address.telephone : ""
              }`}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text style={styles.cellLeft}>{`አድራሻ: ${
                employee.address.streetAddress
                  ? employee.address.streetAddress
                  : ""
              }`}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text
                style={styles.cellLeft}
              >{`የጋብቻ ሁኔታ: ${employee.additionalData.maritalStatus?.toUpperCase()}`}</Text>
            </View>
          </View>

          <View style={[styles.row3]} key="33">
            <View style={{ width: "30%" }}>
              <Text
                style={styles.cellLeft}
              >{`ፓስፖርት ቁጥር: ${employee.passportNumber}`}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text style={styles.cellLeft}>{`የተሰጠበት ቦታ: አዲስ አበባ`}</Text>
            </View>
            <View style={{ width: "35%" }}>
              <Text style={styles.cellLeft}>{`የተሰጠበት ቀን: ${format(
                new Date(employee.passportIssueDate as Date),
                "dd-MMM-yyyy"
              ).toUpperCase()}`}</Text>
            </View>
          </View>

          <View style={[styles.row3]} key="44">
            <View style={{ width: "50%" }}>
              <Text
                style={styles.cellLeft}
              >{`ተያዥ ስም: ${employee.contactPerson.displayName}`}</Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={styles.cellLeft}
              >{`ግንኙነት: ${employee.contactPerson.kinship}`}</Text>
            </View>
            <View style={{ width: "25%" }}></View>
          </View>
          <View style={[styles.row3]} key="444">
            <View style={{ width: "50%" }}>
              <Text style={styles.cellLeft}>{`ስልክ ቁጥር: ${
                employee.contactPerson.address.telephone
                  ? employee.contactPerson.address.telephone
                  : ""
              }`}</Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text style={styles.cellLeft}>{`አድራሻ: ${
                employee.contactPerson.address.streetAddress
                  ? employee.contactPerson.address.streetAddress
                  : ""
              }`}</Text>
            </View>
            <View style={{ width: "25%" }}></View>
          </View>

          <View
            style={{
              height: 20,
              backgroundColor: "#FFA500",
              paddingHorizontal: 3,
            }}
            key="-47"
          >
            <Text
              style={[styles.cellLeft]}
            >{`ሰራተኛው(ዋ)ን በሀገሩ(ሯ) የወከለው የስራ ቅጥር ኤጀንሲ`}</Text>
          </View>

          <View style={[styles.row3]} key="201">
            <View
              style={{
                width: "90%",
              }}
            >
              <Text style={styles.cellLeft}>{`ስም: ${
                company && company.nameAmharic && company.nameAmharic.length > 0
                  ? company.nameAmharic
                  : agency.displayName
              }`}</Text>
            </View>
            <View style={{ width: "10%" }}>
              {/* <Text style={styles.cellLeft}>{`ፍቃድ ቁጥር:`}</Text> */}
            </View>
          </View>
          <View style={[styles.row3]} key="22">
            <View
              style={{
                width: "30%",
              }}
            >
              <Text style={styles.cellLeft}>{`ስልክ ቁጥር: ${
                agency.address ? agency.address.telephone : ""
              }`}</Text>
            </View>
            <View style={{ width: "20%" }}>
              <Text style={styles.cellLeft}>{`ከተማ: አዲስ አበባ`}</Text>
            </View>
            <View style={{ width: "50%" }}>
              <Text style={styles.cellLeft}>{`ኢሜል: ${agency.email}`}</Text>
            </View>
          </View>

          <View
            style={{
              height: 20,
              borderBottomWidth: 2,
              borderColor: "#008000",
              backgroundColor: "#008000",
              paddingHorizontal: 3,
            }}
            key="-30"
          >
            <Text
              style={[styles.cellLeft, { color: "#fff" }]}
            >{`አሰሪው እና የቤት ውስጥ ሠራተኛዋ(ው) በፈቃደኝነት ከሚከተሉት ውሎች እና ሁኔታዎች ጋር ተያይዘዋል።`}</Text>
          </View>
          <View style={{ height: 20 }} key="-40">
            <Text style={[styles.cellLeft]}>{`1. የስራ ከተማ: ${
              employee.visa.cityAmharic && employee.visa.cityAmharic.length > 0
                ? employee.visa.cityAmharic
                : employee.visa.address.city
            }`}</Text>
          </View>

          <View style={{ height: 40 }} key="-50">
            <Text
              style={[styles.cellLeft, { maxLines: 3 }]}
            >{`2. የኮንትራት ቆይታ: ኮንትራቱ የቤት ውስጥ ሠራተኛዋ(ው) ሳውዲ አረቢያ ከደረሰ(ች)በት ቀን ጀምሮ ለሁለት ዓመታት የሚቆይ ይሆናል። ወርሃዊ ደሞዝ ከዚህ ቀን ጀምሮ ለክፍያ ይሰላል`}</Text>
          </View>
          <View style={{ height: 90 }} key="-60">
            <Text
              style={[styles.cellLeft, { maxLines: 5 }]}
            >{`3. [ከቤት ሰራተኞች ጋር የተያያዘ]: እኔ ከላይ የተጠቀስኩት እጩ በሳውዲ አረቢያ ኪንግደም ለመስራት ባለኝ ሙሉ አቅምና ካለኝ ፍላጎት በመነሳት በሳውዲ አረቢያ መንግስት ውስጥ ላሉ ባለስልጣናት እና ሁሉም ተዛማጅ አካላት፤የሙሳነድ ፕሮግራም እና/ወይም ኦፕሬተር ኩባንያውን ጨምሮ እና ለሌችም የግል መረጃዬን ለማካፈል በሙሉ ህጋዊ አቅሜ መቀበሌንና መስማማቴን አረጋግጣለው፤፤ በተጨማሪም የግል መረጃዬን የሙሳነድ ፕሮግራም እና/ወይም ኦፕሬተሮቹ እንዲጠቀሙት እና እንዲያቀነባብሩት እና ለማንኛውም ሶስተኛ ፓርቲዎች እንዲያጋሩት ፍቃደኛ ነኝ።`}</Text>
          </View>
          <View style={{ height: 40 }} key="-70">
            <Text
              style={[styles.cellLeft, { maxLines: 3 }]}
            >{`4. በሁለቱም አገሮች ውስጥ ባሉት ደንቦች መሠረት የቤት ውስጥ ሠራተኛዋ(ው) እና አሰሪው በወር 1,000(አንድ ሺህ) የሳዑዲ አረቢያ ሪያል ወርሃዊ ደሞዝ ተስማምተዋል።`}</Text>
          </View>
          <View style={styles.footer}>
            <Text>{`${agency.address ? agency.address.telephone : ""} | ${
              agency.email
            }`}</Text>
          </View>
        </Page>
      </Document>
    );
  } else {
    return (
      <Document
        creator="pinnasofts"
        author="pinnaSofts"
        title="EnjazPlus"
        subject="Contract Translation"
      >
        <Page size="LETTER" style={styles.page}>
          <View
            style={{
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "20px",
              marginTop: 30,
            }}
          >
            <Text>{`Please fill all the required Data `}</Text>
          </View>
        </Page>
      </Document>
    );
  }
};
