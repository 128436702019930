import Grid from "@mui/material/Grid";
import { Box, Stack, Typography } from "@mui/material";
import { LabelTemplate } from "./styles/table";
import { Employee } from "../types/processTypes";

import {
  BasicPostApplied,
  BasicPostAppliedSingleValue,
} from "./components/basic/BasicPostApplied";
import { BasicDetail } from "./components/basic/BasicDetail";
import {
  BasicLanguage,
  BasicLanguageDetail,
} from "./components/basic/BasicLanguage";
import { BasicExperience } from "./components/basic/BasicExperience";
import {
  BasicSkillsOneLineHorz,
  BasicSkillsTwoRows,
} from "./components/basic/BasicSkills";
import { BasicPassport } from "./components/basic/BasicPassport";
import {
  BasicFullName,
  BasicFullNameHeader,
} from "./components/basic/BasicFullName";
import { BasicFace } from "./components/basic/BasicFace";
import { BasicStand } from "./components/basic/BasicStand";
import { BasicTitleSingleLang } from "./components/basic/BasicTitle";
import { BasicPassportScan } from "./components/basic/BasicPassportScan";
import { BasicHeader } from "./components/basic/BasicHeader";
import { Company } from "../../setups/types/setupTypes";
import { BasicLocation } from "./components/basic/BasicLocation";
import {
  BasicFlag,
  BasicFlagHorz,
  BasicFlagVert,
} from "./components/basic/BasicFlag";
import { BasicHeightWeightHorz } from "./components/basic/BasicHeightWeight";
import {
  BasicReference,
  BasicReferenceDateOnly,
  BasicReferenceDateOnlyHeader,
  BasicReferenceVer,
} from "./components/basic/BasicReference";
import { BasicRemarks, BasicRemarksVer } from "./components/basic/BasicRemarks";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  backgroundColor: string;
  cvTemplate: LabelTemplate;
}
//bg:9cb7d8 becfe5 bdcee4
//Logofont:00275f
function CadresTemplate({
  selectedCompany,
  currentEmployee,
  backgroundColor,
  cvTemplate,
}: Props) {
  return (
    <>
      <Box id="personalData" sx={{ p: 1 }}>
        <Grid
          container
          spacing={cvTemplate.spacing}
          sx={{
            p: 1,
            backgroundColor: backgroundColor,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "90%",
              mb: 1,
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={cvTemplate.spacing}
            justifyContent="center"
            alignItems="center"
          >
            <Grid container item xs={12}>
              <Stack direction="row" sx={{ width: "100%" }}>
                {cvTemplate && currentEmployee && (
                  <>
                    <BasicFullNameHeader
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />
                    <BasicReferenceDateOnlyHeader
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />
                  </>
                )}
              </Stack>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={3}>
                {cvTemplate && currentEmployee && (
                  <BasicFace
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                )}
              </Grid>
              <Grid item xs={9}>
                {cvTemplate && currentEmployee && (
                  <>
                    <BasicPostApplied
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                      showHeader={false}
                      showProfession={true}
                      showSalary={true}
                      showContractPeriod={true}
                    />
                    <BasicFlagHorz
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />
                    <BasicRemarks
                      currentEmployee={currentEmployee}
                      template={cvTemplate}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Stack
              spacing={cvTemplate.spacing}
              alignItems="center"
              sx={{
                width: "100%",
                borderRadius: cvTemplate.borderRadius,
                borderColor: cvTemplate.borderColor,
                borderWidth: cvTemplate.borderWidth,
                borderStyle: cvTemplate.borderStyle,
                p: 1,
              }}
            >
              {cvTemplate && currentEmployee && (
                <>
                  <BasicHeightWeightHorz
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                    showComplexion={true}
                  />
                  <BasicStand
                    currentEmployee={currentEmployee}
                    template={cvTemplate}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <Grid item md={6} xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicPassport
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showPassportNumber={true}
                showIssueDate={true}
                showPlaceOfIssue={true}
                showExpiryDate={true}
              />
            )}
            {cvTemplate && currentEmployee && (
              <BasicDetail
                currentEmployee={currentEmployee}
                template={cvTemplate}
                showHeader={true}
                showNationality={true}
                showReligion={true}
                showDateOfBirth={true}
                showAge={true}
                showPlaceOfBirth={true}
                showMaritalStatus={true}
                showNumberOfChildren={true}
                showWeightHeight={false}
                showComplexion={false}
                showEducation={false}
                showLivingTown={false}
                showEmployeeTelephone={false}
                showContactName={false}
                showContactTelephone={false}
              />
            )}
            {cvTemplate && currentEmployee && (
              <>
                <BasicLanguage
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  showEducation={true}
                />
                <BasicExperience
                  currentEmployee={currentEmployee}
                  template={cvTemplate}
                  type="basic"
                />
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {cvTemplate && currentEmployee && (
              <BasicSkillsTwoRows
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Stack
        id="passportData"
        sx={{
          p: 1,
          pt: 1,
          backgroundColor: backgroundColor,
          minHeight: "1500px",
        }}
      >
        {cvTemplate.addPassportHeader && (
          <Box
            sx={{
              height: "90%",
            }}
          >
            {cvTemplate && currentEmployee && (
              <BasicHeader
                selectedCompany={selectedCompany}
                currentEmployee={currentEmployee}
                template={cvTemplate}
              />
            )}
          </Box>
        )}

        {cvTemplate && currentEmployee && (
          <Stack
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              height: "90%",
            }}
          >
            <BasicLocation
              type="location"
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Stack>
        )}

        {cvTemplate && currentEmployee && (
          <Box
            sx={{
              mt: 5,
            }}
          >
            <BasicPassportScan
              currentEmployee={currentEmployee}
              template={cvTemplate}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default CadresTemplate;
