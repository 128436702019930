import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectSetups, removeUser } from "./setupSlices";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import { NavLink as RouterLink } from "react-router-dom";

import {
  changePageTitle,
  selectPreference,
} from "../preferences/preferencesSlice";
//import Avatar from "@mui/material/Avatar";
import {
  IconButton,
  Stack,
  Button,
  Typography,
  Tooltip,
  MenuItem,
  Box,
} from "@mui/material";
import { Add, Edit, Save, Delete, VerifiedUser } from "@mui/icons-material";

import Accordion from "@mui/material/Accordion";
import { StyledAccordionSummary } from "../../styles/componentStyled";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Formik, FormikProps } from "formik";
import { FormikTextField } from "../../components/Layout/FormikTextField";
import { StyledTableCell, StyledTableRow } from "../../styles/tableStyles";
import TableSkeleton from "../../components/Layout/TableSkeleton";
import { createCompanySchema } from "./validation";
import Paging from "../../components/Layout/Paging";
import { Company } from "./types/setupTypes";
import { addCompany, fetchCompanies } from "./setupSlices";
import { Users } from "./users/Users";
import Toast from "../../components/Layout/Toast";
import { ClientTypes } from "../auth/types/authType";

const defaultCompany: Company = {
  email: "",
  displayName: "",
};

export const Companies = () => {
  const [companyId, setCompanyId] = useState<string>("");
  const [expanded, setExpanded] = useState(false);
  const [selectedCompany, setSelectedAgency] = useState(defaultCompany);

  const dispatch = useAppDispatch();
  const {
    companiesWithCount: { companies, totalCount: totalCompanies },
    success,
    error,
    loading,
  } = useAppSelector(selectSetups);
  const { searchText } = useAppSelector(selectPreference);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    dispatch(changePageTitle("Companies List"));

    const skipRows = currentPage * rowsPerPage;

    dispatch(
      fetchCompanies({
        searchText,
        skip: skipRows,
        take: rowsPerPage,
      })
    );
  }, [dispatch, searchText, currentPage, rowsPerPage]); //

  useEffect(() => {
    setTotal(totalCompanies as number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCompanies]);

  const ToggleAccordion = () => {
    setExpanded(!expanded);
  };

  const DeleteUser = (id: string) => {
    dispatch(removeUser(id));
  };
  const ViewUser = (id: string) => {
    setCompanyId(id);
    setExpanded(true);
  };

  const ResetFields = () => {
    setSelectedAgency(defaultCompany);
    setExpanded(true);
  };

  // const RefreshList = () => {
  //   const skipRows = currentPage * rowsPerPage;
  //   dispatch(
  //     fetchCompanies({
  //       refreshList: "refresh",
  //       searchText,
  //       skip: skipRows,
  //       take: rowsPerPage,
  //     })
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>Companies | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          m: { xs: 0, md: 1 },
          px: { xs: 0, md: 2 },
        }}
      >
        <Formik
          enableReinitialize={true}
          initialValues={selectedCompany as Company}
          validationSchema={createCompanySchema}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            // if (user && user.client) {
            //   values = { ...values, clientId: user.client.id as number };
            // }
            dispatch(addCompany(values));
          }}
        >
          {(props: FormikProps<Company>) => (
            <Form>
              <Stack
                direction="row"
                justifyContent="space-between"
                justifyItems="center"
              >
                <Stack direction="row" spacing={1}>
                  <Tooltip title="Save Company">
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!props.isValid}
                    >
                      <Save />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Add New Company">
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={ResetFields}
                    >
                      <Add />
                    </Button>
                  </Tooltip>
                </Stack>
              </Stack>
              <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item md={3} xs={12}>
                  <FormikTextField formikKey="type" select label="Type">
                    {Object.keys(ClientTypes).map((option) => (
                      <MenuItem key={option} value={option as string}>
                        {option}
                      </MenuItem>
                    ))}
                  </FormikTextField>
                </Grid>

                <Grid item md={6} xs={12}>
                  <FormikTextField formikKey="displayName" label="Name" />
                </Grid>
                <Grid item md={3} xs={12}>
                  <FormikTextField formikKey="email" label="Email" />
                </Grid>
              </Grid>

              {success && <Toast severity="success">{success.message}</Toast>}
              {error && <Toast severity="error">{error.message}</Toast>}
            </Form>
          )}
        </Formik>

        <Grid container justifyContent="flex-start" sx={{ mt: 1 }}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Email</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {loading === "pending" ? (
                  <TableSkeleton numRows={5} numColumns={5} />
                ) : (
                  companies &&
                  companies.map((row, index) => (
                    <StyledTableRow key={row._id}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell>{row.displayName}</StyledTableCell>
                      <StyledTableCell>{row.enjazPlusLicense}</StyledTableCell>
                      <StyledTableCell>{row.type}</StyledTableCell>
                      <StyledTableCell>{row.email}</StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <IconButton
                            color="primary"
                            component={RouterLink}
                            to={"/app/cp/" + row._id + "/a"}
                            size="large"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => DeleteUser(row._id as string)}
                            size="large"
                          >
                            <Delete />
                          </IconButton>
                          <IconButton
                            color="secondary"
                            onClick={() => ViewUser(row._id as string)}
                            size="large"
                          >
                            <VerifiedUser />
                          </IconButton>
                        </Stack>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Stack spacing={1}>
          <Paging
            total={total}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
          />
          <Typography variant="h6" color="textPrimary" component="div">
            Number of Companies: {total}
          </Typography>
        </Stack>

        <Accordion sx={{ mt: 1 }} expanded={expanded}>
          <StyledAccordionSummary
            onClick={ToggleAccordion}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Users</Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            {companyId.length > 0 && (
              <Users companyId={companyId.length > 0 ? companyId : undefined} />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};
