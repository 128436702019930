import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import QRCodeStyling from "qr-code-styling";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getCompany, selectSetups } from "../../../../setups/setupSlices";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
  type: string;
}
export const BasicLocation = ({ currentEmployee, template, type }: Props) => {
  const dispatch = useAppDispatch();

  const { selectedCompany } = useAppSelector(selectSetups);

  useEffect(() => {
    if (currentEmployee) {
      if (
        currentEmployee.prospects &&
        currentEmployee.prospects[0] &&
        currentEmployee.prospects[0]._id
      ) {
        dispatch(getCompany(currentEmployee.prospects[0]._id));
      } else if (currentEmployee.owner && currentEmployee.owner._id) {
        dispatch(getCompany(currentEmployee.owner._id));
      }
    }
  }, [dispatch, currentEmployee]);

  useEffect(() => {
    if (currentEmployee._id && template.bgColor && template.headerGradient) {
      const qrCanvas = document.getElementById(type) as HTMLElement;
      qrCanvas.innerHTML = "";
      const hg = template.headerGradient;

      let qrWidth = 240;
      if (template.qrLocationWidth && template.qrMoreCvsWidth)
        qrWidth =
          type === "location"
            ? template.qrLocationWidth
            : template.qrMoreCvsWidth;

      let qrHeight = 240;
      if (template.qrLocationHeight && template.qrMoreCvsHeight)
        qrHeight =
          type === "location"
            ? template.qrLocationHeight
            : template.qrMoreCvsHeight;

      let qrMargin = 0;
      if (template.qrLocationMargin && template.qrMoreCvsMargin)
        qrMargin =
          type === "location"
            ? template.qrLocationMargin
            : template.qrMoreCvsMargin;
      let qrLink = "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8";
      if (template.qrLocationLink && template.qrMoreCvsLink)
        qrLink =
          type === "location"
            ? template.qrLocationLink
            : template.qrMoreCvsLink;
      let qrImageUrl = "/googleMaps.png";
      if (template.qrLocationImageUrl && template.qrMoreCvsImageUrl)
        qrImageUrl =
          type === "location"
            ? template.qrLocationImageUrl
            : template.qrMoreCvsImageUrl;
      const qrCode = new QRCodeStyling({
        width: qrWidth,
        height: qrHeight,
        type: "svg",
        shape: "square",
        margin: qrMargin,
        data: qrLink,
        image: qrImageUrl,
        dotsOptions: {
          type: "classy-rounded",
          color: "#fffff",
          gradient: {
            type: "radial",
            rotation: 0,
            colorStops: [
              { offset: 0, color: hg.firstColor },
              { offset: 1, color: hg.secondColor },
            ],
          },
        },
        cornersSquareOptions: {
          color: hg.firstColor,
          type: "extra-rounded",
        },
        cornersDotOptions: {
          color: hg.secondColor,
        },
        backgroundOptions: {
          color: template.bgColor,
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 2,
        },
      });
      qrCode.append(qrCanvas);
    }
  }, [currentEmployee, template]);
  return <Box id={type}></Box>;
};
