import React, { useState } from "react";
import YouTube, { YouTubeEvent, YouTubePlayer } from "react-youtube";
import { useParams } from "react-router";
import { TutorVideo } from "./Tutors";
import data from "./data.json";
import { Backspace } from "@mui/icons-material";
import { Box, Tooltip, Button, Typography, Stack } from "@mui/material";
import { NavLink } from "react-router-dom";

export default function TutorDetail() {
  const [player, setPlayer] = useState<YouTubePlayer>({});

  const params = useParams();
  const post = data.posts.find(
    (dataItem: TutorVideo) => dataItem.id === params.id
  ) as TutorVideo;
  const youtubeID = post.url.split("v=")[1];

  const onReady = (e: YouTubeEvent<any>) => {
    setPlayer(e.target);
  };

  const onPlayHandler = () => {
    if (player) player.playVideo();
  };
  const onPauseHandler = () => {
    if (player) player.pauseVideo();
  };
  return (
    <Box sx={{ m: 2, minHeight: "100%" }}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ backgroundColor: "background.default" }}
      >
        <Tooltip title="Back to Tutor list" sx={{ mb: 1 }}>
          <Button
            color="secondary"
            variant="contained"
            component={NavLink}
            to={"/app/tutors"}
          >
            <Backspace />
          </Button>
        </Tooltip>
        <Typography variant="h5" component="div">
          {post.title}
        </Typography>
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ alignSelf: "flex-end" }}
        >
          {post.content}
        </Typography>
        {/* <Box sx={{ backgroundColor: "background.default" }}>
      </Box> */}
      </Stack>
      <Box sx={{ mt: 1, px: 4 }}>
        <YouTube
          videoId={youtubeID}
          onReady={onReady}
          iframeClassName={"iframeClassName"}
          opts={{
            playerVars: {
              autoplay: 1,
            },
          }}
        />
      </Box>
      {/* <button onClick={onPlayHandler} className="btn">
        Play
      </button>
      <button onClick={onPauseHandler} className="btn">
        Pause
      </button> */}
    </Box>
  );
}
