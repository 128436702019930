import { useRef } from "react";
import * as htmlToImage from "html-to-image";
import { toCanvas } from "html-to-image";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  fetchEmployees,
  linkedEmployees,
  selectProcesses,
  setSelectedEmployee,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import Grid from "@mui/material/Grid";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  Tooltip,
  useTheme,
  Stack,
  Box,
  Typography,
  IconButton,
  Fab,
} from "@mui/material";
import { GradientTemplate, LabelTemplate } from "./styles/table";
import Image from "mui-image";
import {
  ArrowBack,
  ArrowForward,
  Download,
  Edit,
  List,
  Share,
} from "@mui/icons-material";
import { Employee, EmployeeStatus, ShortLink } from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";

import CustomDialog from "../../../components/modals/CustomDialog";
import { selectAuth } from "../../auth/authSlice";
import InterTemplate from "./InterTemplate";
import BasicTemplate from "./BasicTemplate";
import { CvMakerPremium } from "./CvMakerPremium";
import jsPDF from "jspdf";
import { CvTemplate } from "../../setups/types/setupTypes";
import { getCompany, selectSetups } from "../../setups/setupSlices";
import { isPrivilegedTransaction } from "../../../utils/authUtils";
import { Role } from "../../auth/types/authType";
import EntranceTemplate from "./EntranceTemplate";
import AkeemTemplate from "./AkeemTemplate";
import CadresTemplate from "./CadresTemplate";
import BasicTwoTemplate from "./BasicTwoTemplate";
import BasicThreeTemplate from "./BasicThreeTemplate";
import OxagonTwoTemplate from "./OxagonTwoTemplate";
import MaledaTemplate from "./MaledaTemplate";
import SanadTemplate from "./SanadTemplate";
import SkillStarTemplate from "./SkillStarTemplate";

function PremiumTemplateMaker() {
  const { agencyId, agentId } = useParams() as {
    agencyId: string;
    agentId: string;
  };
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { searchText } = useAppSelector(selectPreference);
  const [open, setOpen] = useState(false);
  const [shortLink, setShortLink] = useState<ShortLink>({});
  const [isAgentTemplate, setIsAgentTemplate] = useState<boolean>(false);
  const { user } = useAppSelector(selectAuth);

  const [name, setName] = useState("basic");
  const [numberOfPages, setNumberOfPages] = useState(2);

  const [cvLang, setCvLang] = useState("en");
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");

  const [headFontColor, setHeadFontColor] = useState("#ffffff");
  const [headBgColor, setHeadBgColor] = useState(theme.palette.primary.main);
  const [labelFontColor, setLabelFontColor] = useState(
    theme.palette.primary.main
  );
  const [labelBgColor, setLabelBgColor] = useState("#ffffff");
  const [valueFontColor, setValueFontColor] = useState(
    theme.palette.secondary.main
  );
  const [valueBgColor, setValueBgColor] = useState("#ffffff");

  const [borderRadius, setBorderRadius] = useState("5px");
  const [borderStyle, setBorderStyle] = useState("solid");
  const [borderColor, setBorderColor] = useState("#ffffff");
  const [borderWidth, setBorderWidth] = useState(1);

  const [spacing, setSpacing] = useState(1);
  const [labelFontSize, setLabelFontSize] = useState(16);
  const [valueFontSize, setValueFontSize] = useState(18);
  const [labelFontWeight, setLabelFontWeight] = useState(900);
  const [valueFontWeight, setValueFontWeight] = useState(900);

  const [textTransform, setTextTransform] = useState("uppercase");
  const [headerHeight, setHeaderHeight] = useState(48);
  const [bodyHeight, setBodyHeight] = useState(36);

  const [addPassportHeader, setAddPassportHeader] = useState(false);
  const [useHeaderGradient, setUseHeaderGradient] = useState(true);
  const [useBodyGradient, setUseBodyGradient] = useState(false);

  const [headerShadow, setHeaderShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [bodyShadow, setBodyShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [photoShadow, setPhotoShadow] = useState("5px 5px rgba(0,0,0,0.14)");
  const [passportShadow, setPassportShadow] = useState(
    "5px 5px rgba(0,0,0,0.14)"
  );

  const [qrLocationLink, setQrLocationLink] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrLocationWidth, setQrLocationWidth] = useState(150);
  const [qrLocationHeight, setQrLocationHeight] = useState(150);
  const [qrLocationImageUrl, setQrLocationImageUrl] =
    useState("/googleMaps.png");
  const [qrLocationMargin, setQrLocationMargin] = useState(0);

  const [qrMoreCvsLink, setQrMoreCvsLink] = useState(
    "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8"
  );
  const [qrMoreCvsWidth, setQrMoreCvsWidth] = useState(150);
  const [qrMoreCvsHeight, setQrMoreCvsHeight] = useState(150);
  const [qrMoreCvsImageUrl, setQrMoreCvsImageUrl] = useState("/googleMaps.png");
  const [qrMoreCvsMargin, setQrMoreCvsMargin] = useState(0);

  const [faceHeight, setFaceHeight] = useState("240px");
  const [faceWidth, setFaceWidth] = useState("100%");
  const [standHeight, setStandHeight] = useState("600px");
  const [standWidth, setStandWidth] = useState("400px");
  const [flagHeight, setFlagHeight] = useState("200px");
  const [flagWidth, setFlagWidth] = useState("100%");
  const [passportHeight, setPassportHeight] = useState("650px");
  const [passportWidth, setPassportWidth] = useState("700px");

  const [headBgColorAlpha, setHeadBgColorAlpha] = useState(1);
  const [headGrFirstColorAlpha, setHeadGrFirstColorAlpha] = useState(1);
  const [headGrSecondColorAlpha, setHeadGrSecondColorAlpha] = useState(1);
  const [labelBgColorAlpha, setLabelBgColorAlpha] = useState(1);
  const [oddGrFirstColorAlpha, setOddGrFirstColorAlpha] = useState(1);
  const [oddGrSecondColorAlpha, setOddGrSecondColorAlpha] = useState(1);
  const [valueBgColorAlpha, setValueBgColorAlpha] = useState(1);
  const [evenGrFirstColorAlpha, setEvenGrFirstColorAlpha] = useState(1);
  const [evenGrSecondColorAlpha, setEvenGrSecondColorAlpha] = useState(1);

  const [headerGradient, setHeaderGradient] = useState<GradientTemplate>({
    typeOfGradient: "radial-gradient",
    degree: "circle",
    firstColor: theme.palette.primary.main,
    firstPercentage: "0%",
    secondColor: theme.palette.secondary.main,
    secondPercentage: "100%",
  });
  const [evenGradient, setEvenGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });
  const [oddGradient, setOddGradient] = useState<GradientTemplate>({
    typeOfGradient: "linear-gradient",
    degree: "90deg",
    firstColor: "transparent",
    firstPercentage: "0%",
    secondColor: "transparent",
    secondPercentage: "100%",
  });

  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    name,
    numberOfPages,
    cvLang,
    bgColor: backgroundColor,
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    headerGradient,
    evenGradient,
    oddGradient,
    addPassportHeader,
    useHeaderGradient,
    useBodyGradient,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  });

  useEffect(() => {
    setCvTemplate({
      name,
      numberOfPages,
      cvLang,
      bgColor: backgroundColor,
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
      borderColor,
      borderRadius,
      borderWidth,
      borderStyle,
      spacing,
      labelFontSize,
      valueFontSize,
      labelFontWeight,
      valueFontWeight,
      textTransform,
      headerHeight,
      bodyHeight,
      headerGradient,
      evenGradient,
      oddGradient,
      addPassportHeader,
      useHeaderGradient,
      useBodyGradient,
      headerShadow,
      bodyShadow,
      photoShadow,
      passportShadow,
      qrLocationLink,
      qrLocationWidth,
      qrLocationHeight,
      qrLocationImageUrl,
      qrLocationMargin,
      qrMoreCvsLink,
      qrMoreCvsWidth,
      qrMoreCvsHeight,
      qrMoreCvsImageUrl,
      qrMoreCvsMargin,
      faceHeight,
      faceWidth,
      standHeight,
      standWidth,
      flagHeight,
      flagWidth,
      passportHeight,
      passportWidth,
      headBgColorAlpha,
      headGrFirstColorAlpha,
      headGrSecondColorAlpha,
      labelBgColorAlpha,
      oddGrFirstColorAlpha,
      oddGrSecondColorAlpha,
      valueBgColorAlpha,
      evenGrFirstColorAlpha,
      evenGrSecondColorAlpha,
    });
  }, [
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
    spacing,
    labelFontSize,
    valueFontSize,
    labelFontWeight,
    valueFontWeight,
    textTransform,
    headerHeight,
    bodyHeight,
    cvLang,
    headerGradient,
    evenGradient,
    oddGradient,
    useHeaderGradient,
    useBodyGradient,
    name,
    numberOfPages,
    backgroundColor,
    addPassportHeader,
    headerShadow,
    bodyShadow,
    photoShadow,
    passportShadow,
    qrLocationLink,
    qrLocationWidth,
    qrLocationHeight,
    qrLocationImageUrl,
    qrLocationMargin,
    qrMoreCvsLink,
    qrMoreCvsWidth,
    qrMoreCvsHeight,
    qrMoreCvsImageUrl,
    qrMoreCvsMargin,
    faceHeight,
    faceWidth,
    standHeight,
    standWidth,
    flagHeight,
    flagWidth,
    passportHeight,
    passportWidth,
    headBgColorAlpha,
    headGrFirstColorAlpha,
    headGrSecondColorAlpha,
    labelBgColorAlpha,
    oddGrFirstColorAlpha,
    oddGrSecondColorAlpha,
    valueBgColorAlpha,
    evenGrFirstColorAlpha,
    evenGrSecondColorAlpha,
  ]);

  const {
    selectedEmployee: currentEmployee,
    employeesWithCount: { employees },
    selectedShortLink,
    success,
    loading,
  } = useAppSelector(selectProcesses);

  const { selectedCompany } = useAppSelector(selectSetups);

  const renderCvFormat = (cv: CvTemplate) => {
    setName(cv.name);
    setNumberOfPages(cv.numberOfPages);
    setCvLang(cv.cvLang);
    setBackgroundColor(cv.bgColor);
    setHeadFontColor(cv.headFontColor);
    setHeadBgColor(cv.headBgColor);
    setLabelFontColor(cv.labelFontColor);
    setLabelBgColor(cv.labelBgColor);
    setValueFontColor(cv.valueFontColor);
    setValueBgColor(cv.valueBgColor);

    setBorderColor(cv.borderColor);
    setBorderRadius(cv.borderRadius);
    setBorderWidth(cv.borderWidth);
    setBorderStyle(cv.borderStyle);
    setSpacing(cv.spacing);
    setLabelFontSize(cv.labelFontSize);
    setValueFontSize(cv.valueFontSize);
    setLabelFontWeight(cv.labelFontWeight);
    setValueFontWeight(cv.valueFontWeight);
    setTextTransform(cv.textTransform);
    setHeaderHeight(cv.headerHeight);
    setBodyHeight(cv.bodyHeight);

    setAddPassportHeader(cv.addPassportHeader);
    setUseHeaderGradient(cv.useHeaderGradient);
    setUseBodyGradient(cv.useBodyGradient);

    setHeaderShadow(cv.headerShadow);
    setBodyShadow(cv.bodyShadow);
    setPhotoShadow(cv.photoShadow);
    setPassportShadow(cv.passportShadow);

    setQrLocationLink(cv.qrLocationLink);
    setQrLocationWidth(cv.qrLocationWidth);
    setQrLocationHeight(cv.qrLocationHeight);
    setQrLocationImageUrl(cv.qrLocationImageUrl);
    setQrLocationMargin(cv.qrLocationMargin);

    setQrMoreCvsLink(cv.qrMoreCvsLink);
    setQrMoreCvsWidth(cv.qrMoreCvsWidth);
    setQrMoreCvsHeight(cv.qrMoreCvsHeight);
    setQrMoreCvsImageUrl(cv.qrMoreCvsImageUrl);
    setQrMoreCvsMargin(cv.qrMoreCvsMargin);

    setFaceHeight(cv.faceHeight);
    setFaceWidth(cv.faceWidth);
    setStandHeight(cv.standHeight);
    setStandWidth(cv.standWidth);
    setFlagHeight(cv.flagHeight);
    setFlagWidth(cv.flagWidth);
    setPassportHeight(cv.passportHeight);
    setPassportWidth(cv.passportWidth);

    setHeadBgColorAlpha(cv.headBgColorAlpha);
    setHeadGrFirstColorAlpha(cv.headGrFirstColorAlpha);
    setHeadGrSecondColorAlpha(cv.headGrSecondColorAlpha);
    setLabelBgColorAlpha(cv.labelBgColorAlpha);
    setOddGrFirstColorAlpha(cv.oddGrFirstColorAlpha);
    setOddGrSecondColorAlpha(cv.oddGrSecondColorAlpha);
    setValueBgColorAlpha(cv.valueBgColorAlpha);
    setEvenGrFirstColorAlpha(cv.evenGrFirstColorAlpha);
    setEvenGrSecondColorAlpha(cv.evenGrSecondColorAlpha);

    const headerGr = cv.headerGradient.split("_");
    if (headerGr.length === 6) {
      setHeaderGradient({
        typeOfGradient: headerGr[0],
        degree: headerGr[1],
        firstColor: headerGr[2],
        firstPercentage: headerGr[3],
        secondColor: headerGr[4],
        secondPercentage: headerGr[5],
      });
    }

    const evenGr = cv.evenGradient.split("_");
    if (evenGr.length === 6) {
      setEvenGradient({
        typeOfGradient: evenGr[0],
        degree: evenGr[1],
        firstColor: evenGr[2],
        firstPercentage: evenGr[3],
        secondColor: evenGr[4],
        secondPercentage: evenGr[5],
      });
    }
    const oddGr = cv.oddGradient.split("_");
    if (oddGr.length === 6) {
      setOddGradient({
        typeOfGradient: oddGr[0],
        degree: oddGr[1],
        firstColor: oddGr[2],
        firstPercentage: oddGr[3],
        secondColor: oddGr[4],
        secondPercentage: oddGr[5],
      });
    }
    // const templateColors = remarks.split(",");
    // //console.log(templateColors);
    // if (templateColors.length > 0) {
    //   setBackgroundColor(templateColors[0]);
    //   setHeadFontColor(templateColors[1]);
    //   setHeadBgColor(templateColors[2]);
    //   setLabelFontColor(templateColors[3]);
    //   setLabelBgColor(templateColors[4]);
    //   setValueFontColor(templateColors[5]);
    //   setValueBgColor(templateColors[6]);
    // }
    // if (templateColors.length > 7) {
    //   setBorderColor(templateColors[7]);
    //   setBorderRadius(templateColors[8]);
    //   setBorderWidth(parseInt(templateColors[9]));
    //   setBorderStyle(templateColors[10]);
    //   setSpacing(parseInt(templateColors[11]));
    //   setLabelFontSize(parseInt(templateColors[12]));
    //   setValueFontSize(parseInt(templateColors[13]));
    //   setLabelFontWeight(parseInt(templateColors[14]));
    //   setValueFontWeight(parseInt(templateColors[15]));
    //   setTextTransform(templateColors[16]);
    //   setHeaderHeight(parseInt(templateColors[17]));
    //   setBodyHeight(parseInt(templateColors[18]));
    //   setCvLang(templateColors[19]);
    //   if (templateColors.length > 19) {
    //     setCvLang(templateColors[19]);
    //   }
    //   if (templateColors.length > 20) {
    //     const headerGr = templateColors[20].split("_");
    //     if (headerGr.length === 6) {
    //       setHeaderGradient({
    //         typeOfGradient: headerGr[0],
    //         degree: headerGr[1],
    //         firstColor: headerGr[2],
    //         firstPercentage: headerGr[3],
    //         secondColor: headerGr[4],
    //         secondPercentage: headerGr[5],
    //       });
    //     }

    //     const evenGr = templateColors[21].split("_");
    //     if (evenGr.length === 6) {
    //       setEvenGradient({
    //         typeOfGradient: evenGr[0],
    //         degree: evenGr[1],
    //         firstColor: evenGr[2],
    //         firstPercentage: evenGr[3],
    //         secondColor: evenGr[4],
    //         secondPercentage: evenGr[5],
    //       });
    //     }
    //     const oddGr = templateColors[22].split("_");
    //     if (oddGr.length === 6) {
    //       setOddGradient({
    //         typeOfGradient: oddGr[0],
    //         degree: oddGr[1],
    //         firstColor: oddGr[2],
    //         firstPercentage: oddGr[3],
    //         secondColor: oddGr[4],
    //         secondPercentage: oddGr[5],
    //       });
    //     }
    //   }
    //   if (templateColors.length > 23) {
    //     const useGr = templateColors[23].split("_");
    //     setUseHeaderGradient(useGr[0] === "true");
    //     setUseBodyGradient(useGr[1] === "true");
    //   }
    // }
  };

  useEffect(() => {
    if (currentEmployee) {
      if (
        currentEmployee.prospects &&
        currentEmployee.prospects[0] &&
        currentEmployee.prospects[0]._id
      ) {
        dispatch(getCompany(currentEmployee.prospects[0]._id));
      } else if (currentEmployee.owner && currentEmployee.owner._id) {
        dispatch(getCompany(currentEmployee.owner._id));
      }
    }
  }, [dispatch, currentEmployee]);

  useEffect(() => {
    if (selectedCompany && selectedCompany._id) {
      if (selectedCompany.cvTemplate) {
        renderCvFormat(selectedCompany.cvTemplate);
      } else {
        //renderCvFormat(currentEmployee.owner.cvTemplate);
      }
    }
  }, [dispatch, selectedCompany]);

  useEffect(() => {
    dispatch(changePageTitle("Cvs Detail"));
    if (!employees || employees.length === 0 || searchText) {
      if (
        agencyId &&
        agentId &&
        agencyId.length === 24 &&
        agentId.length === 24
      ) {
        dispatch(
          linkedEmployees({
            agencyId: agencyId,
            agentId: agentId,
            status: EmployeeStatus.new,
            refreshList: "network-only",
          })
        );
      } else {
        dispatch(
          fetchEmployees({
            searchText: "",
            skip: 0,
            take: 500,
            status: EmployeeStatus.new,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, agencyId, agentId]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      if (currentEmployee.givenName === "") {
        dispatch(setSelectedEmployee(employees[0]));
      } else {
        let indexOf = employees.findIndex(
          (e: Employee) => e._id === currentEmployee._id
        );
        dispatch(setSelectedEmployee(employees[indexOf]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const previousEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === 0) indexOf = employees.length - 1;
    else indexOf--;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };
  const nextEmployee = () => {
    let indexOf = employees.findIndex(
      (e: Employee) => e._id === currentEmployee._id
    );
    if (indexOf === employees.length - 1) indexOf = 0;
    else indexOf++;
    dispatch(setSelectedEmployee(employees[indexOf]));
  };

  const shareSingle = (employeeId: string) => {
    //dispatch(generateLink({ employeeIds: [employeeId] }));
    setOpen(true);
  };
  const dialogClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (success && selectedShortLink && selectedShortLink.link) {
      setOpen(true);
      setShortLink(selectedShortLink);
    }
  }, [success, selectedShortLink]);
  const shareCV = () => {};

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const handleGeneratePdf = () => {
    // alert(
    //   "Your screen resolution is: " +
    //     window.screen.width +
    //     "x" +
    //     window.screen.height
    // );

    // if (ref.current === null || ref2.current === null) {
    //   alert(`Make sure you uploaded "PASSPORT COPY" of the Employee`);
    //   return;
    // }
    const fileName = currentEmployee.givenName as string;

    // htmlToImage
    //   .toJpeg(document.getElementById("personalData") as HTMLElement)
    //   .then(function (blob) {
    //     const link = document.createElement("a");
    //     link.download = "my-image-name.jpeg";
    //     link.href = blob;
    //     link.click();
    //   });

    if (numberOfPages === 1) {
      const doc = new jsPDF("p", "mm", "a4", false);
      toCanvas(document.getElementById("personalData") as HTMLElement)
        .then((canvas) => {
          const imgWidth = canvas.width;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("image/png");
          doc.addImage({
            imageData: imgData,
            format: "PNG",
            x: 4,
            y: 4,
            width: 200,
            height: 290,
            alias: "alias1",
            compression: "FAST",
          });
          doc.save(`${fileName}.pdf`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const doc = new jsPDF("p", "mm", "a4", false);
      toCanvas(document.getElementById("personalData") as HTMLElement)
        .then((canvas) => {
          const imgWidth = 200;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL("image/png");
          doc.addImage({
            imageData: imgData,
            format: "PNG",
            x: 4,
            y: 4,
            width: 200,
            height: 290,
            alias: "alias1",
            compression: "FAST",
          });

          doc.addPage();
          toCanvas(document.getElementById("passportData") as HTMLElement)
            .then((canvas2) => {
              const imgWidth2 = 100;
              const imgHeight2 = (canvas.height * imgWidth2) / canvas2.width;
              const imgData2 = canvas2.toDataURL("image/png");
              //console.log(imgData);
              doc.addImage({
                imageData: imgData2,
                format: "PNG",
                x: 4,
                y: 4,
                width: 200,
                height: 190,
                alias: "alias2",
                compression: "FAST",
              });
              doc.save(`${fileName}.pdf`);
              // doc.save("download.pdf", { returnPromise: true })
              //   .then((pdfFile) => {
              //                      }
              // });
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // doc.html(ref.current, {
    //   async callback(doc) {
    //     await doc.save("document");
    //   },
    //   width: 500,
    // });
  };

  // const handleGeneratePdf = () => {
  //   //console.log('print');

  //   let printContents = document.getElementById("printCV")?.innerHTML;
  //   let originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents as string;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  //   return false;
  // };

  const changeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAgentTemplate(!isAgentTemplate);
  };

  //a08745;f2f1f0 fffcf2
  return (
    <>
      <Grid container>
        <Grid item sx={{ p: 1 }} sm={8} xs={12}>
          {employees && employees.length > 0 ? (
            <Box>
              <>
                <Stack direction="row" justifyContent="space-between">
                  <>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      sx={{
                        width: "100%",
                        "& > :not(style)": {
                          m: 0,
                          ml: 1,
                        },
                      }}
                    >
                      <Tooltip title="Previous" sx={{ mt: 30 }}>
                        <Fab component="span" color="primary">
                          <IconButton
                            color="inherit"
                            onClick={previousEmployee}
                            size="large"
                          >
                            <ArrowBack />
                          </IconButton>
                        </Fab>
                      </Tooltip>
                      <Tooltip title="Show List">
                        <Button
                          color="secondary"
                          component={RouterLink}
                          variant="contained"
                          to={`/app/cvs`}
                          size="small"
                        >
                          <List />
                        </Button>
                      </Tooltip>

                      <Tooltip title="Download CV">
                        <Button
                          color="secondary"
                          onClick={handleGeneratePdf}
                          variant="contained"
                          size="small"
                        >
                          <Download />
                        </Button>
                      </Tooltip>
                      {/* <Typography
                        alignSelf="center"
                        fontSize="sm"
                        fontFamily="Noto Sans Ethiopic"
                        fontWeight="extrabold"
                        color="error"
                        sx={{ opacity: 1 }}
                      >{`Before downloading adjust your browser's zoom & screen resolution:${window.screen.width}X${window.screen.height}`}</Typography>
                    */}
                    </Stack>
                  </>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    sx={{
                      width: "100%",
                      "& > :not(style)": {
                        m: 0,
                        ml: 1,
                      },
                    }}
                  >
                    {!agencyId && (
                      <Tooltip title="Edit Detail">
                        <Button
                          color="secondary"
                          component={RouterLink}
                          variant="contained"
                          to={`/app/employee/${currentEmployee._id as string}`}
                          size="small"
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Share Candidate">
                      <Button
                        color="secondary"
                        onClick={() =>
                          shareSingle(currentEmployee._id as string)
                        }
                        variant="contained"
                        size="small"
                      >
                        <Share />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Next" sx={{ mt: 30 }}>
                      <Fab component="span" color="primary">
                        <IconButton
                          color="inherit"
                          onClick={nextEmployee}
                          size="large"
                        >
                          <ArrowForward />
                        </IconButton>
                      </Fab>
                    </Tooltip>
                  </Stack>
                </Stack>
              </>

              <Box
                component={Paper}
                elevation={3}
                sx={{
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                  mt: 1,
                }}
                id="printCV"
              >
                <div dir={theme.direction === "rtl" ? "ltr" : "ltr"}>
                  {name === "basic" ? (
                    <BasicTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "basic2" ? (
                    <BasicTwoTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "basic3" ? (
                    <BasicThreeTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "entrance" ? (
                    <EntranceTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "akeem" ? (
                    <AkeemTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "cadres" ? (
                    <CadresTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "oxagon" ? (
                    <OxagonTwoTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "maleda" ? (
                    <MaledaTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "stars" ? (
                    <SkillStarTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : name === "sanad" ? (
                    <SanadTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  ) : (
                    <InterTemplate
                      backgroundColor={backgroundColor}
                      cvTemplate={cvTemplate}
                      currentEmployee={currentEmployee}
                      selectedCompany={selectedCompany}
                    />
                  )}
                </div>
              </Box>
            </Box>
          ) : loading === "pending" ? (
            <AuthSkeleton />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Image
                src="/No_Data.svg"
                alt="No Data"
                width={250}
                height={120}
                shift="top"
                distance="2rem"
                shiftDuration={320}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Typography variant="h5">No CV Found</Typography>
            </Box>
          )}
          <CustomDialog
            title="Agency/Agent Link"
            isOpen={open}
            handleDialogClose={dialogClose}
          >
            {currentEmployee &&
            currentEmployee.owner &&
            currentEmployee.owner._id &&
            currentEmployee.prospects &&
            currentEmployee.prospects[0] &&
            currentEmployee.prospects[0]._id ? (
              <Typography variant="h4">
                {`https://enjazplus.com/cpp/${currentEmployee.owner._id}/${currentEmployee.prospects[0]._id}?lng=ar`}
              </Typography>
            ) : (
              <Typography variant="h4">
                No Agent/Company Assigned to Employee
              </Typography>
            )}
          </CustomDialog>{" "}
        </Grid>
        <Grid
          item
          sm={4}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-start",
          }}
        >
          {isPrivilegedTransaction(user?.roles as Role[], "ViewCompanies") && (
            <CvMakerPremium
              setName={setName}
              setNumberOfPages={setNumberOfPages}
              setCvLang={setCvLang}
              setBGC={setBackgroundColor}
              setHFC={setHeadFontColor}
              setHBC={setHeadBgColor}
              setLFC={setLabelFontColor}
              setLBC={setLabelBgColor}
              setVFC={setValueFontColor}
              setVBC={setValueBgColor}
              setBorderColor={setBorderColor}
              setBorderRadius={setBorderRadius}
              setBorderWidth={setBorderWidth}
              setBorderStyle={setBorderStyle}
              setSpacing={setSpacing}
              setLabelFontSize={setLabelFontSize}
              setValueFontSize={setValueFontSize}
              setLabelFontWeight={setLabelFontWeight}
              setValueFontWeight={setValueFontWeight}
              setTextTransform={setTextTransform}
              setHeaderHeight={setHeaderHeight}
              setBodyHeight={setBodyHeight}
              setHeaderGradient={setHeaderGradient}
              setEvenGradient={setEvenGradient}
              setOddGradient={setOddGradient}
              setAddPassportHeader={setAddPassportHeader}
              setUseHeaderGradient={setUseHeaderGradient}
              setUseBodyGradient={setUseBodyGradient}
              setHeaderShadow={setHeaderShadow}
              setBodyShadow={setBodyShadow}
              setPhotoShadow={setPhotoShadow}
              setPassportShadow={setPassportShadow}
              setQrLocationLink={setQrLocationLink}
              setQrLocationWidth={setQrLocationWidth}
              setQrLocationHeight={setQrLocationHeight}
              setQrLocationImageUrl={setQrLocationImageUrl}
              setQrLocationMargin={setQrLocationMargin}
              setQrMoreCvsLink={setQrMoreCvsLink}
              setQrMoreCvsWidth={setQrMoreCvsWidth}
              setQrMoreCvsHeight={setQrMoreCvsHeight}
              setQrMoreCvsImageUrl={setQrMoreCvsImageUrl}
              setQrMoreCvsMargin={setQrMoreCvsMargin}
              setFaceHeight={setFaceHeight}
              setFaceWidth={setFaceWidth}
              setStandHeight={setStandHeight}
              setStandWidth={setStandWidth}
              setFlagHeight={setFlagHeight}
              setFlagWidth={setFlagWidth}
              setPassportHeight={setPassportHeight}
              setPassportWidth={setPassportWidth}
              setHeadBgColorAlpha={setHeadBgColorAlpha}
              setHeadGrFirstColorAlpha={setHeadGrFirstColorAlpha}
              setHeadGrSecondColorAlpha={setHeadGrSecondColorAlpha}
              setLabelBgColorAlpha={setLabelBgColorAlpha}
              setOddGrFirstColorAlpha={setOddGrFirstColorAlpha}
              setOddGrSecondColorAlpha={setOddGrSecondColorAlpha}
              setValueBgColorAlpha={setValueBgColorAlpha}
              setEvenGrFirstColorAlpha={setEvenGrFirstColorAlpha}
              setEvenGrSecondColorAlpha={setEvenGrSecondColorAlpha}
            />
            // <Stack alignItems="center">
            //   <FormControlLabel
            //     style={{ marginTop: 2 }}
            //     control={
            //       <Switch
            //         checked={isAgentTemplate}
            //         onChange={changeCheckBox}
            //         inputProps={{ "aria-label": "controlled" }}
            //         name="selectAll"
            //         color="secondary"
            //       />
            //     }
            //     label="Show CV Maker"
            //   />
            //   {isAgentTemplate && (

            //   )}
            // </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PremiumTemplateMaker;
