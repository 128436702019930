import { Role } from "../features/auth/types/authType";

export const isPrivilegedTransaction = (roles: Role[], privileged: string) => {
  return roles.some(
    (r) =>
      r &&
      r.displayName &&
      r.displayName.toLowerCase() === privileged.toLowerCase()
  );
};
