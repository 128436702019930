import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, Container, LinearProgress } from "@mui/material";

import { useAppDispatch } from "../../app/hooks";
import { changePageTitle } from "../preferences/preferencesSlice";

const Complaints = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changePageTitle("Complaints"));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Complaints | EnjazPlus</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <LinearProgress color="secondary" />
        </Container>
      </Box>
    </>
  );
};

export default Complaints;
