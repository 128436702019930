import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  useTheme,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
} from "../../../../styles/tableStyles";
import { useTranslation } from "react-i18next";
import { Employee } from "../../types/processTypes";
interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const PostApplied = ({ currentEmployee, template }: Props) => {
  const { t: tt } = useTranslation();
  const { t: te } = useTranslation("enums");
  const theme = useTheme();
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          marginTop: 1,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRow key="4">
              <StyledTableCellLabelTemplate
                template={template}
                colSpan={2}
                sx={{
                  textAlign: "center",
                }}
              >
                {currentEmployee.givenName +
                  " (" +
                  currentEmployee.passportNumber +
                  ")"}
              </StyledTableCellLabelTemplate>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow key="3">
              <StyledTableCellLabelTemplate template={template}>
                {te("profession")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(
                  (
                    currentEmployee.additionalData?.appliedProfession as string
                  ).toLowerCase()
                )}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="2">
              <StyledTableCellLabelTemplate template={template}>
                {tt("salary")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.additionalData?.salary?.toLocaleString(
                  theme.direction === "rtl" ? "ar-SA" : "en-US"
                ) +
                  " " +
                  te(
                    (
                      currentEmployee.additionalData?.currency as string
                    ).toLowerCase()
                  )}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
            <StyledTableRow key="1">
              <StyledTableCellLabelTemplate template={template}>
                {te("contractPeriod")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {te(currentEmployee.additionalData?.contractPeriod as string)}
              </StyledTableCellValueTemplate>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
