import { createAsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { apolloClient } from "../../apollo/graphql";
import {
  CompaniesWithCount,
  Company,
  CompanyArgs,
  CvTemplate,
  SetupsState,
  UsersWithCount,
} from "./types/setupTypes";

import {
  ADD_USER_ROLES,
  CREATE_USER,
  REMOVE_USER,
  ADD_UPDATE_COMPANY,
  LINK_COMPANIES,
  SINGLE_UPLOAD_COMPANY,
} from "../../apollo/mutations";
import {
  GET_ALL_ROLES,
  GET_ALL_USERS,
  GET_SELECTED_USER,
  GET_ALL_COMPANIES,
  GET_SELECTED_COMPANY,
} from "../../apollo/queries";

import {
  RejectWithValueType,
  AuthUser,
  Role,
  CreateUser,
  UploadGraphQL,
  UploadGraphQLResult,
} from "../auth/types/authType";

import { UserArgs } from "./types/setupTypes";
import { PhotoTypes } from "../process/types/processTypes";

export const fetchUsers = createAsyncThunk<
  any,
  UserArgs,
  { rejectValue: RejectWithValueType }
>("users/fetchUsers", async (userArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const { refreshList } = userArgs;
    //console.log(userArgs);
    const fetchPolicy =
      refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_ALL_USERS,
      variables: { ...userArgs },
      fetchPolicy,
    });

    if (response && response.data && response.data.Users) {
      return response.data.Users as UsersWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const getUser = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("users/getUser", async (userId, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.query({
      query: GET_SELECTED_USER,
      variables: { id: userId },
    });
    if (response && response.data && response.data.GetUser) {
      return response.data.GetUser as AuthUser;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const createUser = createAsyncThunk<
  any,
  CreateUser,
  { rejectValue: RejectWithValueType }
>("users/createUser", async (user, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    // const { agency, agent } = user;
    // console.log(user);
    const response = await apolloClient.mutate({
      mutation: CREATE_USER,
      variables: {
        name: user.name,
        email: user.email,
        companyId: user.companyId,
      },
    });

    if (response && response.data && response.data.createUser) {
      return response.data.createUser as AuthUser;
    }
    //return [];
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const fetchCompanies = createAsyncThunk<
  any,
  CompanyArgs,
  { rejectValue: RejectWithValueType }
>("setups/fetchCompanies", async (companyArgs, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  //console.log(companyArgs);
  try {
    const { refreshList } = companyArgs;
    const fetchPolicy =
      refreshList === "refresh" ? "network-only" : "cache-first";
    const response = await apolloClient.query({
      query: GET_ALL_COMPANIES,
      variables: { ...companyArgs },
      fetchPolicy,
    });

    if (response && response.data && response.data.companies) {
      return response.data.companies as CompaniesWithCount;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const getCompany = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("setups/getCompany", async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    //console.log(id);
    const response = await apolloClient.query({
      query: GET_SELECTED_COMPANY,
      variables: { id: id },
    });

    if (response && response.data && response.data.getCompany) {
      return response.data.getCompany as Company;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const addCompany = createAsyncThunk<
  any,
  Company,
  { rejectValue: RejectWithValueType }
>("setups/addCompany", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    //let bp = { ...arg };
    //console.log(arg);
    let city,
      mobile,
      alternateMobile,
      telephone,
      alternateTelephone,
      country,
      streetAddress;
    const { address, cvTemplate } = arg;
    if (address) {
      city = address.city;
      mobile = address.mobile;
      alternateMobile = address.alternateMobile;
      telephone = address.telephone;
      alternateTelephone = address.alternateTelephone;
      country = address.country;
      streetAddress = address.streetAddress;
    }

    let name,
      numberOfPages,
      cvLang,
      bgColor,
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
      borderColor,
      borderRadius,
      borderStyle,
      borderWidth,
      spacing,
      labelFontSize,
      valueFontSize,
      labelFontWeight,
      valueFontWeight,
      headerHeight,
      bodyHeight,
      textTransform,
      addPassportHeader,
      useHeaderGradient,
      useBodyGradient,
      headerGradient,
      evenGradient,
      oddGradient,
      headerShadow,
      bodyShadow,
      photoShadow,
      passportShadow,
      qrLocationLink,
      qrLocationWidth,
      qrLocationHeight,
      qrLocationImageUrl,
      qrLocationMargin,
      qrMoreCvsLink,
      qrMoreCvsWidth,
      qrMoreCvsHeight,
      qrMoreCvsImageUrl,
      qrMoreCvsMargin,
      faceHeight,
      faceWidth,
      standHeight,
      standWidth,
      flagHeight,
      flagWidth,
      passportHeight,
      passportWidth,
      headBgColorAlpha,
      headGrFirstColorAlpha,
      headGrSecondColorAlpha,
      labelBgColorAlpha,
      oddGrFirstColorAlpha,
      oddGrSecondColorAlpha,
      valueBgColorAlpha,
      evenGrFirstColorAlpha,
      evenGrSecondColorAlpha;
    if (cvTemplate) {
      name = cvTemplate.name;
      numberOfPages = cvTemplate.numberOfPages;
      cvLang = cvTemplate.cvLang;
      bgColor = cvTemplate.bgColor;
      headFontColor = cvTemplate.headFontColor;
      headBgColor = cvTemplate.headBgColor;
      labelFontColor = cvTemplate.labelFontColor;
      labelBgColor = cvTemplate.labelBgColor;
      valueFontColor = cvTemplate.valueFontColor;
      valueBgColor = cvTemplate.valueBgColor;
      borderColor = cvTemplate.borderColor;
      borderRadius = cvTemplate.borderRadius;
      borderStyle = cvTemplate.borderStyle;
      borderWidth = cvTemplate.borderWidth;
      spacing = cvTemplate.spacing;
      labelFontSize = cvTemplate.labelFontSize;
      valueFontSize = cvTemplate.valueFontSize;
      labelFontWeight = cvTemplate.labelFontWeight;
      valueFontWeight = cvTemplate.valueFontWeight;
      headerHeight = cvTemplate.headerHeight;
      bodyHeight = cvTemplate.bodyHeight;
      textTransform = cvTemplate.textTransform;
      addPassportHeader = cvTemplate.addPassportHeader;
      useHeaderGradient = cvTemplate.useHeaderGradient;
      useBodyGradient = cvTemplate.useBodyGradient;
      headerGradient = cvTemplate.headerGradient;
      evenGradient = cvTemplate.evenGradient;
      oddGradient = cvTemplate.oddGradient;
      headerShadow = cvTemplate.headerShadow;
      bodyShadow = cvTemplate.bodyShadow;
      photoShadow = cvTemplate.photoShadow;
      passportShadow = cvTemplate.passportShadow;
      qrLocationLink = cvTemplate.qrLocationLink;
      qrLocationWidth = cvTemplate.qrLocationWidth;
      qrLocationHeight = cvTemplate.qrLocationHeight;
      qrLocationImageUrl = cvTemplate.qrLocationImageUrl;
      qrLocationMargin = cvTemplate.qrLocationMargin;
      qrMoreCvsLink = cvTemplate.qrMoreCvsLink;
      qrMoreCvsWidth = cvTemplate.qrMoreCvsWidth;
      qrMoreCvsHeight = cvTemplate.qrMoreCvsHeight;
      qrMoreCvsImageUrl = cvTemplate.qrMoreCvsImageUrl;
      qrMoreCvsMargin = cvTemplate.qrMoreCvsMargin;
      faceHeight = cvTemplate.faceHeight;
      faceWidth = cvTemplate.faceWidth;
      standHeight = cvTemplate.standHeight;
      standWidth = cvTemplate.standWidth;
      flagHeight = cvTemplate.flagHeight;
      flagWidth = cvTemplate.flagWidth;
      passportHeight = cvTemplate.passportHeight;
      passportWidth = cvTemplate.passportWidth;
      headBgColorAlpha = cvTemplate.headBgColorAlpha;
      headGrFirstColorAlpha = cvTemplate.headGrFirstColorAlpha;
      headGrSecondColorAlpha = cvTemplate.headGrSecondColorAlpha;
      labelBgColorAlpha = cvTemplate.labelBgColorAlpha;
      oddGrFirstColorAlpha = cvTemplate.oddGrFirstColorAlpha;
      oddGrSecondColorAlpha = cvTemplate.oddGrSecondColorAlpha;
      valueBgColorAlpha = cvTemplate.valueBgColorAlpha;
      evenGrFirstColorAlpha = cvTemplate.evenGrFirstColorAlpha;
      evenGrSecondColorAlpha = cvTemplate.evenGrSecondColorAlpha;
    }

    const response = await apolloClient.mutate({
      mutation: ADD_UPDATE_COMPANY,
      variables: {
        id: arg._id,
        type: arg.type,
        displayName: arg.displayName.toUpperCase(),
        email: arg.email.toLowerCase(),
        address: address && {
          city,
          telephone,
          country,
          streetAddress,
          alternateTelephone,
          mobile,
          alternateMobile,
        },
        cvTemplate: cvTemplate && {
          name,
          numberOfPages,
          cvLang,
          bgColor,
          headFontColor,
          headBgColor,
          labelFontColor,
          labelBgColor,
          valueFontColor,
          valueBgColor,
          borderColor,
          borderRadius,
          borderStyle,
          borderWidth,
          spacing,
          labelFontSize,
          valueFontSize,
          labelFontWeight,
          valueFontWeight,
          headerHeight,
          bodyHeight,
          textTransform,
          addPassportHeader,
          useHeaderGradient,
          useBodyGradient,
          headerGradient,
          evenGradient,
          oddGradient,
          headerShadow,
          bodyShadow,
          photoShadow,
          passportShadow,
          qrLocationLink,
          qrLocationWidth,
          qrLocationHeight,
          qrLocationImageUrl,
          qrLocationMargin,
          qrMoreCvsLink,
          qrMoreCvsWidth,
          qrMoreCvsHeight,
          qrMoreCvsImageUrl,
          qrMoreCvsMargin,
          faceHeight,
          faceWidth,
          standHeight,
          standWidth,
          flagHeight,
          flagWidth,
          passportHeight,
          passportWidth,
          headBgColorAlpha,
          headGrFirstColorAlpha,
          headGrSecondColorAlpha,
          labelBgColorAlpha,
          oddGrFirstColorAlpha,
          oddGrSecondColorAlpha,
          valueBgColorAlpha,
          evenGrFirstColorAlpha,
          evenGrSecondColorAlpha,
        },
        remarks: arg.remarks,
        managerNameAmharic: arg.managerNameAmharic,
        nameAmharic: arg.nameAmharic,
        licenseNumber: arg.licenseNumber,
        enjazPlusLicense: arg.enjazPlusLicense,
      },
    });

    if (response && response.data && response.data.createCompany) {
      const addedCompany = (await response.data.createCompany) as Company;

      await setSuccessAction(dispatch, {
        message: "Company Successfully Saved",
        setupType: "Company",
      });

      return addedCompany;
    }
    // return LocalAgency;
  } catch (error: any) {
    const message = error.message;
    dispatch(setCompany(arg));
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const uploadCompanyPicture = createAsyncThunk<
  any,
  UploadGraphQL,
  { rejectValue: RejectWithValueType }
>("setups/uploadCompanyPicture", async (uploadInput, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  const { _id, avatar, type } = uploadInput;
  try {
    if (avatar.size > 1000000) {
      const message = "File size can't exceeds 1Mb";
      await setErrorAction(dispatch, { message });
      return rejectWithValue({ message });
    } else {
      const response = await apolloClient.mutate({
        mutation: SINGLE_UPLOAD_COMPANY,
        variables: {
          _id: _id,
          type: type,
          avatar: avatar,
        },
      });

      if (response && response.data && response.data.uploadCompanyPicture) {
        await setSuccessAction(dispatch, {
          message: "uploaded successfully",
        });
        return response.data.uploadCompanyPicture as UploadGraphQLResult;
      }
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const linkCompanies = createAsyncThunk<
  any,
  string[],
  { rejectValue: RejectWithValueType }
>("setups/linkCompanies", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    //console.log(arg);
    const response = await apolloClient.mutate({
      mutation: LINK_COMPANIES,
      variables: { ids: arg },
      refetchQueries: [
        { query: GET_SELECTED_COMPANY, variables: { id: arg[0] } },
      ],
    });

    if (response && response.data && response.data.linkCompanies) {
      //return response.data.linkCompanies as Company;
      const linkedCompany = (await response.data.linkCompanies) as Company;

      await setSuccessAction(dispatch, {
        message: "Company Successfully Linked",
        setupType: "Company",
      });

      return linkedCompany;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const removeUser = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("setups/removeUser", async (id, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await apolloClient.mutate({
      mutation: REMOVE_USER,
      variables: { id },
    });

    if (response && response.data && response.data.deleteUser) {
      await setSuccessAction(dispatch, {
        message: "User Successfully Removed",
        setupType: "User",
      });
      return id as string;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
export const addUserRoles = createAsyncThunk<
  any,
  string[],
  { rejectValue: RejectWithValueType }
>("users/addUserRoles", async (arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await apolloClient.mutate({
      mutation: ADD_USER_ROLES,
      variables: { ids: arg },
      refetchQueries: [{ query: GET_SELECTED_USER, variables: { id: arg[0] } }],
    });

    if (response && response.data && response.data.addUserRoles) {
      return response.data.addUserRoles as AuthUser;
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});
// export const addUserAgent = createAsyncThunk<
//   any,
//   number[],
//   { rejectValue: RejectWithValueType }
// >("users/addUserAgent", async (arg, thunkAPI) => {
//   const { rejectWithValue, dispatch } = thunkAPI;
//   try {
//     const response = await apolloClient.mutate({
//       mutation: ADD_USER_AGENT,
//       variables: { ids: arg },
//       refetchQueries: [{ query: GET_SELECTED_USER, variables: { id: arg[0] } }],
//     });

//     if (response && response.data && response.data.addUserAgent) {
//       return response.data.addUserAgent as AuthUser;
//     }
//   } catch (error: any) {
//     const message = error.message;
//     await setErrorAction(dispatch, { message });
//     return rejectWithValue({ message });
//   }
// });
export const fetchRoles = createAsyncThunk<
  any,
  string,
  { rejectValue: RejectWithValueType }
>("users/fetchRoles", async (_arg, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;

  try {
    const response = await apolloClient.query({
      query: GET_ALL_ROLES,
    });

    if (response && response.data && response.data.GetRoles) {
      return response.data.GetRoles as Role[];
    }
  } catch (error: any) {
    const message = error.message;
    await setErrorAction(dispatch, { message });
    return rejectWithValue({ message });
  }
});

async function setSuccessAction(
  dispatch: ThunkDispatch<any, any, any>,
  payload: any
) {
  dispatch(setSuccess(payload));
  setTimeout(() => {
    // switch (payload.setupType) {
    //   case "User":
    //     dispatch(
    //       resetSelectedUser({
    //         ...defaultUser,
    //       })
    //     );
    //     break;
    //   case "Company":
    //     dispatch(resetCompany());
    //     break;
    // }
    dispatch(resetSuccess());
  }, 2000);
}
async function setErrorAction(
  dispatch: ThunkDispatch<any, any, any>,
  payload: any
) {
  dispatch(setError(payload));
  setTimeout(() => {
    dispatch(resetError());
  }, 6000);
}

const defaultUser: AuthUser = {
  email: "",
  name: "",
};
const defaultCompany: Company = {
  displayName: "",
  email: "",
  licenseNumber: "",
  address: { mobile: "", telephone: "", email: "" },
};

const initialSetupsState: SetupsState = {
  companiesWithCount: { totalCount: 0, companies: [] },
  selectedCompany: { ...defaultCompany },

  usersWithCount: { totalCount: 0, users: [] },
  selectedUser: { ...defaultUser },
  roles: [],
  loading: "idle",
  currentRequestId: undefined,
  success: null,
  error: null,
};

export const setupsSlice = createSlice({
  name: "setups",
  initialState: initialSetupsState,
  reducers: {
    setSuccess: (state, { payload }) => {
      state.success = payload;
    },
    resetSuccess: (state) => {
      state.success = null;
    },
    setError: (state, { payload }) => {
      state.error = payload;
      if (payload.message.includes("Authorized")) {
        localStorage.removeItem("userInfo");
      }
    },
    resetError: (state) => {
      state.error = null;
    },
    resetCompany: (state) => {
      state.selectedCompany = {
        ...defaultCompany,
      };
    },
    setCompany: (state, { payload }) => {
      state.selectedCompany = payload;
    },

    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
    setUsers: (state, { payload }) => {
      state.usersWithCount = payload;
    },
    resetSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.usersWithCount = payload;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(getUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedUser = payload;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(createUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedUser = payload;
      state.usersWithCount.users = state.usersWithCount.users?.filter(
        (c) => c._id !== payload._id
      );
      state.usersWithCount.users.unshift(payload);
      state.usersWithCount.totalCount++;
    });
    builder.addCase(createUser.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(fetchCompanies.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchCompanies.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.companiesWithCount = payload;
    });
    builder.addCase(fetchCompanies.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(getCompany.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(getCompany.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedCompany = payload;
    });
    builder.addCase(getCompany.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(addCompany.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addCompany.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedCompany = payload;
    });
    builder.addCase(addCompany.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(uploadCompanyPicture.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(uploadCompanyPicture.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      const photoUrl = payload.avatar as string;
      const type = payload.type as PhotoTypes;
      if (state.selectedCompany) {
        if (type === PhotoTypes.Header) state.selectedCompany.header = photoUrl;
        else if (type === PhotoTypes.Bottom)
          state.selectedCompany.footer = photoUrl;
      }
    });
    builder.addCase(uploadCompanyPicture.rejected, (state, { payload }) => {
      state.loading = "idle";
    });
    builder.addCase(linkCompanies.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(linkCompanies.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedCompany = payload;
    });
    builder.addCase(linkCompanies.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(removeUser.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(removeUser.fulfilled, (state, { payload }) => {
      state.loading = "idle";

      state.usersWithCount.totalCount =
        (state.usersWithCount.totalCount as number) - 1;
      state.usersWithCount.users = state.usersWithCount.users.filter(
        (h) => h._id !== payload
      );
    });
    builder.addCase(removeUser.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(fetchRoles.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(fetchRoles.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.roles = payload;
    });
    builder.addCase(fetchRoles.rejected, (state) => {
      state.loading = "idle";
    });

    builder.addCase(addUserRoles.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(addUserRoles.fulfilled, (state, { payload }) => {
      state.loading = "idle";
      state.selectedUser = payload;
    });
    builder.addCase(addUserRoles.rejected, (state) => {
      state.loading = "idle";
    });
    // builder.addCase(addUserAgent.pending, (state) => {
    //   state.loading = "pending";
    // });
    // builder.addCase(addUserAgent.fulfilled, (state, { payload }) => {
    //   state.loading = "idle";
    //   state.selectedUser = payload;
    // });
    // builder.addCase(addUserAgent.rejected, (state) => {
    //   state.loading = "idle";
    // });
  },
});
const { actions, reducer } = setupsSlice;
export const {
  resetSuccess,
  setSuccess,
  resetError,
  setError,
  setCompany,
  resetCompany,
  setUsers,
  setSelectedUser,
  resetSelectedUser,
} = actions;

export default reducer;

// Selectors
export const selectSetups = (state: RootState) => state.setups as SetupsState;
