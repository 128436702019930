import { experimentalStyled as styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import tinycolor from "tinycolor2";

export interface GradientTemplate {
  typeOfGradient: string | "linear-gradient";
  degree: string | "90deg";
  firstColor: string | "transparent";
  firstPercentage: string | "0%";
  secondColor: string | "transparent";
  secondPercentage: string | "100%";
}

export interface LabelTemplate {
  name: string | "basic";
  numberOfPages: number | 2;

  cvLang: string | "ar";
  bgColor: string | "#fff";

  headFontColor?: string;
  headBgColor?: string;
  labelFontColor?: string;
  labelBgColor?: string;
  valueFontColor?: string;
  valueBgColor?: string;

  borderColor?: string;
  borderRadius?: string;
  borderWidth?: number;
  borderStyle?: string;

  spacing?: number;
  labelFontSize?: number | 16;
  valueFontSize?: number | 18;
  labelFontWeight?: number | 900;
  valueFontWeight?: number | 700;

  headerHeight?: number | 48;
  bodyHeight?: number | 32;
  textTransform?: string | "uppercase";

  headerGradient: GradientTemplate;
  evenGradient: GradientTemplate;
  oddGradient: GradientTemplate;

  addPassportHeader: boolean | false;
  useHeaderGradient: boolean | false;
  useBodyGradient: boolean | false;

  headerShadow?: string;
  bodyShadow?: string;
  photoShadow?: string;
  passportShadow?: string;

  qrLocationLink: string | "https://maps.app.goo.gl/ikKVLs5b6Ef2Xemw8";
  qrLocationWidth: number | 150;
  qrLocationHeight: number | 150;
  qrLocationImageUrl: string | "/googleMaps.png";
  qrLocationMargin: number | 0;

  qrMoreCvsLink: string | "https://enjazplus.com/qrCvs";
  qrMoreCvsWidth: number | 150;
  qrMoreCvsHeight: number | 150;
  qrMoreCvsImageUrl: string | "/enjazPLogoFilled.png";
  qrMoreCvsMargin: number | 0;

  faceHeight: string | "240px";
  faceWidth: string | "100%";
  standHeight: string | "600px";
  standWidth: string | "400px";
  flagHeight: string | "200px";
  flagWidth: string | "100%";
  passportHeight: string | "650px";
  passportWidth: string | "700px";

  headBgColorAlpha?: number | 1;
  labelBgColorAlpha?: number | 1;
  valueBgColorAlpha?: number | 1;
  headGrFirstColorAlpha?: number | 1;
  headGrSecondColorAlpha?: number | 1;
  oddGrFirstColorAlpha?: number | 1;
  oddGrSecondColorAlpha?: number | 1;
  evenGrFirstColorAlpha?: number | 1;
  evenGrSecondColorAlpha?: number | 1;
}
export const alphaBg = (primaryColor: string, alpha: number) => {
  return tinycolor(primaryColor).setAlpha(alpha).toRgbString();
};

export const StyledTableContainer = styled(TableContainer, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => ({
  width: "100%",
  borderRadius: template.borderRadius,
  borderColor: template.borderColor,
  borderWidth: template.borderWidth,
  borderStyle: template.borderStyle,
  boxShadow: template.bodyShadow,
}));

// <Table
//   sx={{
//     [`& .${tableCellClasses.root}`]: {
//       borderBottom: "none"
//     }
//   }}
// >

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.selected,
  },

  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const StyledTableRowTemplate = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => {
  // console.log(
  //   template.headerGradient,
  //   template.evenGradient,
  //   template.oddGradient
  // );
  if (
    template &&
    template.headerGradient &&
    template.evenGradient &&
    template.oddGradient
  ) {
    const {
      typeOfGradient,
      degree,
      firstColor,
      firstPercentage,
      secondColor,
      secondPercentage,
    } = template.headerGradient;
    const {
      typeOfGradient: typeOfGradientEven,
      degree: degreeEven,
      firstColor: firstColorEven,
      firstPercentage: firstPercentageEven,
      secondColor: secondColorEven,
      secondPercentage: secondPercentageEven,
    } = template.evenGradient;
    const {
      typeOfGradient: typeOfGradientOdd,
      degree: degreeOdd,
      firstColor: firstColorOdd,
      firstPercentage: firstPercentageOdd,
      secondColor: secondColorOdd,
      secondPercentage: secondPercentageOdd,
    } = template.oddGradient;

    return {
      "&:nth-of-type(even)": {
        background: template.useBodyGradient
          ? `${typeOfGradientEven}(${degreeEven}, ${alphaBg(
              firstColorEven,
              template.evenGrFirstColorAlpha as number
            )} ${firstPercentageEven}, ${alphaBg(
              secondColorEven,
              template.evenGrSecondColorAlpha as number
            )} ${secondPercentageEven})`
          : "#FFFFFF00",
      },
      "&:nth-of-type(odd)": {
        background: template.useBodyGradient
          ? `${typeOfGradientOdd}(${degreeOdd}, ${alphaBg(
              firstColorOdd,
              template.oddGrFirstColorAlpha as number
            )} ${firstPercentageOdd}, ${alphaBg(
              secondColorOdd,
              template.oddGrSecondColorAlpha as number
            )} ${secondPercentageOdd})`
          : "#FFFFFF00",
      },
      [`&.${tableRowClasses.head}`]: {
        background: template.useHeaderGradient
          ? `${typeOfGradient}(${degree}, ${alphaBg(
              firstColor,
              template.headGrFirstColorAlpha as number
            )} ${firstPercentage}, ${alphaBg(
              secondColor,
              template.headGrSecondColorAlpha as number
            )} ${secondPercentage})`
          : "#FFFFFF00",
      },
      "td, th": {
        border: 0,
      },
    };
  } else {
    return {
      "&:nth-of-type(even)": {
        background: `${alphaBg(
          template.valueBgColor as string,
          template.valueBgColorAlpha as number
        )}`,
      },
      "&:nth-of-type(odd)": {
        background: `${alphaBg(
          template.labelBgColor as string,
          template.labelBgColorAlpha as number
        )}`,
      },
      [`&.${tableRowClasses.head}`]: {
        background: `${alphaBg(
          template.headBgColor as string,
          template.headBgColorAlpha as number
        )}`,
      },
      "td, th": {
        border: 0,
      },
    };
  }
});
// export const StyledTableRowTemplate = styled(TableRow, {
//   shouldForwardProp: (prop) => prop !== "template",
// })<{ template: LabelTemplate }>(({ theme, template }) => ({
//   [`&.${tableRowClasses.head}`]: {
//     background: `radial-gradient(circle, ${template.headBgColor} 0%, ${template.labelBgColor} 50%)`,
//   },

//   "&:nth-of-type(even)": {
//     background: `radial-gradient(circle, ${template.headBgColor} 0%, ${template.labelBgColor} 50%)`,
//   },
//   "&:nth-of-type(odd)": {
//     background: `radial-gradient(circle, ${template.headBgColor} 0%, ${template.labelBgColor} 50%)`,
//   },
// }));
export const StyledTableCellLabelTemplate = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: template.useHeaderGradient
      ? "#FFFFFF00"
      : alphaBg(
          template.headBgColor as string,
          template.headBgColorAlpha as number
        ),
    color: template.headFontColor,
    height: template.headerHeight,
    fontSize: template.labelFontSize,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: template.labelFontSize,
    height: template.bodyHeight,
  },

  // textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: template.labelFontWeight,
  backgroundColor: template.useBodyGradient
    ? "#FFFFFF00"
    : alphaBg(
        template.labelBgColor as string,
        template.labelBgColorAlpha as number
      ),
  color: template.labelFontColor,
  textTransform:
    template.textTransform === "capitalize" ? "capitalize" : "uppercase",
}));

export const StyledTableCellValueTemplate = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: template.useHeaderGradient
      ? "#FFFFFF00"
      : alphaBg(
          template.headBgColor as string,
          template.headBgColorAlpha as number
        ),
    color: template.headFontColor,
    height: template.headerHeight,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: template.valueFontSize,
    height: template.bodyHeight,
  },

  textAlign: "center",
  fontWeight: template.valueFontWeight,
  backgroundColor: template.useBodyGradient
    ? "#FFFFFF00"
    : alphaBg(
        template.valueBgColor as string,
        template.valueBgColorAlpha as number
      ),
  color: template.valueFontColor,
  textTransform:
    template.textTransform === "capitalize" ? "capitalize" : "uppercase",
}));
