import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { useAppSelector, useAppDispatch } from "../../app/hooks";

import { selectAuth } from "../../features/auth/authSlice";
import { logout } from "../../features/auth/authSlice";
import { selectPreference } from "../../features/preferences/preferencesSlice";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Search from "../search/Search";
import { Box, Divider, ListItemIcon, Tooltip } from "@mui/material";
import Mode from "../mode/Mode";
import { Logout, Person, Refresh, Settings } from "@mui/icons-material";
import Language from "../mode/Language";
import { t } from "i18next";

// (
//   <MenuItem key="11" onClick={handleMenuClose} component={NavLink} to="/app/cp">
//     <ListItemIcon>
//       <CorporateFare />
//     </ListItemIcon>
//     Company Profile
//   </MenuItem>
// ),
function NavbarContent() {
  const { user } = useAppSelector(selectAuth);
  const { pageTitle, searchText } = useAppSelector(selectPreference);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const logoutHandler = () => {
    dispatch(logout());
    handleMenuClose();
  };
  function refreshPage() {
    window.location.reload();
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [header, setHeader] = useState("");
  useEffect(() => {
    if (user && user.company) {
      const headerTitle = user.company?.displayName as string;
      setHeader(headerTitle);
    } else {
      setHeader(pageTitle);
    }
  }, [user, pageTitle]);

  const menuId = "primary-search-account-menu";

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.default",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {[
        <MenuItem
          key="12"
          onClick={handleMenuClose}
          component={NavLink}
          to="/app/profile"
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          My account
        </MenuItem>,
        <Divider key="15" />,

        <MenuItem
          key="13"
          onClick={handleMenuClose}
          component={NavLink}
          to="/app/setting"
        >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>,
        <MenuItem key="14" onClick={logoutHandler}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Log out
        </MenuItem>,
      ]}
    </Menu>
  );

  return (
    <>
      <Typography
        sx={{
          fontWeight: 900,
          fontFamily: "Open Sans",
          fontStyle: "italic",
          p: 0.5,
        }}
        variant="h3"
        noWrap
      >
        {header}
      </Typography>
      <Box sx={{ flex: "1 1 auto" }} />
      <Toolbar disableGutters>
        {(window.location.href.includes("employees") ||
          window.location.href.includes("coms") ||
          window.location.href.includes("cvs") ||
          window.location.href.includes("pcv") ||
          window.location.href.includes("users")) && (
          <Search initialValue={searchText} />
        )}
        <Tooltip title={t("refresh")}>
          <IconButton color="inherit" onClick={refreshPage}>
            <Refresh />
          </IconButton>
        </Tooltip>
      </Toolbar>

      <Box
        sx={{
          flexDirection: "row",
          display: { xs: "none", md: "flex" },
          justifyContent: "space-between",
        }}
      >
        {/* <IconButton color="inherit" style={{ marginLeft: 0 }}>
        <Badge badgeContent={4} color="secondary">
          <MailIcon />
        </Badge>
      </IconButton>
      <IconButton color="inherit">
        <Badge badgeContent={17} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton> */}
        <Mode />
        <IconButton
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          size="large"
        >
          <Avatar
            alt="avatar"
            src={user ? user.avatar : ""}
            sx={{
              height: 20,
              width: 20,
            }}
          />
        </IconButton>
        <Language />
      </Box>
      {renderMenu}
    </>
  );
}

export default NavbarContent;
