import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  StyledTableRowTemplate,
  StyledTableCellValueTemplate,
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { tt } from "../../../../../data/langUtility";

interface Props {
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicFullName = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableHead></TableHead>
          <TableBody>
            <StyledTableRowTemplate template={template} key="4">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("fullName", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.givenName + " " + currentEmployee.surName}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("fullName", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
export const BasicFullNameHeader = ({ currentEmployee, template }: Props) => {
  return (
    <>
      <StyledTableContainer template={template}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <StyledTableRowTemplate template={template} key="4">
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "left",
                }}
              >
                {tt("name", "en")}
              </StyledTableCellLabelTemplate>
              <StyledTableCellValueTemplate template={template}>
                {currentEmployee.givenName + " " + currentEmployee.surName}
              </StyledTableCellValueTemplate>
              <StyledTableCellLabelTemplate
                template={template}
                sx={{
                  textAlign: "right",
                }}
              >
                {tt("name", "ar")}
              </StyledTableCellLabelTemplate>
            </StyledTableRowTemplate>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};
