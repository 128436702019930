import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  Typography,
  Stack,
} from "@mui/material";
import {
  StyledTableCellLabelTemplate,
  LabelTemplate,
  StyledTableRowTemplate,
  StyledTableContainer,
} from "../../styles/table";
import { tt } from "../../../../../data/langUtility";

interface Props2 {
  template: LabelTemplate;
}
export const BasicTitle = ({ template }: Props2) => {
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRowTemplate template={template} key="4">
            <StyledTableCellLabelTemplate template={template}>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <Typography variant="h4" sx={{ fontWeight: 900, fontSize: 28 }}>
                  {tt("employmentApplication", "en")}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 900, fontSize: 28 }}>
                  {tt("employmentApplication", "ar")}
                </Typography>
              </Stack>
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableHead>
      </Table>
    </StyledTableContainer>
  );
};
export const BasicTitleCenter = ({ template }: Props2) => {
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRowTemplate template={template} key="4">
            <StyledTableCellLabelTemplate template={template}>
              <Stack direction="row" justifyContent="center" spacing={1}>
                <Typography variant="h4" sx={{ fontWeight: 900, fontSize: 28 }}>
                  {tt("employmentApplication", "en")}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 900, fontSize: 28 }}>
                  {tt("employmentApplication", "ar")}
                </Typography>
              </Stack>
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableHead>
      </Table>
    </StyledTableContainer>
  );
};
export const BasicTitleSingleLang = ({ template }: Props2) => {
  return (
    <StyledTableContainer
      template={template}
      sx={{
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <StyledTableRowTemplate template={template} key="4">
            <StyledTableCellLabelTemplate
              template={template}
              sx={{
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 900, fontSize: 28 }}>
                {tt("employmentApplication", template.cvLang)}
              </Typography>
            </StyledTableCellLabelTemplate>
          </StyledTableRowTemplate>
        </TableHead>
      </Table>
    </StyledTableContainer>
  );
};
