import { useCallback, useRef } from "react";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toCanvas, toJpeg, toPng } from "html-to-image";
import {
  fetchEmployees,
  generateLink,
  selectProcesses,
  sharedEmployees,
} from "../processSlices";
import AuthSkeleton from "../../auth/AuthSkeleton";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import { NavLink as RouterLink, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  Tooltip,
  useTheme,
  Stack,
  Box,
  MobileStepper,
  Container,
  Typography,
} from "@mui/material";
import {
  StyledTableRow,
  StyledTableCellLabelTemplate,
  StyledTableCellValueTemplate,
  LabelTemplate,
} from "../../../styles/tableStyles";
import Image from "mui-image";
import { Edit, List, Share, ThumbUp } from "@mui/icons-material";
import { Employee, EmployeeStatus, ShortLink } from "../types/processTypes";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
//import { autoPlay } from "react-swipeable-views-utils";
import {
  changePageTitle,
  selectPreference,
} from "../../preferences/preferencesSlice";
import { useTranslation } from "react-i18next";

//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
import { bindKeyboard } from "react-swipeable-views-utils";
import CustomDialog from "../../../components/modals/CustomDialog";
import ShareCvs from "../components/ShareCvs";
import { selectAuth } from "../../auth/authSlice";
import { PostApplied } from "./cv/PostApplied";
import { Detail } from "./cv/Detail";
import { CvSkills } from "./cv/CvSkills";
import { CvLanguage } from "./cv/CvLanguage";
import { CvExperience } from "./cv/CvExperience";
import jsPDF from "jspdf";
const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);

function CvDetailCarousel() {
  const { link } = useParams() as {
    link: string;
  };
  const dispatch = useAppDispatch();
  const { t: tt } = useTranslation();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const { searchText } = useAppSelector(selectPreference);
  const [open, setOpen] = useState(false);
  const [shortLink, setShortLink] = useState<ShortLink>({});
  const { user } = useAppSelector(selectAuth);
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [headFontColor, setHeadFontColor] = useState("white");
  const [headBgColor, setHeadBgColor] = useState(theme.palette.primary.main);
  const [labelFontColor, setLabelFontColor] = useState(
    theme.palette.primary.main
  );
  const [labelBgColor, setLabelBgColor] = useState("white");
  const [valueFontColor, setValueFontColor] = useState(
    theme.palette.secondary.main
  );
  const [valueBgColor, setValueBgColor] = useState("white");

  const [cvTemplate, setCvTemplate] = useState<LabelTemplate>({
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
  });
  useEffect(() => {
    setCvTemplate({
      headFontColor,
      headBgColor,
      labelFontColor,
      labelBgColor,
      valueFontColor,
      valueBgColor,
    });
  }, [
    headFontColor,
    headBgColor,
    labelFontColor,
    labelBgColor,
    valueFontColor,
    valueBgColor,
  ]);

  const {
    selectedEmployee,
    employeesWithCount: { employees },
    selectedShortLink,
    success,
    loading,
  } = useAppSelector(selectProcesses);
  useEffect(() => {
    dispatch(changePageTitle("Cvs Detail"));
    if (!employees || employees.length === 0 || searchText) {
      if (link && link.length === 6) {
        dispatch(
          sharedEmployees({
            link: link,
            refreshList: "network-only",
          })
        );
      } else {
        dispatch(
          fetchEmployees({
            skip: 0,
            take: 500,
            status: EmployeeStatus.new,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, searchText, link]);

  useEffect(() => {
    setMaxSteps(employees.length);
    if (selectedEmployee.givenName === "") {
      //dispatch(setSelectedEmployee(employees[0]));
      setActiveStep(0);
    } else {
      let indexOf = employees.findIndex(
        (e: Employee) => e._id === selectedEmployee._id
      );
      setActiveStep(indexOf);
    }
    if (employees.length > 0) {
      //console.log(employees[0]);
      renderCvFormat(
        employees[0] &&
          employees[0].prospects &&
          employees[0].prospects[0] &&
          employees[0].prospects[0].remarks
          ? employees[0].prospects[0].remarks
          : employees[0].owner && employees[0].owner.remarks
          ? employees[0].owner.remarks
          : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, employees]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  const shareSingle = (employeeId: string) => {
    dispatch(generateLink({ employeeIds: [employeeId] }));
  };
  const dialogClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (success && selectedShortLink && selectedShortLink.link) {
      setOpen(true);
      setShortLink(selectedShortLink);
    }
  }, [success, selectedShortLink]);
  const shareCV = () => {};

  const ref = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  const handleGeneratePdf2 = useCallback(() => {
    if (ref.current === null) {
      return;
    }
    toCanvas(ref.current)
      .then((blob) => {
        //console.log(blob);
        // const URL = window.URL.crea(blob as Blob);
        // const el = document.createElement("a");
        // el.download = "mydummyfile.pdf";
        // el.href = URL;
        // el.click();
        // window.URL.revokeObjectURL(URL);

        const linkSource = `data:application/pdf;base64,${blob}`;
        const downloadLink = document.createElement("a");
        const fileName = "file.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ref]);

  const handleGeneratePdf = () => {
    if (ref.current === null || ref2.current === null) {
      return;
    }
    const fileName = employees[activeStep].givenName as string;

    //doc.setFont("Open-Sans", "normal");
    //doc.addPage();
    const doc = new jsPDF("p", "mm", "a4");
    toCanvas(ref.current)
      .then((canvas) => {
        const imgWidth = 200;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const imgData = canvas.toDataURL("image/png");
        doc.addImage({
          imageData: imgData,
          format: "PNG",
          x: 4,
          y: 4,
          width: imgWidth,
          height: imgHeight,
          alias: "alias1",
          compression: "SLOW",
        });

        doc.addPage();
        toCanvas(ref2.current as HTMLDivElement)
          .then((canvas2) => {
            const imgWidth2 = 200;
            const imgHeight2 = (canvas.height * imgWidth2) / canvas2.width;
            const imgData2 = canvas2.toDataURL("image/png");
            //console.log(imgData);
            doc.addImage({
              imageData: imgData2,
              format: "PNG",
              x: 4,
              y: 4,
              width: imgWidth2,
              height: imgHeight2,
              alias: "alias2",
              compression: "SLOW",
            });
            doc.save(`${fileName}.pdf`);
            // doc.save("download.pdf", { returnPromise: true })
            //   .then((pdfFile) => {

            // });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // doc.html(ref.current, {
    //   async callback(doc) {
    //     await doc.save("document");
    //   },
    //   width: 500,
    // });
  };

  const onButtonClick2 = () => {
    if (ref.current === null) {
      return;
    }
    // const empId = "emp_" + activeStep;
    // var node = document.getElementById(empId);
    // console.log(node);

    toPng(ref.current)
      .then((dataUrl) => {
        // const link = document.createElement("a");
        // link.download = "my-image-name.jpg";
        // link.href = dataUrl;
        // link.click();
        //console.log(dataUrl);
        const linkSource = `data:application/pdf;base64,${dataUrl.substring(
          22
        )}`;
        const downloadLink = document.createElement("a");
        const fileName = "file.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onButtonClick = useCallback(() => {
    // if (ref.current === null) {
    //   return;
    // }
    const empId = "emp_" + activeStep;
    console.log(empId);
    var node = document.getElementById(empId);

    toJpeg(node as HTMLElement, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "my-image-name.jpg";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [activeStep]);

  const renderCvFormat = (remarks: string) => {
    if (remarks.length > 0) {
      //console.log(remarks);
      const templateColors = remarks.split(",");
      setBackgroundColor(templateColors[0]);
      setHeadFontColor(templateColors[1]);
      setHeadBgColor(templateColors[2]);
      setLabelFontColor(templateColors[3]);
      setLabelBgColor(templateColors[4]);
      setValueFontColor(templateColors[5]);
      setValueBgColor(templateColors[6]);
    }
  };
  return (
    <div>
      {employees && employees.length > 0 ? (
        <Container maxWidth="md">
          <Box>
            {!link && (
              <>
                {/* <CvMaker
                  setBGC={setBackgroundColor}
                  setHFC={setHeadFontColor}
                  setHBC={setHeadBgColor}
                  setLFC={setLabelFontColor}
                  setLBC={setLabelBgColor}
                  setVFC={setValueFontColor}
                  setVBC={setValueBgColor}
                /> */}

                <Stack direction="row" justifyContent="space-between">
                  <>
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="flex-start"
                      sx={{
                        width: "100%",
                        "& > :not(style)": {
                          m: 0,
                          ml: 1,
                        },
                      }}
                    >
                      <Tooltip title="Show List">
                        <Button
                          color="secondary"
                          component={RouterLink}
                          variant="contained"
                          to={`/app/cvs`}
                          size="small"
                        >
                          <List />
                        </Button>
                      </Tooltip>

                      {/* <Tooltip title="Select Candidate">
                        <Button
                          color="secondary"
                          onClick={handleGeneratePdf}
                          variant="contained"
                          size="small"
                        >
                          <Download />
                        </Button>
                      </Tooltip> */}
                    </Stack>
                  </>

                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    sx={{
                      width: "100%",
                      "& > :not(style)": {
                        m: 0,
                        ml: 1,
                      },
                    }}
                  >
                    <Tooltip title="Select Candidate">
                      <Button
                        color="secondary"
                        onClick={shareCV}
                        variant="contained"
                        size="small"
                      >
                        <ThumbUp />
                      </Button>
                    </Tooltip>
                    {!link && (
                      <Tooltip title="Edit Detail">
                        <Button
                          color="secondary"
                          component={RouterLink}
                          variant="contained"
                          to={`/app/employee/${
                            employees[activeStep]._id as string
                          }`}
                          size="small"
                        >
                          <Edit />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="Share Candidate">
                      <Button
                        color="secondary"
                        onClick={() =>
                          shareSingle(employees[activeStep]._id as string)
                        }
                        variant="contained"
                        size="small"
                      >
                        <Share />
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
              </>
            )}
            <Box
              component={Paper}
              elevation={3}
              sx={{ display: "block", overflow: "hidden", width: "100%" }}
            >
              <BindKeyboardSwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
                disableLazyLoading
                ignoreNativeScroll
              >
                {employees.map((currentEmployee, index) => (
                  <div
                    key={index}
                    dir={theme.direction === "rtl" ? "rtl" : "ltr"}
                    id={`emp_${activeStep}`}
                  >
                    {Math.abs(activeStep - index) <= 2 ? (
                      <>
                        <Grid
                          container
                          spacing={1}
                          sx={{
                            p: 1,
                            backgroundColor: backgroundColor,
                          }}
                          ref={ref}
                        >
                          <Grid item xs={12} sx={{ height: "150px" }}>
                            {currentEmployee.prospects &&
                            currentEmployee.prospects[0] ? (
                              <>
                                {/* {renderCvFormat(
                                  currentEmployee.prospects[0].remarks as string
                                )} */}
                                <img
                                  width="100%"
                                  height="100%"
                                  src={
                                    currentEmployee.prospects[0].header
                                      ? (currentEmployee.prospects[0]
                                          .header as string)
                                      : "/noHeader.png"
                                  }
                                  alt="No agent header"
                                />
                              </>
                            ) : (
                              <>
                                {/* {renderCvFormat(
                                  currentEmployee && currentEmployee.owner
                                    ? (currentEmployee.owner.remarks as string)
                                    : ""
                                )} */}
                                <img
                                  width="100%"
                                  height="100%"
                                  src={
                                    currentEmployee &&
                                    currentEmployee.owner &&
                                    currentEmployee.owner.header
                                      ? (currentEmployee.owner.header as string)
                                      : "/noHeader.png"
                                  }
                                  alt="No Agency/Agent header"
                                />
                              </>
                            )}
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={12}
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt: 1 }}
                          >
                            {/* Short Photo */}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "4px",
                              }}
                            >
                              <Image
                                src={
                                  currentEmployee && currentEmployee.facePhoto
                                    ? (currentEmployee.facePhoto as string)
                                    : "/blankShort.jpg"
                                }
                                alt={currentEmployee.passportNumber}
                                width={180}
                                height={180}
                                shift="top"
                                distance="2rem"
                                shiftDuration={320}
                                style={{
                                  borderRadius: "50%",
                                }}
                              />
                            </Box>
                            {cvTemplate && currentEmployee && (
                              <PostApplied
                                currentEmployee={currentEmployee}
                                template={cvTemplate}
                              />
                            )}
                            {cvTemplate && currentEmployee && (
                              <Detail
                                currentEmployee={currentEmployee}
                                template={cvTemplate}
                              />
                            )}
                            {cvTemplate && currentEmployee && (
                              <CvSkills
                                currentEmployee={currentEmployee}
                                template={cvTemplate}
                              />
                            )}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            {loading === "pending" ? (
                              <AuthSkeleton />
                            ) : (
                              <Stack
                                spacing={1}
                                sx={{
                                  alignItems: "center",
                                }}
                              >
                                {cvTemplate && currentEmployee && (
                                  <CvLanguage
                                    currentEmployee={currentEmployee}
                                    template={cvTemplate}
                                  />
                                )}
                                {cvTemplate && currentEmployee && (
                                  <CvExperience
                                    currentEmployee={currentEmployee}
                                    template={cvTemplate}
                                  />
                                )}

                                <Image
                                  src={
                                    currentEmployee &&
                                    currentEmployee.standPhoto
                                      ? (currentEmployee.standPhoto as string)
                                      : "/blankLong.jpg"
                                  }
                                  alt={currentEmployee.passportNumber}
                                  width="95%"
                                  height="100%"
                                  shift="top"
                                  distance="2rem"
                                  shiftDuration={320}
                                  style={{
                                    borderRadius: "2%",
                                  }}
                                />
                              </Stack>
                            )}
                          </Grid>

                          <Grid item xs={12}>
                            {cvTemplate && currentEmployee && (
                              <TableContainer
                                component={Paper}
                                sx={{
                                  marginTop: 1,
                                  borderRadius: "10px",
                                  width: "100%",
                                }}
                              >
                                <Table size="small" aria-label="simple table">
                                  <TableBody>
                                    <StyledTableRow key="0">
                                      <StyledTableCellLabelTemplate
                                        template={cvTemplate}
                                        sx={{
                                          width: "20%",
                                        }}
                                      >
                                        {tt("remark")}
                                      </StyledTableCellLabelTemplate>
                                      <StyledTableCellValueTemplate
                                        sx={{ width: "80%" }}
                                        template={cvTemplate}
                                      >
                                        {
                                          currentEmployee.additionalData
                                            ?.notes as string
                                        }
                                      </StyledTableCellValueTemplate>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>
                        </Grid>
                        {/* ADD_PAGE */}
                        <Box ref={ref2}>
                          {currentEmployee && currentEmployee.passportScan && (
                            <Image
                              src={currentEmployee.passportScan as string}
                              alt={currentEmployee.passportNumber}
                              width="100%"
                              height="100%"
                              shift="top"
                              distance="2rem"
                              shiftDuration={320}
                              style={{
                                borderRadius: "2%",
                                marginTop: 40,
                              }}
                            />
                          )}
                        </Box>
                      </>
                    ) : null}
                  </div>
                ))}
              </BindKeyboardSwipeableViews>
              <MobileStepper
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                  >
                    {tt("next")}
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <KeyboardArrowLeft />
                    {tt("back")}
                  </Button>
                }
              />
            </Box>
          </Box>
        </Container>
      ) : loading === "pending" ? (
        <AuthSkeleton />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Image
            src="/No_Data.svg"
            alt="No Data"
            width={250}
            height={120}
            shift="top"
            distance="2rem"
            shiftDuration={320}
            style={{
              borderRadius: "50%",
            }}
          />
          <Typography variant="h5">No CV Found</Typography>
        </Box>
      )}
      <CustomDialog
        title="Shared Links"
        isOpen={open}
        handleDialogClose={dialogClose}
      >
        <ShareCvs
          link={shortLink.link as string}
          employees={
            shortLink.employees?.map((e) => e.givenName).join(", ") as string
          }
        />
      </CustomDialog>{" "}
    </div>
  );
}

export default CvDetailCarousel;
