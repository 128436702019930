import Resizer from "react-image-file-resizer";

export const resizeFile = (file: File, width: number, height: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      width,
      height,
      "jpeg",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file",
      200,
      200
    );
  });
