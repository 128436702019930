import { Box } from "@mui/material";
import { LabelTemplate } from "../../styles/table";
import { Employee } from "../../../types/processTypes";
import { Company } from "../../../../setups/types/setupTypes";

interface Props {
  selectedCompany: Company;
  currentEmployee: Employee;
  template: LabelTemplate;
}
export const BasicFooter = ({
  selectedCompany,
  currentEmployee,
  template,
}: Props) => {
  return (
    <>
      {selectedCompany && selectedCompany.footer ? (
        <Box
          sx={{
            borderRadius: template.borderRadius,
            // boxShadow: template.headerShadow,
            borderColor: template.borderColor,
            borderWidth: (template.borderWidth as number) * 3,
            borderStyle: template.borderStyle,
          }}
        >
          <img
            width="100%"
            height="60px"
            src={selectedCompany.footer as string}
            alt="office footer"
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
