import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faEnvelopeOpen,
  faLocationArrow,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faYoutube,
  faTelegram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import Link from "@mui/material/Link";
import { GradientBackground } from "../../styles/layoutStyled";
import Grid from "@mui/material/Grid";

export default function Footer() {
  return (
    <Box id="contact" sx={{ pt: 8 }}>
      <GradientBackground>
        <Container maxWidth="sm">
          <Stack
            data-aos="fade-up"
            data-aos-delay="40"
            direction="row"
            sx={{ fontSize: "3rem" }}
            justifyContent="space-between"
            color="white"
          >
            <Link href="https://fa.com/enjazPlus" color="inherit">
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link href="https://youtube.com/enjazPlus" color="inherit">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link href="https://t.me/enjazPlus" color="inherit">
              <FontAwesomeIcon icon={faTelegram} />
            </Link>
            <Link href="https://whatsapp.com/enjazPlus" color="inherit">
              <FontAwesomeIcon icon={faWhatsapp} />
            </Link>
          </Stack>
        </Container>

        <Container maxWidth="md" color="white">
          <Paper elevation={5} sx={{ background: "transparent" }}>
            <Stack
              data-aos="fade-up"
              data-aos-delay="60"
              spacing={0}
              sx={{ mt: 1 }}
              alignItems="center"
            >
              <Typography
                sx={{ mt: 1, color: "white" }}
                variant="h4"
                component="div"
                fontWeight="700"
              >
                <FontAwesomeIcon icon={faAddressBook} />
                &nbsp;Contact
              </Typography>
              <Divider orientation="horizontal" flexItem />
              <Grid
                container
                direction="row"
                sx={{
                  width: "100%",
                  color: "white",
                }}
              >
                <Grid item md={4} xs={12}>
                  <ListItemButton component="a" href="#customized-list">
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faPhone} />
                    </ListItemIcon>
                    <ListItemText
                      primary="+251-911-850-908"
                      secondary="Mon-Fri 9am-6pm"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ListItemButton
                    component="a"
                    href="mailto:contact@enjazplus.com"
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faEnvelopeOpen} />
                    </ListItemIcon>
                    <ListItemText
                      primary="contact@enjazplus.com"
                      secondary="Contact Us"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
                <Grid item md={4} xs={12}>
                  <ListItemButton component="a" href="#customized-list">
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Addis Ababa,Ethiopia"
                      secondary="Piasa Street, AA"
                      secondaryTypographyProps={{
                        color: "whitesmoke",
                        fontSize: 12,
                      }}
                    />
                  </ListItemButton>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <Box>
            <Typography variant="body1" color="white" align="center">
              <>
                {"Copyright © "}
                <MuiLink
                  color="inherit"
                  href="https://pinnasofts.com/"
                  sx={{ textDecoration: "none" }}
                >
                  PinnaSofts PLC
                </MuiLink>{" "}
                {new Date().getFullYear()}.
              </>
            </Typography>
          </Box>
        </Container>
      </GradientBackground>
    </Box>
  );
}
