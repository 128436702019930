import {
  Attachment,
  ExpandMore,
  ImportExport,
  Print,
  Save,
  VideoCall,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  Button,
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { VisaFilter } from "../../../components/filter/VisaFilter";
import { FormikTextField } from "../../../components/Layout/FormikTextField";
import { StyledAccordionSummary } from "../../../styles/componentStyled";
import { CountriesList, Employee } from "../types/processTypes";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import {
  assignEmployeeVisa,
  fetchVisas,
  selectProcesses,
} from "../processSlices";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { NavLink } from "react-router-dom";

interface ExperienceTabProps {
  formikData: FormikProps<Employee>;
  printPdf?: any;
  printVisibility?: string;
}

export const VisaBrief = ({
  formikData,
  printPdf,
  printVisibility,
}: ExperienceTabProps) => {
  const { t: te } = useTranslation("enums");
  const { t } = useTranslation();
  const [visaId, setVisaId] = useState<string>("");
  //const [company, setCompany] = useState<Company>();
  const dispatch = useAppDispatch();

  const {
    visasWithCount: { visas },
    loading: loadingVisas,
  } = useAppSelector(selectProcesses);

  useEffect(() => {
    dispatch(
      fetchVisas({
        skip: 0,
        take: 400,
      })
    );
  }, [dispatch]);

  const attachVisa = () => {
    dispatch(
      assignEmployeeVisa([formikData.values._id as string, visaId] as string[])
    );
  };
  const visaEntered = (visaNumber: string) => {
    const visa = visas.find((v) => v.visaNumber === visaNumber);
    if (visa) {
      formikData.setFieldValue("visa", visa);
      return true;
    }
    return false;
  };
  const pasteVisaData = async () => {
    try {
      const pasteContent = await navigator.clipboard.readText();
      if (pasteContent) {
        const contents = pasteContent.split("-");
        const visaNumber = contents[0].split("EMPLOYER")[0];
        if (!visaEntered(visaNumber.trim())) {
          const sponsorId = contents[0].split("EMPLOYER")[1];
          formikData.setFieldValue("visa.visaNumber", visaNumber.trim());
          formikData.setFieldValue("visa.passportNumber", sponsorId.trim());
          formikData.setFieldValue("visa.fullName", contents[1].trim());
          formikData.setFieldValue(
            "visa.address.country",
            CountriesList.saudi_arabia
          );
        }
      }
    } catch {}
  };
  return (
    <Stack>
      <Accordion expanded>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Visa Entry
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item sm={8} xs={12}>
              {/* <CompanyFilter
                companyType={ClientTypes.ForeignAgent}
                setCompany={setCompany}
              /> */}
            </Grid>
            <Grid item sm={4} xs={12}>
              {(!formikData.values.visa ||
                !formikData.values.visa.visaNumber ||
                formikData.values.visa.visaNumber.length < 9) && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Button
                    variant="contained"
                    onClick={pasteVisaData}
                    sx={{
                      mt: 1,
                      width: "100%",
                      backgroundImage: "url(/musanedLogo.svg)",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "100px 40px",
                      backgroundPositionX: "right",
                      justifyContent: "space-between",
                      paddingX: 2,
                    }}
                  >
                    Paste Visa Data From
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    component={NavLink}
                    to={`/app/tutor/cbIC0G3FVWE`}
                  >
                    Watch Video
                    <VideoCall />
                  </Button>
                  {/*<Link
                    color="secondary"
                    variant="h4"
                    href="/app/tutor/z3xd25rMZmw"
                    target="_blank"
                  >
                    Watch Video
                  </Link>
                   <Link
                    color="secondary"
                    variant="h4"
                    href="https://chrome.google.com/webstore/detail/enjaz%2B/oafbpbmbacpfmjgmffmngnlcflhffcfi"
                    target="_blank"
                  >
                    How?
                  </Link> */}
                </Stack>
              )}
              {/* <TextField
                value={value}
                onPaste={(event) => {
                  const pasteContent = event.clipboardData.getData("text");
                  const contents = pasteContent.split("-");
                  //const contents = mainContents[0].split(" ");
                  const visaNumber = contents[0].split("EMPLOYER")[0];
                  const sponsorId = contents[0].split("EMPLOYER")[1];
                  formikData.setFieldValue("visa.visaNumber", visaNumber);
                  formikData.setFieldValue("visa.passportNumber", sponsorId);
                  formikData.setFieldValue("visa.fullName", contents[1]);
                  formikData.setFieldValue(
                    "visa.address.country",
                    CountriesList.saudi_arabia
                  );
                  //console.log(event.clipboardData.getData("text"));
                  // setValue(event.clipboardData.getData("text"));
                }}
                onChange={(e) => {
                  //console.log("value changed", e.target.value);
                  //setValue(e.target.value);
                }}
              /> */}
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.visaNumber"
                label="Visa Number"
                onKeyUp={(event) => {
                  const visNum = formikData?.values?.visa?.visaNumber as string;
                  if (visNum?.length === 10) visaEntered(visNum);
                }}
                onPaste={(event) => {
                  const visNum = formikData?.values?.visa?.visaNumber as string;
                  if (visNum?.length === 10) visaEntered(visNum);
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.fullName"
                label="Employer Full Name"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.passportNumber"
                label="Employer Id"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.telephone"
                label="Employer Telephone"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.city"
                label="Employer city"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="visa.address.country"
                select
                label="Employer Country"
              >
                {Object.keys(CountriesList).map((option) => (
                  <MenuItem key={option} value={option as string}>
                    {te((option as string).toLowerCase())}
                  </MenuItem>
                ))}
              </FormikTextField>
            </Grid>
            <Grid item md={4} xs={12}>
              <FormikTextField
                formikKey="embassyProcess.enjazNumber"
                label="Application Number"
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={!formikData.isValid}
                size="large"
                sx={{ mt: 1, width: "100%" }}
              >
                <Save />
                {t("save") + " " + t("visa")}
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                color="secondary"
                variant="contained"
                onClick={printPdf}
                size="large"
                sx={{ display: printVisibility, mt: 1, width: "100%" }}
              >
                <Print /> Print First Page
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h6" component="div">
            Search & Attach Visa
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} sx={{ mb: 1 }}>
            <Grid item md={8} xs={12}>
              <VisaFilter setVisaId={setVisaId} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                sx={{ width: "100%", mt: 1 }}
                color="secondary"
                variant="contained"
                onClick={attachVisa}
                disabled={!visaId || visaId.length === 0}
              >
                <Attachment />
                Attach Visa
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
