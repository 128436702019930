import { useEffect, useState } from "react";
import { Autocomplete, LinearProgress, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

import {
  fetchVisas,
  selectProcesses,
} from "../../features/process/processSlices";
import { changePageTitle } from "../../features/preferences/preferencesSlice";
import { Visa } from "../../features/process/types/processTypes";

interface Props {
  setVisaId: any;
}
export const VisaFilter = ({ setVisaId }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedVisa, setSelectedVisa] = useState<Visa>();

  const {
    visasWithCount: { visas },
    loading: loadingVisas,
  } = useAppSelector(selectProcesses);

  useEffect(() => {
    dispatch(changePageTitle("Visas List"));

    dispatch(
      fetchVisas({
        skip: 0,
        take: 400,
      })
    );
  }, [dispatch]);
  return (
    <>
      {loadingVisas === "pending" ? (
        <LinearProgress color="secondary" />
      ) : (
        <Autocomplete
          id="visaId"
          options={visas}
          value={selectedVisa}
          getOptionLabel={(option) => option.visaNumber + " " + option.fullName}
          sx={{ mt: 1 }}
          onChange={(e, value) => {
            setSelectedVisa(value as Visa);
            setVisaId(value?._id as string);
          }}
          size="small"
          renderInput={(params) => (
            <TextField
              label="Search and attach visa"
              name="visaId"
              {...params}
            />
          )}
        />
      )}
    </>
  );
};
