import { NavLink as RouterLink } from "react-router-dom";
import {
  AppBar,
  Box,
  Container,
  Divider,
  List,
  ListItem,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import Logo from "../features/process/components/Logo";
import Language from "./mode/Language";
import { useTranslation } from "react-i18next";
import { ShadowStyled } from "../features/home/styles/layoutStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { MenuButton } from "../features/home/styles/componentStyled";
//import { useAppDispatch } from "../app/hooks";
const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
const MainNavbar = () => {
  // const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation("auth");
  const { t: tt } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };
  return (
    <>
      <AppBar elevation={0}>
        <Box
          sx={{
            background: `linear-gradient(to right, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 70%)`,
          }}
        >
          <ShadowStyled>
            <Toolbar sx={{ height: 64 }}>
              <Container maxWidth="lg">
                <Stack direction="row" alignItems="center">
                  <RouterLink to="/" data-aos="fade-right">
                    <Logo />
                  </RouterLink>
                  <Typography
                    data-aos="fade-left"
                    sx={{
                      color: theme.palette.getContrastText(
                        theme.palette.secondary.light
                      ),
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      [theme.breakpoints.up("sm")]: {
                        fontSize: "1rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      [theme.breakpoints.up("md")]: {
                        fontSize: "1.2rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      [theme.breakpoints.up("lg")]: {
                        fontSize: "1.4rem",
                        color: theme.palette.getContrastText(
                          theme.palette.primary.light
                        ),
                      },
                      lineHeight: "1.1",
                      p: 0.5,
                      "&:hover": {
                        color: "orange",
                      },
                    }}
                  >
                    {tt("platform")}
                  </Typography>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Stack
                    spacing={2}
                    sx={{
                      display: { md: "flex", xs: "none" },
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 0,
                        ml: 2,
                      },
                    }}
                    direction="row"
                  >
                    <MenuButton href="/home">{t("home")}</MenuButton>
                    <MenuButton href="/ts">{tt("statusTracking")}</MenuButton>
                    <MenuButton href="/login">{t("signin")}</MenuButton>
                    <MenuButton href="/register">{t("signUp")}</MenuButton>
                    <Language />
                  </Stack>

                  <Stack sx={{ mr: 2, display: { md: "none", xs: "block" } }}>
                    <Box
                      style={{
                        borderRadius: "50%",
                      }}
                      onClick={handleDrawerOpen}
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Box>
                  </Stack>
                </Stack>
              </Container>
            </Toolbar>
          </ShadowStyled>
        </Box>
      </AppBar>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        variant="temporary"
        onClose={handleDrawerOpen}
        open={drawerOpen}
        onOpen={handleDrawerOpen}
        anchor="right"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        <Box
          sx={{
            background: `linear-gradient(to bottom, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
            minHeight: "100vh",
          }}
        >
          <Toolbar>
            <RouterLink to="/" data-aos="fade-right">
              <Logo />
            </RouterLink>
            <Typography
              color="white"
              sx={{
                ml: "8px",
                lineHeight: "1.1",
                color: theme.palette.getContrastText(
                  theme.palette.secondary.light
                ),
                fontSize: "0.8rem",
                fontWeight: "bold",
                [theme.breakpoints.up("sm")]: {
                  fontSize: "1rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                [theme.breakpoints.up("md")]: {
                  fontSize: "1.2rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                [theme.breakpoints.up("lg")]: {
                  fontSize: "1.4rem",
                  color: theme.palette.getContrastText(
                    theme.palette.primary.light
                  ),
                },
                fontStyle: "italic",
                p: 0.5,
                "&:hover": {
                  color: "orange",
                },
              }}
            >
              {tt("platform")}
            </Typography>
          </Toolbar>
          <Divider />
          <List onClick={handleDrawerOpen}>
            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/home"
              >
                {t("home")}
              </MenuButton>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/ts"
              >
                {tt("statusTracking")}
              </MenuButton>
            </ListItem>
            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/login"
              >
                {t("signin")}
              </MenuButton>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                py: 0.5,
              }}
            >
              <MenuButton
                sx={{
                  justifyContent: theme.direction === "ltr" ? "left" : "right",
                }}
                href="/register"
              >
                {t("signUp")}
              </MenuButton>
            </ListItem>

            <ListItem
              sx={{
                display: "flex",
                p: "6px 16px",
              }}
            >
              <Language />
            </ListItem>
          </List>
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default MainNavbar;
