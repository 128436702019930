import { ChangeEvent, useState } from "react";
import Avatar from "@mui/material/Avatar";
//import { deleteFile } from "../../auth/authSlice";
import Delete from "@mui/icons-material/Delete";
import AddAPhoto from "@mui/icons-material/AddAPhoto";

import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Fab from "@mui/material/Fab";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Box from "@mui/material/Box";
import Toast from "../../../components/Layout/Toast";

import AuthSkeleton from "../../auth/AuthSkeleton";
import { resizeFile } from "../../../utils/imageUtility";
import { selectSetups, uploadCompanyPicture } from "../setupSlices";
import { PhotoTypes } from "../../process/types/processTypes";
import { Company } from "../types/setupTypes";

interface CompanyProps {
  companyData?: Company | undefined;
}

function CompanyHeader({ companyData }: CompanyProps) {
  const [image, setImage] = useState("");

  const {
    success,
    loading: loadingStandPhoto,
    error,
  } = useAppSelector(selectSetups);

  const dispatch = useAppDispatch();
  const onChangeHeader = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;

    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 1200, 200)) as File;
      if (image) {
        dispatch(
          uploadCompanyPicture({
            _id: companyData!._id as string,
            type: PhotoTypes.Header,
            avatar: image,
          })
        );
      }
    }
  };
  const onChangeFooter = async (event: ChangeEvent<HTMLInputElement>) => {
    const { validity, files } = event.target;

    if (validity.valid && files && files[0]) {
      const image = (await resizeFile(files[0], 1200, 200)) as File;
      if (image) {
        dispatch(
          uploadCompanyPicture({
            _id: companyData!._id as string,
            type: PhotoTypes.Bottom,
            avatar: image,
          })
        );
      }
    }
  };

  const removeImage = () => {
    setImage("");
    //dispatch(deleteFile(companyData?._id as string));
  };

  return (
    <>
      <Paper
        elevation={0}
        style={{
          position: "relative",
          borderRadius: 18,
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box>
          <input
            name="headerImage"
            accept="image/*"
            onChange={onChangeHeader}
            style={{ display: "none" }}
            id="icon-button-file-header"
            type="file"
          />
          <label htmlFor="icon-button-file-header">
            <>
              <Badge
                overlap="rectangular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={
                  <Tooltip
                    title="Add/Change Header Photo"
                    aria-label="icon-button-file-header"
                  >
                    <Fab component="span" color="primary">
                      <IconButton
                        aria-label="icon-button-file-header"
                        component="span"
                        size="large"
                        color="inherit"
                      >
                        <AddAPhoto />
                      </IconButton>
                    </Fab>
                  </Tooltip>
                }
              >
                {loadingStandPhoto === "pending" ? (
                  <AuthSkeleton />
                ) : (
                  <Avatar
                    alt="avatar"
                    variant="rounded"
                    src={image ? image : companyData ? companyData.header : ""}
                    sx={{
                      height: 250,
                      width: 1000,
                    }}
                  />
                )}
              </Badge>
            </>
          </label>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 0,
            }}
          >
            {(image || (companyData && companyData.header)) && (
              <>
                <Box component="div">
                  <Tooltip
                    title="Remove Photo"
                    aria-label="icon-button-file-header"
                  >
                    <Fab component="span" color="primary">
                      <IconButton
                        color="inherit"
                        edge="start"
                        onClick={removeImage}
                        size="large"
                        sx={{
                          margin: "auto",
                          width: "auto",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Fab>
                  </Tooltip>
                </Box>
              </>
            )}
          </div>
        </Box>
        <Box sx={{ mt: 2 }}>
          <input
            name="footerImage"
            accept="image/*"
            onChange={onChangeFooter}
            style={{ display: "none" }}
            id="icon-button-file-footer"
            type="file"
          />
          <label htmlFor="icon-button-file-footer">
            <>
              <Badge
                overlap="rectangular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={
                  <Tooltip
                    title="Add/Change Footer Photo"
                    aria-label="icon-button-file-footer"
                  >
                    <Fab component="span" color="primary">
                      <IconButton
                        aria-label="icon-button-file-footer"
                        component="span"
                        size="large"
                        color="inherit"
                      >
                        <AddAPhoto />
                      </IconButton>
                    </Fab>
                  </Tooltip>
                }
              >
                {loadingStandPhoto === "pending" ? (
                  <AuthSkeleton />
                ) : (
                  <Avatar
                    alt="avatar"
                    variant="rounded"
                    src={image ? image : companyData ? companyData.footer : ""}
                    sx={{
                      height: 250,
                      width: 1000,
                    }}
                  />
                )}
              </Badge>
            </>
          </label>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 0,
            }}
          >
            {(image || (companyData && companyData.footer)) && (
              <>
                <Box component="div">
                  <Tooltip
                    title="Remove Photo"
                    aria-label="icon-button-file-footer"
                  >
                    <Fab component="span" color="primary">
                      <IconButton
                        color="inherit"
                        edge="start"
                        onClick={removeImage}
                        size="large"
                        sx={{
                          margin: "auto",
                          width: "auto",
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Fab>
                  </Tooltip>
                </Box>
              </>
            )}
          </div>
        </Box>
        <Box m={1}>
          {error && <Toast severity="error">{error.message}</Toast>}
          {success && <Toast severity="success">{success.message}</Toast>}
        </Box>
      </Paper>
    </>
  );
}

export default CompanyHeader;
