import { experimentalStyled as styled } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    height: 32,
  },
}));
export const StyledTableCellLabel = styled(StyledTableCell)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  color: theme.palette.primary.main,
}));
export const StyledTableCellValue = styled(StyledTableCell)(({ theme }) => ({
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  color: theme.palette.secondary.main,
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.selected,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledTableRowTemplate = styled(TableRow, {
  shouldForwardProp: (prop) => prop !== "evenRowBgColor",
})<{ evenRowBgColor?: string }>(({ theme, evenRowBgColor }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: evenRowBgColor,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// export const StyledTableCellLabelTemplate = styled(TableCell, {
//   shouldForwardProp: (prop) =>
//     prop !== "headFontColor" &&
//     prop !== "headBgColor" &&
//     prop !== "labelFontColor" &&
//     prop !== "labelBgColor",
// })<{
//   headFontColor?: string;
//   headBgColor?: string;
//   labelFontColor?: string;
//   labelBgColor?: string;
// }>(({ theme, headFontColor, headBgColor, labelFontColor, labelBgColor }) => ({
//   padding: "4px",
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: headBgColor,
//     color: headFontColor,
//     height: 48,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     height: 32,
//   },
//   textAlign: theme.direction === "rtl" ? "right" : "left",
//   fontWeight: 900,
//   backgroundColor: labelBgColor,
//   color: labelFontColor,
// }));

// export const StyledTableCellValueTemplate = styled(TableCell, {
//   shouldForwardProp: (prop) =>
//     prop !== "headFontColor" &&
//     prop !== "headBgColor" &&
//     prop !== "valueFontColor",
// })<{ headFontColor?: string; headBgColor?: string; valueFontColor?: string }>(
//   ({ theme, headFontColor, headBgColor, valueFontColor }) => ({
//     padding: "4px",
//     [`&.${tableCellClasses.head}`]: {
//       backgroundColor: headBgColor,
//       color: headFontColor,
//       height: 48,
//     },
//     [`&.${tableCellClasses.body}`]: {
//       fontSize: 14,
//       height: 32,
//     },
//     textAlign: theme.direction === "rtl" ? "right" : "left",
//     fontWeight: 900,
//     color: valueFontColor,
//   })
// );

export interface LabelTemplate {
  headFontColor?: string;
  headBgColor?: string;
  labelFontColor?: string;
  labelBgColor?: string;
  valueFontColor?: string;
  valueBgColor?: string;
}

export const StyledTableCellLabelTemplate = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: template.headBgColor,
    color: template.headFontColor,
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    height: 32,
  },
  textAlign: theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  backgroundColor: template.labelBgColor,
  color: template.labelFontColor,
  textTransform: "uppercase",
}));

export const StyledTableCellValueTemplate = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "template",
})<{ template: LabelTemplate }>(({ theme, template }) => ({
  padding: "4px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: template.headBgColor,
    color: template.headFontColor,
    height: 48,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    height: 32,
  },
  textAlign: "center", // theme.direction === "rtl" ? "right" : "left",
  fontWeight: 900,
  backgroundColor: template.valueBgColor,
  color: template.valueFontColor,
  textTransform: "uppercase",
}));
